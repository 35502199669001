import React, {Component} from "react";

import {
	Select,
	MenuItem,
	FormControl,
	InputLabel
} from '@mui/material';
import { SelectChangeEvent } from '@mui/material/Select';

interface FilterSelectorItem {
	value: any,
	key: string,
	label: string
}

interface FilterSelectorProps {
	label: string,
	items: FilterSelectorItem[],
	value: any,
	onChange: (event: SelectChangeEvent, child?: object) => void
}

interface FilterSelectorState {
}

class FilterSelector extends Component<FilterSelectorProps, FilterSelectorState> {
    render() {
        return (
			<FormControl fullWidth variant="outlined" size="small">
				<InputLabel id="select-energy-source-label" disableAnimation shrink={false} className={this.props.value ? 'has-value' : ''}>{this.props.label}</InputLabel>
				<Select
					labelId="select-energy-source-label"
					id="select-energy-source"
					label=""
					inputProps={{MenuProps: {disableScrollLock: true}}}
					onChange={this.props.onChange}
					value={this.props.value}
				>
					{(this.props.items || []).map(({ value, key, label }) => 
						<MenuItem value={value} key={key}>{label}</MenuItem>
					)}
				</Select>
			</FormControl>
        )
    }
}

export default FilterSelector;

