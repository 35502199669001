import ReactApexChart from 'react-apexcharts'
import { ApexChartType, ApexLegendHorizontalAlign } from "../Util/ApexTypeHelper";

interface EnergieKennzahlTargetCanvasProps {
    data;
    einheit: string;
    target;
    type;
}

const EnergieKennzahlTargetCanvas = (props: EnergieKennzahlTargetCanvasProps) => {
    console.log("EnergieKennzahlTargetCanvas", props);
    const data = props.data;
    const target = props.target && Number.parseFloat(props.target.amount);

    let gemessen = [];
    let normalisiert = [];
    let ziel = [];
    for (let i = 1; i <= 12; i++) {
        if (data && data[i]) {
            if (data[i][1]) {
                gemessen.push(data[i][1].toFixed(0));
            }
            if (data[i][2]) {
                normalisiert.push(data[i][2].toFixed(0));
                ziel.push(Number.parseFloat((data[i][2] * (Number.parseFloat("0." + (100.00 - (target))))).toFixed(0)));
            }
        }
    }

    const chart = {
        series: [{
            name: 'Gemessen',
            type: props.type,
            data: gemessen
        }, {
            name: "Berechnet (EnPI(Monat))",
            type: props.type,
            data: normalisiert
        }, {
            name: "Ziel (" + target + "%)",
            type: props.type,
            data: ziel
        }],
        options: {
            chart: {
                height: 350,
                stacked: false
            },
            dataLabels: {
                enabled: false
            },
            stroke: {
                width: [3, 3, 3]
            },
            xaxis: {
                categories: ["Jan", 'Feb', 'März', 'April', 'Mai', 'Juni', 'Juli', 'Aug', 'Sep', 'Okt', 'Nov', 'Dez'],
            },
            yaxis: [
                {
                    axisTicks: {
                        show: true,
                    },
                    axisBorder: {
                        show: true,
                        color: '#008FFB'
                    },
                    labels: {
                        style: {
                            fontSize:  '14px',
                            colors: '#008FFB',
                        }
                    },
                    title: {
                        text: `Energieverbrauch (${props.einheit})`,
                        style: {
                            fontSize:  '14px',
                            color: '#008FFB',
                        }
                    },
                    tooltip: {
                        enabled: true
                    }
                }
            ],
            tooltip: {
                fixed: {
                    enabled: true,
                    position: 'bottomLeft', // topRight, topLeft, bottomRight, bottomLeft
                    offsetY: 30,
                    offsetX: 60
                },
            },
            legend: {
                horizontalAlign: 'left' as ApexLegendHorizontalAlign,
                offsetX: 40
            }
        }
    }

    return (
        <div>
            <ReactApexChart options={chart.options} series={chart.series} type="line" height={350}/>
        </div>
    );
}

export default EnergieKennzahlTargetCanvas;

