import { ChangeEvent, useState } from "react";
import Grid from "@material-ui/core/Grid";
import { Button, TextField } from "@mui/material";
import { useConstructor } from "../Util/ReactUtils";
import { useHistory, useParams } from "react-router";
import { EnergySource } from "../API";
import { createEnergySource, getEnergySourceById, updateEnergySource } from "../DataBase/EnergySourceAPI";
import { useUser } from "../UserContext";
import { v4 as uuid } from "uuid";
import { formatFloat } from "../Util/helper";
import { isEnpiEnergySource } from "../Model/EnergySource";

enum EditMode {
  NEW = 'NEW',
  EDIT = 'EDIT',
}

interface EditEnergySourceProps {
  mode: EditMode;
}

interface EditEnergySourceParams {
  id?: string;
}

const EnergySourceEditor = (props: EditEnergySourceProps) => {
  const { companyID } = useUser();
  const params = useParams<EditEnergySourceParams>();
  const mode = props.mode;
  const history = useHistory();
  const [energySource, setEnergySource] = useState({} as EnergySource);
  const isEnpiSource = isEnpiEnergySource(energySource);

  const fetchData = async () => {
    if (mode === EditMode.EDIT) {
      setEnergySource(await getEnergySourceById(params.id));
    }
  }

  useConstructor(() => {
    fetchData();
  });

  const save = async() => {
    if (mode === 'NEW') {
      energySource.id = uuid();
      energySource.companyID = companyID;
      await createEnergySource(energySource);
      history.goBack();
    } else {
      await updateEnergySource(energySource);
      history.goBack();
    }
  }

  const handleInput = (event: ChangeEvent, isFloat = false) => {
    const target = event.target as HTMLInputElement;
    const name = target.name;
    const value = isFloat ? target.value.replaceAll(',', '.') : target.value;
    setEnergySource({
      ...energySource,
      [name]: value,
    });
  }

  return (
    <div className="dashboard-root">
      <div className="base-page">
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <div className="mb-3">
              <TextField
                required
                fullWidth
                id="input-name"
                label="Bezeichnung"
                name="name"
                InputLabelProps={{ shrink: true }}
                onChange={handleInput}
                value={energySource.name}
                disabled={isEnpiSource}
              />
            </div>
          </Grid>
          <Grid item xs={12} sm={6}>
            <div className="mb-3">
              <TextField
                required
                fullWidth
                id="input-unit"
                label="Einheit"
                name="unit"
                InputLabelProps={{ shrink: true }}
                onChange={handleInput}
                value={energySource.unit}
                disabled={isEnpiSource}
              />
            </div>
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <div className="mb-3">
              <TextField
                required
                fullWidth
                id="input-cofactor"
                label="CO2 in g je Einheit"
                name="co2factor"
                InputLabelProps={{ shrink: true }}
                onChange={(e) => handleInput(e, true)}
                value={formatFloat(energySource.co2factor)}
                disabled={isEnpiSource}
              />
            </div>
          </Grid>
          <Grid item xs={12} sm={6}>
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <div className="mb-3">
              <TextField
                fullWidth
                id="input-sourceDescription"
                label="Quelle"
                name="sourceDescription"
                InputLabelProps={{ shrink: true }}
                onChange={handleInput}
                value={energySource.sourceDescription}
                disabled={isEnpiSource}
              />
            </div>
          </Grid>
          <Grid item xs={12} sm={6}>
            <div className="mb-3">
              <TextField
                fullWidth
                id="input-sourceUrl"
                label="URL"
                name="sourceUrl"
                InputLabelProps={{ shrink: true }}
                onChange={handleInput}
                value={energySource.sourceUrl}
                disabled={isEnpiSource}
              />
            </div>
          </Grid>
        </Grid>
        <br />
        <Button className="full-size-button" onClick={save} variant="contained" disabled={isEnpiSource}>
            {mode === EditMode.NEW ? "Hinzufügen" : "Speichern"}
        </Button>
      </div>
    </div>
  );
}

export default EnergySourceEditor;
