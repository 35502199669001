import { useState } from "react";
import ProcessListItem from "./ProcessListItem";
import { withRouter } from "react-router-dom";
import { listProcessesWithEnergySources, ProcessWithEnergySource } from "../../DataBase/Prozess/ProzessAPI";
import { useConstructor } from "../../Util/ReactUtils";
import { isVirtualConsumer } from "../../Model/VirtualConsumer";
import EnpiButton from "../../ui/EnpiButton";
import { useUser } from "../../UserContext";

const ProcessOverview = (props) => {
    const { companyID } = useUser();
    const [consumers, setConsumers] = useState([] as ProcessWithEnergySource[]);

    useConstructor(async () => {
        setConsumers(await listProcessesWithEnergySources(companyID));
    });

    return (
        <div className="dashboard-root" >
            <div className="base-content">
                <div>
                    <header>
                        <h1>Verbraucher</h1>
                    </header>
                    <br/>

                    <div className="table-responsive">
                        <table
                            className="table table-vcenter">
                            <thead>
                            <tr>
                                <th>Gemessene Verbraucher</th>
                                <th>Verantwortlichkeit</th>
                                <th>Energieträger</th>
                                <th></th>
                            </tr>
                            </thead>
                            <tbody>
                            {consumers.filter((consumer) => !isVirtualConsumer(consumer.process)).map((process, index) =>
                                <ProcessListItem key={index} consumer={process}/>
                            )}
                            </tbody>
                        </table>
                    </div>
                    <EnpiButton link={`/Prozess/NewProzess/${companyID}`}>Neuen Verbraucher anlegen</EnpiButton>
                    <br/>
                    <div className="table-responsive">
                        <table
                            className="table table-vcenter">
                            <thead>
                            <tr>
                                <th>Virtuelle Verbraucher</th>
                                <th>Formel</th>
                                <th>Energieträger</th>
                            </tr>
                            </thead>
                            <tbody>
                            {consumers.filter((consumer) => isVirtualConsumer(consumer.process)).map((consumer, index) =>
                                <ProcessListItem key={index} consumer={consumer}/>
                            )}
                            </tbody>
                        </table>
                    </div>
                    <EnpiButton link={`/VirtualConsumer/New/${companyID}`}>Neuen Virtuellen Verbraucher anlegen</EnpiButton>
                </div>
            </div>
        </div>
    );
}

export default withRouter(ProcessOverview);
