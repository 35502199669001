import {Component} from "react";
import {getTargetbyID, updateTargetbyState} from "../../DataBase/Target/TargetAPI";
import {getProcess} from "../../DataBase/Prozess/ProzessAPI";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import DatePicker from "@mui/lab/DatePicker";
import TextField from "@mui/material/TextField";
import EnpiButton from "../../ui/EnpiButton";


class Target extends Component {

    constructor(props) {
        super(props);
        this.history = props.history;

        this.state = {
            regexNumbersOnly: /^[+-]?\d*\.?,?\d*$/,
            open: false,
            openBewertung: false,
            id: "",
            amount: "",
            amount_error_text: "",
            bewertungsfaktor: "",
            bezugsJahr: "",
            verantwortlicher: "",
            prozessID: ""
        };
        this.fetchData(props.match.params.id);
    }

    async fetchData(targetid) {
        let target = await getTargetbyID(targetid);
        this.setState({id: target.id});
        this.setState({amount: target.amount});
        this.setState({bewertungsfaktor: target.bewertungsfaktor});
        this.setState({bezugsJahr: target.bezugsJahr});
        this.setState({verantwortlicher: target.verantwortlicher});
        this.setState({prozessID: target.prozessID});
        let process = await getProcess(this.state.prozessID);
        this.setState({process: process});
    }


    async saveTarget() {
        let updatedTarget = await updateTargetbyState(this.state);
        this.setState({target: updatedTarget});
        this.history.goBack();
    }
    handleBewertungClose = () => {
        this.setState({openBewertung: false});
    };

    handleBewertungOpen = () => {
        this.setState({openBewertung: true});
    };

    setValue(fieldAndValue) {
        let inputfield = document.getElementsByName(Object.keys(fieldAndValue)[0])[0];
        if (this.validate(fieldAndValue[Object.keys(fieldAndValue)[0]])) {
            if (inputfield.name === "amount") {
                fieldAndValue[Object.keys(fieldAndValue)[0]] = this.deNormalise(fieldAndValue[Object.keys(fieldAndValue)[0]]);
            }
            this.setState(fieldAndValue);
            this.unsetErrorFor(inputfield);
        } else {
            this.setErrorFor(inputfield);
        }
    }
    validate(value) {
        console.log(value);
        return value === '' || this.state.regexNumbersOnly.test(value);
    }

    setErrorFor(input) {
        if (input.id === "input-amount") {
            this.setState({amount_error_text: "Es können nur Zahlen eingegeben werden"});
        }
    }

    unsetErrorFor(input) {
        if (input.id === "input-amount") {
            this.setState({amount_error_text: ""});
        }
    }

    deNormalise(entry) {
        if (entry === null) {


            return "";
        }
        return ("".concat(entry)).replaceAll('.', ',');
    }

    setYear(selection) {
        this.setState({bezugsJahr: selection.getFullYear()})
    }


    render() {

        return (
            <div>
                <div className="base-page">
                    <header>
                        <h3>Einsparziel für
                            Verbraucher {this.state.process && this.state.process.prozessnummer} {this.state.process && this.state.process.prozessname}  </h3>
                    </header>
                    <div className="base-content">

                        <div className="mb-3">
                            <TextField
                                fullWidth
                                id="input-verantwortlichkeit"
                                label="Verantwortlichkeit"
                                name="verantwortlicher"
                                InputLabelProps={{shrink: true}}
                                onChange={e => this.setState({verantwortlicher: e.target.value})}
                                value={this.state.verantwortlicher}/>
                        </div>

                        <div className="mb-3">
                            <InputLabel id="bezugsjahr-select-label">Einsparziel für das Jahr *</InputLabel>
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <DatePicker
                                    views={['year']}
                                    value={this.state.bezugsJahr ? new Date().setFullYear(this.state.bezugsJahr): "" }
                                    readOnly={true}
                                    minDate={new Date().setFullYear(2000)}
                                    maxDate={new Date().setFullYear((new Date().getFullYear()+1))}
                                    onChange={(newValue) => this.setYear(newValue)}
                                    renderInput={(params) => <TextField {...params} helperText={null}/>}
                                />
                            </LocalizationProvider>
                        </div>


                        <div className="mb-3">
                            <TextField
                                fullWidth
                                id="input-amount"
                                label="Einsparziel in %"
                                name="amount"
                                error={this.state.amount_error_text !== ""}
                                helperText={this.state.amount_error_text}
                                InputLabelProps={{shrink: true}}
                                onChange={e => this.setValue({amount: e.target.value})}
                                value={this.state.amount}/>
                            <small>Error message</small>
                        </div>

                        <div className="mb-3 bewertungsfaktor">
                            <InputLabel id="bewertungsfaktor-select-label">Bewertungsfaktor</InputLabel>
                            <Select
                                labelId="bewertungsfaktor-select-label"
                                id="bewertungsfaktor-open-select"
                                className="select-minsize"
                                open={this.state.openBewertung}
                                onClose={this.handleBewertungClose}
                                onOpen={this.handleBewertungOpen}
                                value={this.state.bewertungsfaktor}
                                onChange={e => this.setState({bewertungsfaktor: e.target.value})}
                            >
                                <MenuItem value={"Energie"}>Energie</MenuItem>
                                <MenuItem value={"Emission"}>Emission</MenuItem>
                            </Select>

                        </div>
                        <EnpiButton
                            onClick={() => this.saveTarget()}
                            disabled={this.state.amount === "" || this.state.jahr === ""}>
                            Speichern und zurück
                        </EnpiButton>
                        <br/>
                        <br/>
                    </div>
                </div>
            </div>
        );
    }
}

export default Target;
