import { useState } from 'react';
import ReactApexChart, { Props as ReactApexChartProps } from 'react-apexcharts'
import { ApexChartType, ApexLegendHorizontalAlign } from '../Util/ApexTypeHelper';
import { useConstructor } from '../Util/ReactUtils';

interface BasicMonatskennzahlenProps {
    data;
    type?;
    einheit;
}

interface ChartData {
    series?;
    options?;
}

const BasicMonatskennzahlen = (props: BasicMonatskennzahlenProps) => {
    const jahreLabels = [];
    const monateLabels = ["Jan", 'Feb', 'Mär', 'Apr', 'Mai', 'Jun', 'Jul', 'Aug', 'Sep', 'Okt', 'Nov', 'Dez'];
    const drawtype = props.type;// ? props.type; : "column";
    //const [chartData, setChartData] = useState({series: null, options: null} as ChartData);

    const prepareSeries = (data) => {
        console.log("prepareSeries", data);
        const preparedSeries = [];
        if (data && data.length) {
            for (let k = 0; k < data.length; k++) {
                let seriesData = [];
                jahreLabels.push(data[k].bezugsJahr || "Kein Jahr Angegeben");
                if (data && data[k] && data[k].kennzahlen) {
                    for (let i = 0; i <= 11; i++) {
                        if (data[k].kennzahlen[i]) {
                            seriesData.push(Number.parseFloat(data[k].kennzahlen[i].toFixed(3)));
                        } else {
                            seriesData.push(null);
                        }
                    }
                }
                preparedSeries.push({
                    name: data[k].bezugsJahr || "Kein Jahr Angegeben",
                    type: drawtype,
                    data: seriesData
                })
            }
        }
        return preparedSeries;
    }

    const chartData = {
            series: prepareSeries(props.data),
            options: {
                chart: {
                    height: 350,
                    stacked: false
                },
                dataLabels: {
                    enabled: false
                },
                stroke: {
                    width: 2
                },
                xaxis: {
                    categories: monateLabels,
                },
                yaxis: [
                    {
                        axisTicks: {
                            show: true,
                        },
                        axisBorder: {
                            show: true,
                            color: '#008FFB'
                        },
                        labels: {
                            style: {
                                colors: '#008FFB',
                            }
                        },
                        title: {
                            text: props.einheit ? "Verbrauch (" + props.einheit + ")" : "Verbrauch",
                            style: {
                                color: '#008FFB',
                            }
                        },
                        tooltip: {
                            enabled: true
                        }
                    }
                ],
                tooltip: {
                    fixed: {
                        enabled: true,
                        position: 'bottomLeft', // topRight, topLeft, bottomRight, bottomLeft
                        offsetY: 30,
                        offsetX: 60
                    },
                },
                legend: {
                    horizontalAlign: 'left' as ApexLegendHorizontalAlign,
                    offsetX: 40
                }
            }
        };

    // componentWillReceiveProps(prevProps) {
    //     if (prevProps.data !== this.data) {
    //         this.data = prevProps.data;
    //         console.log("redraw");
    //         this.setState({
    //             series: this.prepareSeries(this.data),
    //             options: {
    //                 chart: {
    //                     height: 350,
    //                     type: 'bar',
    //                     stacked: false
    //                 },
    //                 dataLabels: {
    //                     enabled: false
    //                 },
    //                 stroke: {
    //                     width: 2
    //                 },
    //                 xaxis: {
    //                     categories: this.monateLabels,
    //                 },
    //                 yaxis: [
    //                     {
    //                         axisTicks: {
    //                             show: true,
    //                         },
    //                         axisBorder: {
    //                             show: true,
    //                             color: '#008FFB'
    //                         },
    //                         labels: {
    //                             style: {
    //                                 colors: '#008FFB',
    //                             }
    //                         },
    //                         title: {
    //                             text: this.einheit ? "Verbrauch (" + this.einheit + ")" : "Verbrauch",
    //                             style: {
    //                                 color: '#008FFB',
    //                             }
    //                         },
    //                         tooltip: {
    //                             enabled: true
    //                         }
    //                     }
    //                 ],
    //                 tooltip: {
    //                     fixed: {
    //                         enabled: true,
    //                         position: 'bottomLeft', // topRight, topLeft, bottomRight, bottomLeft
    //                         offsetY: 30,
    //                         offsetX: 60
    //                     },
    //                 },
    //                 legend: {
    //                     horizontalAlign: 'left',
    //                     offsetX: 40
    //                 }
    //             }
    //         });
    //     }
    // }



    return (
        <div>
            <ReactApexChart options={chartData.options} series={chartData.series} type="bar" height={350}/>
        </div>
    );
}
export default BasicMonatskennzahlen;
