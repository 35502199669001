import { useState } from "react";
import FaktorListItem from "./FaktorListItem";
import { withRouter } from "react-router-dom";
import { listEinflussFaktorbyCompany } from "../../DataBase/EinflussFaktor/EinflussfaktorAPI";
import { useUser } from "../../UserContext";
import { Einflussfaktor } from "../../API";
import { useConstructor } from "../../Util/ReactUtils";
import EnpiButton from "../../ui/EnpiButton";

const FaktorenOverview = () => {
    const { companyID } = useUser();
    const [factors, setFactors] = useState([] as Einflussfaktor[]);

    useConstructor(async () => {
        setFactors(await listEinflussFaktorbyCompany(companyID));
    });

    return (
        <div className="dashboard-root">
            <div className="base-content">

                <div>
                    <header>
                        <h1>Einflussfaktoren</h1>
                    </header>
                    <br/>

                    <div className="table-responsive">
                        <table
                            className="table table-vcenter">
                            <thead>
                            <tr>
                                <th>Bezeichnung</th>
                                <th>Einheit</th>
                                <th></th>
                            </tr>
                            </thead>
                            <tbody>
                            {factors.map((factor, index) =>
                                <FaktorListItem key={index} factor={factor}/>
                            )}
                            </tbody>
                        </table>
                    </div>
                    <EnpiButton link={`/Factor/NewFactor/${companyID}`}>Neuen Einflussfaktor anlegen</EnpiButton>
                </div>
            </div>
        </div>
    );
}

export default withRouter(FaktorenOverview);
