import {useHistory, withRouter} from "react-router-dom";
import {
    deleteEinflussFaktorCascade,
    getEinflussFaktorbyID,
    updateEinflussFaktor
} from "../DataBase/EinflussFaktor/EinflussfaktorAPI";
import GoBackButton from "../Util/GoBackButton";
import TextField from "@mui/material/TextField";
import Grid from "@material-ui/core/Grid";
import {FiTrash2} from "react-icons/fi/index";
import EnpiButton from "../ui/EnpiButton";
import { useConstructor } from "../Util/ReactUtils";
import { useState } from "react";
import { useUser } from "../UserContext";

const Factor = (props) => {
    const { companyID } = useUser();
    const history = useHistory();
    const einflussFaktorID = props.match.params.factor;
    const [state, setState] = useState({
        id: einflussFaktorID,
        einflussFaktorNummer: "",
        einflussFaktorName: "",
        einheit: "",
        verantwortlicher: ""
    });

    const fetchData = async() => {
        let einflussfaktor = await getEinflussFaktorbyID(einflussFaktorID);
        setState(einflussfaktor);
    }

    useConstructor(() => {
        fetchData();
    });

    const saveEinflussFaktor = async() => {
        await save();
        history.push('/Factor/DetailsFactor/' + einflussFaktorID);
    }

    const save = async() => {
        await updateEinflussFaktor(state);
    }

    const deleteEinflussFaktor = async() => {
        await deleteEinflussFaktorCascade(state.id)
        history.push('/Factor/' + companyID);
    }

    return (
        <div className="dashboard-root">
            <div className="base-page">
                <header>
                    <h4>Einflussfaktor bearbeiten
                        <FiTrash2 size={24} className="float-right" title="Messjahr löschen" onClick={deleteEinflussFaktor}/>
                    </h4>
                </header>
                <div className="base-content">
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={6}>
                            <div className="mb-3">
                                <TextField
                                    fullWidth
                                    id="input-einflussFaktorName"
                                    label="Einflussfaktor Name"
                                    name="einflussfaktorname"
                                    InputLabelProps={{shrink: true}}
                                    onChange={e => setState({...state, einflussFaktorName: e.target.value})}
                                    value={state.einflussFaktorName}/>
                            </div>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <div className="mb-3">
                                <TextField
                                    fullWidth
                                    id="input-einheit"
                                    label="Einheit"
                                    name="einheit"
                                    InputLabelProps={{shrink: true}}
                                    onChange={e => setState({...state, einheit: e.target.value})}
                                    value={state.einheit}/>
                            </div>
                        </Grid>
                    </Grid>

                    <EnpiButton onClick={saveEinflussFaktor} disabled={state.einflussFaktorName === "" || state.einheit === ""}>
                        Änderung speichern
                    </EnpiButton>

                    <br/>
                    <GoBackButton/>
                    <br/>
                </div>
            </div>
        </div>
    );
}

export default withRouter(Factor);
