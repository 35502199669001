import { ThemeProvider } from '@mui/material';
import ReactDOM from 'react-dom';
import './index.scss';
import RootNavigator from "./navigation"
import * as serviceWorker from './serviceWorker';
//import theme from './theme';

// const app = () => (
//   <ThemeProvider theme={theme}>
//     <RootNavigator/>
//   </ThemeProvider>
// );

ReactDOM.render(<RootNavigator/>, document.getElementById('root'));
//ReactDOM.render(app(), document.getElementById('root'));

serviceWorker.unregister();