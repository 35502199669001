import React, {Component} from "react";

import ReactApexChart from 'react-apexcharts'


class MessDatenAnalyseCanvasJahre extends Component {

    constructor(props) {
        super(props);
        this.history = props.history;
        this.jahreLabels = [];
        this.monateLabels = ["Jan", 'Feb', 'März', 'April', 'Mai', 'Juni', 'Juli', 'Aug', 'Sep', 'Okt', 'Nov', 'Dez'];
        this.data = props.data;
        this.drawtype = props.type;
        this.einheit = props.einheit;
        this.relaceYLable = props.replace;
        if (!this.drawtype) {
            this.drawtype = "column";
        }
        this.series = this.prepareSeries();

        this.state = {
            series: this.series,
            monat: props.monat || false,
            options: {
                chart: {
                    height: 350,
                    type: 'column',
                    stacked: false
                },
                dataLabels: {
                    enabled: false
                },
                stroke: {
                    width: [3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3]
                },
                xaxis: {
                    categories: this.monateLabels,
                },
                yaxis: [
                    {
                        axisTicks: {
                            show: true,
                        },
                        axisBorder: {
                            show: true,
                            color: '#008FFB'
                        },
                        labels: {
                            style: {
                                colors: '#008FFB',
                            }
                        },
                        title: {
                            text: this.relaceYLable ? this.einheit : " Energieverbrauch (" + this.einheit + ")",
                            style: {
                                color: '#008FFB',
                            }
                        },
                        tooltip: {
                            enabled: true
                        }
                    }
                ],
                tooltip: {
                    fixed: {
                        enabled: true,
                        position: 'bottomLeft', // topRight, topLeft, bottomRight, bottomLeft
                        offsetY: 30,
                        offsetX: 60
                    },
                },
                legend: {
                    horizontalAlign: 'left',
                    offsetX: 40
                }
            }
        }
    }

    prepareSeries() {
        let series = [];
        if (this.data && this.data.length) {
            for (let k = 0; k < this.data.length; k++) {
                let data = [];
                this.jahreLabels.push(this.data[k].val.bezugsJahr || "Kein Jahr Angegeben");
                if (this.data && this.data[k] && this.data[k].val && this.data[k].val.Messpunkte) {
                    for (let i = 0; i <= 11; i++) {
                        if (this.data[k].val.Messpunkte[i]) {

                            data.push(Number.parseFloat(this.data[k].val.Messpunkte[i].toFixed(0)));
                        } else {
                            data.push(null);
                        }
                    }
                }

                series.push({
                    name: this.data[k].val.bezugsJahr || "Kein Jahr Angegeben",
                    type: this.drawtype,
                    data: data
                })
            }
        }
        return series;
    }

    componentWillReceiveProps(prevProps) {
        if ((prevProps.monat !== this.state.monat) || (prevProps.data !== this.data)) {
            this.data = prevProps.data;
            console.log("redraw");
            this.setState({
                series: this.prepareSeries(),
                options: {
                    chart: {
                        height: 350,
                        type: 'column',
                        stacked: false
                    },
                    dataLabels: {
                        enabled: false
                    },
                    stroke: {
                        width: [3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3]
                    },
                    xaxis: {
                        categories: this.monateLabels,
                    },
                    yaxis: [
                        {
                            axisTicks: {
                                show: true,
                            },
                            axisBorder: {
                                show: true,
                                color: '#008FFB'
                            },
                            labels: {
                                style: {
                                    colors: '#008FFB',
                                }
                            },
                            title: {
                                text: " Energieverbrauch (kWh)",
                                style: {
                                    color: '#008FFB',
                                }
                            },
                            tooltip: {
                                enabled: true
                            }
                        }
                    ],
                    tooltip: {
                        fixed: {
                            enabled: true,
                            position: 'bottomLeft', // topRight, topLeft, bottomRight, bottomLeft
                            offsetY: 30,
                            offsetX: 60
                        },
                    },
                    legend: {
                        horizontalAlign: 'left',
                        offsetX: 40
                    }
                }
            });
        }
    }


    render() {
        return (
            <div>
                <ReactApexChart options={this.state.options} series={this.state.series} type="line" height={350}/>
            </div>


        );
    }
}

export default MessDatenAnalyseCanvasJahre;

