import { NavLink } from "react-router-dom";
import { withRouter } from "react-router-dom";
import Burger from "./Burger";
import Avatar from "@mui/material/Avatar";

const NavigationHeader = () => {
    return (
        <div>
            <div className="logo">
                <h3><NavLink to={`/`}>{`EnPI`}</NavLink></h3>
            </div>
            <div className="nav-burger nav-burger-hide">
                <Burger/>
            </div>
            <div className="nav-avatar nav-menue-hide">
                <Avatar></Avatar>
            </div>
        </div>
    );
}

export default withRouter(NavigationHeader);
