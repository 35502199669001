import * as mutations from "../graphql/mutations";
import { getEnergySource, listEnergySources } from "../graphql/queries";
import { CreateEnergySourceInput, CreateEnergySourceMutation, GetEnergySourceQuery, ListEnergySourcesQuery, ModelEnergySourceConditionInput, UpdateTargetMutation } from "../API";
import { API, graphqlOperation } from "aws-amplify";
import { NUMCAMP_COMPANY_ID } from "../Model/Enpi";
import { sanitizeObject } from "../Util/ObjectFilter";
import { MIXED_ENERGY_SOURCE } from "../Model/EnergySource";

export const createEnergySource = async (energySource: CreateEnergySourceInput) => {
  try {
    const res = await API.graphql(
      graphqlOperation(mutations.createEnergySource, { input: energySource })
    ) as { data: CreateEnergySourceMutation };
    return res.data.createEnergySource;
  } catch (error) {
    console.log("Create failed", error)
  }
}

export const updateEnergySource = async (energySource: CreateEnergySourceInput) => {
  const sanitized = sanitizeObject(energySource, {
    id: 'string',
    companyID: 'string',
    name: 'string',
    unit: 'string',
    co2factor: 'number',
    sourceDescription: 'string',
    sourceUrl: 'string',
  });
  try {
    const updatedTarget = await API.graphql(
      graphqlOperation(mutations.updateEnergySource, { input: sanitized })
    ) as { data: UpdateTargetMutation };
    return updatedTarget.data.updateTarget;
  } catch (error) {
    console.log("Update failed", error)
  }
}

export const listEnergySourcesByCompany = async (companyID: string) => {
  try {
    let filter: ModelEnergySourceConditionInput = {
      or: [
        { companyID: { eq: companyID } },
        { companyID: { eq: NUMCAMP_COMPANY_ID } }
      ]
    };
    const res = await API.graphql({
      query: listEnergySources, variables: {
        filter: filter,
        limit: 10000,
      }
    }) as { data: ListEnergySourcesQuery };
    return res.data.listEnergySources.items || [];
  } catch (e) {
    console.log("List failed", e);
  }
}

export const getEnergySourceById = async (id: string) => {
  if (!id) {
    return MIXED_ENERGY_SOURCE;
  }
  try {
    const res = await API.graphql({
      query: getEnergySource, variables: {
        id: id
      }
    }) as { data: GetEnergySourceQuery };
    return res.data.getEnergySource;
  } catch (e) {
    console.log("Get failed", e);
  }
} 