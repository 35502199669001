import React, {Component} from "react";

import {Link, withRouter} from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import {
    getBaselineEnergieKennzahlenById,
    getEnergieKennzahlenJahresDatenByBaselineId
} from "../../DataBase/BaselineEnergieKennzahlen/BaselineEnergieKennzahlenAPI";
import {getProcess} from "../../DataBase/Prozess/ProzessAPI";
import {getTargetbyID} from "../../DataBase/Target/TargetAPI";
import {getEinflussFaktorbyID} from "../../DataBase/EinflussFaktor/EinflussfaktorAPI";
import {getEnergieMessDatenbyId, getFaktorMessDatenbyId, messDatenSatus} from "../../DataBase/MessDaten/MessDatenAPI";
import {prozentualeAbweichung} from "../../Energiekennzahlen/AbweichungsAnalyse/Logik/AbweichungsAnalyseLogik";
import EnergieKennzahlTargetCanvas from "../../Energiekennzahlen/EnergieKennzahlTargetCanvas";
import CircularProgress from "@material-ui/core/CircularProgress";
import {formater, formaterEuro} from "../../Util/helper";

class KennzahlDashboardItem extends Component {

    constructor(props) {

        super(props);
        this.history = props.history;

        this.state = {
            name: "",
            companyID: props.companyID,
            kennzahlenViewlist: [],
            selectedKennzahl: null,
            loading: true,
            showState: false,
            baseline: {
                id: "",
                prozessID: "",
                companyID: "",
                name: "",
                jahr: "",
                target: "",
                einflussfaktorID: "",
                baselineEinflussFaktorMessDatenID: "",
                baselineEnergieverbrauchsDatenID: "",
                energieKennzahlenDaten: []
            },
            process: {
                id: "",
                companyID: "",
                prozessname: "",
                prozessnummer: "",
                referenceJahrEnergieverbrauch: "",
                referenceJahrEnergieverbrauchID: "",
                verantwortlicher: "",
                energiekosten: ""
            },
            einflussfaktor: {
                id: "",
                companyID: "",
                messDaten: [],
                einflussFaktorNummer: "",
                einflussFaktorName: "",
                einheit: "",
                verantwortlicher: ""
            },
            target: {
                id: "",
                name: "",
                amount: "",
                bewertungsfaktor: "",
                savings: "",
                bezugsJahr: "",
                verantwortlicher: "",
                prozessID: "",
            },
            targetEnergieMessDaten: "",
            targetFaktorMessDaten: "",
            energieMessDaten: [],
            einflussFaktorMessDaten: [],
            abweichung: 0,
            abweichungZiel: 0,
            summeNormalisiert: 0,
            normalisiert: undefined,
            gemessen: undefined,
            aktuelleAbweichung: 0,
            abweichungProzentual: [],
            abweichungProzentualAbsolute: 0,
            summegemessen: 0,
            pubaseline: 0,
            pucurrent: 0,
            punorm: 0,
            summeFaktor: 0,
            mediancanvasdaten: undefined
        };
        this.loadData(props.baselineID);
    }

    async loadData(baselineid) {
        if (!baselineid) {
            return;
        }
        let baselineEnergieKennzahlen = await getBaselineEnergieKennzahlenById(baselineid);
        this.setState({baseline: baselineEnergieKennzahlen});
        if (!baselineEnergieKennzahlen) {
            this.setState({showState: true})
            this.setState({loading: false});
            return;
        }
        if (!baselineEnergieKennzahlen.rwert) {
            this.setState({loading: false});
            this.setState({showState: true})
            return;
        }
        this.setState({baseline: baselineEnergieKennzahlen});
        this.state.baseline.energieKennzahlenDaten = await getEnergieKennzahlenJahresDatenByBaselineId(baselineid);


        let processData = await getProcess(baselineEnergieKennzahlen.prozessID);

        if (processData) {
        } else {
            console.log("Error", this.state);
            return;
        }
        if (processData.energiekosten) {
            processData.energiekosten = processData.energiekosten.replaceAll(',', '.');
        }
        this.setState({process: processData});
        if (baselineEnergieKennzahlen.target) {
            let target = await getTargetbyID(baselineEnergieKennzahlen.target);
            this.setState({target: target});
        } else if (processData.aktuellesTarget) {
            let target = await getTargetbyID(processData.aktuellesTarget);
            this.setState({target: target});
        }

        let einflussfaktor = await getEinflussFaktorbyID(baselineEnergieKennzahlen.einflussfaktorID);
        this.setState({einflussfaktor: einflussfaktor});


        let energieMessDaten = [];
        let einflussFaktorMessDaten = [];
        let cancasData = [];
        let yearOfInterest = this.state.target.bezugsJahr;
        let allJahresDaten = this.state.baseline.energieKennzahlenDaten

        let energieMessDatenJahr = await getEnergieMessDatenbyId(this.state.baseline.baselineEnergieverbrauchsDatenID);
        energieMessDatenJahr.status = "Abgeschlossen (Referenzjahr)";
        energieMessDaten.push(energieMessDatenJahr);


        let faktorMessDatenJahr = await getFaktorMessDatenbyId(this.state.baseline.baselineEinflussFaktorMessDatenID);
        faktorMessDatenJahr.status = "Abgeschlossen (Referenzjahr)";
        einflussFaktorMessDaten.push(faktorMessDatenJahr);


        for (let i = 0; i < allJahresDaten.length; i++) {
            if (allJahresDaten[i].jahr === yearOfInterest) {


                let energieMessDatenJahr = await getEnergieMessDatenbyId(allJahresDaten[i].energieverbrauchsDatenID);
                energieMessDatenJahr.status = messDatenSatus(energieMessDatenJahr.Messpunkte);
                energieMessDaten.push(energieMessDatenJahr);
                cancasData.push([].concat(["Gemessen " + energieMessDatenJahr.bezugsJahr], energieMessDatenJahr.Messpunkte));
                this.setState({targetEnergieMessDaten: energieMessDatenJahr.id});

                let faktorMessDatenJahr = await getFaktorMessDatenbyId(allJahresDaten[i].einflussFaktorMessDatenID);
                faktorMessDatenJahr.status = messDatenSatus(faktorMessDatenJahr.Messpunkte);
                this.setState({isMessdatenDefined: (energieMessDatenJahr.status !== "Keine Messdaten" || faktorMessDatenJahr.status !== "Keine Messdaten")});
                einflussFaktorMessDaten.push(faktorMessDatenJahr);
                cancasData.push([].concat(["Normalisiert " + faktorMessDatenJahr.bezugsJahr], faktorMessDatenJahr.Messpunkte));
                this.setState({targetFaktorMessDaten: faktorMessDatenJahr.id});
            }
        }
        this.setState({energieMessDaten: energieMessDaten});
        this.setState({einflussFaktorMessDaten: einflussFaktorMessDaten});
        this.createTargetCanvasData(cancasData);

        if (this.state.energieMessDaten && this.state.energieMessDaten[0] && this.state.energieMessDaten[1]) {
            this.setState({currentKost: this.state.energieMessDaten[this.state.energieMessDaten.length - 1].kostenpu || 0.0});
        } else {
            return;
        }

        let gemessen = this.state.energieMessDaten[1].Messpunkte || [];
        let gemessenFaktor = this.state.einflussFaktorMessDaten[1].Messpunkte || [];
        let gemessenEnergieBaseline = this.state.energieMessDaten[0].Messpunkte || [];
        let gemessenFaktorbaseline = this.state.einflussFaktorMessDaten[0].Messpunkte || [];
        let summeFaktorbaseline = 0;
        let summegemessenEnergiebaseline = 0;
        let normalisiert = [];
        let summeNormalisiert = 0;
        let abweichung = [];
        let abweichungProzentual = [];
        let summe = 0;
        let abweichungProzentualAbsolute = 0;
        let anzahlMonate = 0;
        let abweichungZiel = 0;
        let summegemessen = 0;
        let summeFaktor = 0;


        for (let i = 0; i < 12; i++) {
            if (gemessen[i] && !isNaN(gemessen[i])) {
                summegemessen = Number.parseFloat(summegemessen + gemessen[i]);
            }
            if (gemessenFaktor[i] && !isNaN(gemessenFaktor[i])) {
                summeFaktor = Number.parseFloat(summeFaktor + gemessenFaktor[i]);
                normalisiert.push(this.applyEnergieVerbrauchfunktion(gemessenFaktor[i]));
                summeNormalisiert = Number.parseFloat(summeNormalisiert + normalisiert[i]);
            } else {
                normalisiert.push(null);
            }
            if (gemessen[i] && !isNaN(gemessen[i]) && normalisiert[i] && !isNaN(normalisiert[i])) {

                abweichung.push(gemessen[i] - normalisiert[i]);
                summe = Number.parseFloat(summe + Number.parseFloat((gemessen[i] - normalisiert[i])));
                anzahlMonate++;
                abweichungProzentual.push(prozentualeAbweichung(gemessen[i], normalisiert[i]));
                abweichungProzentualAbsolute = Number.parseFloat(abweichungProzentualAbsolute + prozentualeAbweichung(gemessen[i], normalisiert[i]));

            }
            if (gemessenEnergieBaseline[i] && !isNaN(gemessenEnergieBaseline[i])) {
                summegemessenEnergiebaseline = summegemessenEnergiebaseline + gemessenEnergieBaseline[i];
            }

            if (gemessenFaktorbaseline[i] && !isNaN(gemessenFaktorbaseline[i])) {
                summeFaktorbaseline = summeFaktorbaseline + gemessenFaktorbaseline[i];
            }

            summegemessen = Number.parseFloat(summegemessen);
            summeFaktor = Number.parseFloat(summeFaktor);
            summeNormalisiert = Number.parseFloat(summeNormalisiert);
            summe = Number.parseFloat(summe);
            abweichungProzentualAbsolute = Number.parseFloat(abweichungProzentualAbsolute);
        }


        this.setState({summeFaktor: summeFaktor});
        this.setState({abweichung: summe});
        this.setState({summeNormalisiert: summeNormalisiert});
        this.setState({abweichungProzentual: abweichungProzentual});
        this.setState({normalisiert: normalisiert});
        this.setState({gemessen: gemessen});
        this.setState({summegemessen: summegemessen});

        this.setState({abweichungProzentualAbsolute: abweichungProzentualAbsolute / anzahlMonate});

        abweichungZiel = Number.parseFloat(summe) * (Number.parseFloat("0." + (100.00 - (Number.parseFloat(this.state.target.amount)))));
        this.setState({abweichungZiel: abweichungZiel});

        let aktuelleAbweichung = summegemessen - summeNormalisiert;

        this.setState({aktuelleAbweichung: aktuelleAbweichung});

        let pucurrent = Number.parseFloat(summegemessen) / Number.parseFloat(summeFaktor);
        let pubaseline = Number.parseFloat(summegemessenEnergiebaseline) / Number.parseFloat(summeFaktorbaseline);
        let punorm = Number.parseFloat(summeNormalisiert) / Number.parseFloat(summeFaktor);

        this.setState({pubaseline: pubaseline});
        this.setState({pucurrent: pucurrent});
        this.setState({punorm: punorm});
        this.setState({einheit: this.state.process.einheit});
    }

    createTargetCanvasData(energieMessDaten) {
        let amount = 0.0;
        if (this.state.target && this.state.target.amount) {
            amount = Number.parseFloat(this.state.target.amount);
        }
        let header = ['Monat', "Jan", 'Feb', 'März', 'April', 'Mai', 'Juni', 'Juli', 'Aug', 'Sep', 'Okt', 'Nov', 'Dez'];
        let canvas = [];
        for (let i = 0; i < header.length; i++) {
            if (energieMessDaten && energieMessDaten[0] && energieMessDaten[1]) {
                let line = [header[i], energieMessDaten[0][i], energieMessDaten[1][i]];
                if (i !== 0) {
                    line = [header[i], energieMessDaten[0][i], Number.parseFloat(((this.applyEnergieVerbrauchfunktion(energieMessDaten[1][i]) / 100 * (100 - amount)).toFixed(0)))];
                }
                canvas.push(line);
            }
        }

        this.setState({targetcanvasdaten: canvas});
        this.setState({loading: false});
    }

    applyEnergieVerbrauchfunktion(energieKennzahlMonat) {
        if (this.state.baseline.energieCoefizent && this.state.baseline.energieIntercept) {
            return Number.parseFloat(((Number.parseFloat(this.state.baseline.energieCoefizent) * Number.parseFloat(energieKennzahlMonat)) + Number.parseFloat(this.state.baseline.energieIntercept)).toFixed(2));
        }
        return Number.parseFloat(energieKennzahlMonat);
    }


    render() {
        return (
            this.state.showState ?
                <div>  
                </div>
                :
                this.state.loading ?
                    <div className="loading-screen">
                        <CircularProgress className="loading-icon"/>
                    </div>
                    :
                    <div>
                        <Grid container spacing={1}>
                            <Grid item xs={12} sm={12}>
                                <Link className="grid-contents font-large font-blue"
                                      to={`/EnergieKennzahl/AbweichungsAnalyse/${this.state.baseline.id}`}>{this.state.baseline.name}

                                </Link>
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <div className="grid-contents">
                                    <div className="base-content-grid">

                                        <header className="border-bottom">
                                            <h6 className="medium-font">Verbrauch {this.state.baseline.jahr}
                                            </h6>
                                            <div className="small-font">pro {this.state.einflussfaktor.einheit},
                                                gemessen
                                            </div>
                                        </header>

                                        <div className="font-large">
                                            {this.state.pubaseline ?
                                                formater(this.state.pubaseline) + " " + this.state.einheit
                                                :
                                                ""
                                            }
                                        </div>
                                    </div>
                                </div>
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <div className="grid-contents">

                                    <div className="base-content-grid">

                                        <header className="border-bottom">
                                            <h6 className="medium-font">Verbrauch {this.state.target.bezugsJahr}
                                            </h6>
                                            <div className="small-font">pro {this.state.einflussfaktor.einheit},
                                                gemessen
                                            </div>
                                        </header>
                                        <div className="font-large">
                                            {this.state.pucurrent ?
                                                formater(this.state.pucurrent) + " " +this.state.einheit
                                                :
                                                "Messdaten fehlen"
                                            }
                                        </div>
                                    </div>
                                </div>
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <div className="grid-contents">
                                    <div className="base-content-grid">

                                        <header className="border-bottom">
                                            <h6 className="medium-font">Veränderung in %</h6>
                                            <div
                                                className="small-font">Verbrauch {this.state.baseline.jahr} vs. {this.state.target.bezugsJahr}</div>
                                        </header>
                                        <div className="font-large">
                                            {(this.state.pubaseline && this.state.pucurrent) ?
                                                formater(-1 * (prozentualeAbweichung(this.state.pubaseline, this.state.pucurrent))) + " %"
                                                :
                                                "Messdaten fehlen"
                                            }
                                        </div>
                                    </div>
                                </div>
                            </Grid>

                        </Grid>
                       
                    </div>
        )
    }
}

export default withRouter(KennzahlDashboardItem);

