import React, {Component} from "react";
import {Link, withRouter} from "react-router-dom";
import {BsThreeDotsVertical} from "react-icons/bs/index";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import {deleteBaselineEnergieKennzahlenCascade} from "../../DataBase/BaselineEnergieKennzahlen/BaselineEnergieKennzahlenAPI";


class KennzahlenListItem extends Component {
    constructor(props) {
        super(props);
        this.history = props.history;
        this.state = props.state;
        if (props.state && props.state.value) {
            this.value = this.state.value;
        }

    }

    handleOpen(event) {
        this.setState({anchorEl: event.currentTarget})


    }

    handleClose() {
        this.setState({anchorEl: false})
    }

    async deleteKennzahl() {
        await deleteBaselineEnergieKennzahlenCascade(this.state.val.id)
        window.location.reload(false);
    }

    render() {
        return (
            <tr key={this.state.key.toString()}>
                <td>
                    <Link
                        to={`/EnergieKennzahl/AbweichungsAnalyse/${this.state.val.id}`}>{this.state.val.name}

                    </Link>
                </td>
                <td className="text-dark">
                    {this.state.val.jahr}
                </td>
                <td className="text-dark">
                    {this.state.target && this.state.target.bezugsJahr}
                </td>
                <td className="text-end">
                    <BsThreeDotsVertical onClick={e => this.handleOpen(e)}/>
                    <Menu
                        id="simple-menu"
                        anchorEl={this.state.anchorEl}
                        keepMounted
                        open={Boolean(this.state.anchorEl)}
                        onClose={() => this.handleClose()}
                    >
                        <MenuItem> <Link
                            to={`/EnergieKennzahl/AbweichungsAnalyse/${this.state.val.id}`}>Bearbeiten</Link></MenuItem>
                        <MenuItem onClick={() => this.deleteKennzahl()}>Löschen</MenuItem>
                    </Menu>
                </td>

            </tr>

        );

    }
}

export default withRouter(KennzahlenListItem);
