import { useState } from "react";
import { Link } from "react-router-dom";
import { formater } from "../../Util/helper";
import { FiTrash2 } from "react-icons/fi";
import { deleteEnergieMessdatenByID } from "../../DataBase/MessDaten/MessDatenAPI";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import { Button } from "react-bootstrap";

interface EnergieMessDatenListItemProps {
    state: any;
    energietraeger: any; //FIXME rename this to co2factor, see below
    editable: boolean;
}

const EnergieMessDatenListItem = (props: EnergieMessDatenListItemProps) => {
    const [open, setOpen] = useState(false);
    const co2factor = props.energietraeger;

    const isMesspunkteComplete = (messpunkte) => {
        if (messpunkte && messpunkte.length === 12) {
            for (let i = 0; i < messpunkte.length; i++) {
                if (!isMesspunktDefined(messpunkte[i])) {
                    return "Unvollständig"
                }
            }
            return "Vollständig";
        }
        return "Unvollständig";
    }

    const isMesspunktDefined = (punkt) => {
        return (0 === punkt || (punkt && punkt !== ""));
    }

    const deleteMessJahr = async () => {
        await deleteEnergieMessdatenByID(props.state.val.id);
        handleClose();
        window.location.reload();
    }

    const handleClickOpen = () => {
        setOpen(true);
    }

    const handleClose = () => {
        setOpen(false);
    }

    return (
        <tr key={props.state.key.toString()}>
            <td>{props.state.val.bezugsJahr}</td>
            <td className="text-dark text-right">
                {props.state.val.summeMessdaten && formater(props.state.val.summeMessdaten)}
            </td>
            <td className="text-dark text-right">
                {props.state.val.summeMessdaten && props.state.val.kostenpu && formater((props.state.val.summeMessdaten * Number.parseFloat(props.state.val.kostenpu)))}
            </td>
            <td className="text-dark text-right">
                {props.state.val.summeMessdaten && co2factor && formater((props.state.val.summeMessdaten * co2factor))} kg
            </td>
            <td className="text-dark">
                {props.editable &&
                    <Link to={`/MessDaten/EnergieDaten/${props.state.val.id}`}>{isMesspunkteComplete(props.state.val.Messpunkte)}</Link>
                }
                {!props.editable &&
                    isMesspunkteComplete(props.state.val.Messpunkte)
                }
            </td>
            {props.editable &&
            <td>
                <FiTrash2 size={24} className="float-right" title="Messjahr löschen" onClick={handleClickOpen}/>
                <div>
                    <Dialog
                        fullScreen={false}
                        open={open}
                        onClose={handleClose}
                        aria-labelledby="responsive-dialog-title"
                    >
                        <DialogTitle id="responsive-dialog-title">
                            {"Messdaten löschen"}
                        </DialogTitle>
                        <DialogContent>
                            <DialogContentText>
                                Bist du sicher, dass du die Messdaten
                                für {props.state.val.bezugsJahr ? "das Jahr " + props.state.val.bezugsJahr : " unbekanntes Jahr"} {props.state.val.prozessname} löschen
                                möchtest?
                                Dies kann nicht rückgängig gemacht werden.
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button className="red-button" onClick={() => deleteMessJahr()}>
                                Löschen
                            </Button>
                            <Button className="link-button" onClick={handleClose}>
                                Abbrechen
                            </Button>
                        </DialogActions>
                    </Dialog>
                </div>
            </td>
            }
        </tr>
    );
}


export default EnergieMessDatenListItem;
