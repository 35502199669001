import {API, graphqlOperation} from "aws-amplify";
import {createTarget, updateTarget} from "../../graphql/mutations";
import {getProcess, updateProcess} from "../Prozess/ProzessAPI";
import {getTarget} from "../../graphql/queries";
import { CreateTargetMutation, GetTargetQuery, UpdateTargetMutation } from "../../API";

export const createNewTarget = async (state) => {
    try {
        const target = {
            name: state.name,
            amount: state.amount,
            bezugsJahr: state.bezugsJahr,
            verantwortlicher: state.verantwortlicher,
            bewertungsfaktor: state.bewertungsfaktor,
            prozessID: state.prozessID
        }

        const newTarget = await API.graphql(
            graphqlOperation(createTarget, {input: target})
        ) as { data: CreateTargetMutation };

        let process = await getProcess(state.prozessID);

        process.aktuellesTarget = newTarget.data.createTarget.id;
        let targets = [];
        if (process.targets) {
            targets.push(process.targets)
        }
        targets.push(process.aktuellesTarget);
        process.targets = targets;
        await updateProcess(process);
        return newTarget.data.createTarget;
    } catch (error) {
        console.log("Fehler beim Speichern des Targets", error)
    }
}

export const updateTargetbyState = async (state) => {
    try {
        const target = {
            id: state.id,
            name: state.name,
            amount: state.amount,
            bezugsJahr: state.bezugsJahr,
            verantwortlicher: state.verantwortlicher,
            bewertungsfaktor: state.bewertungsfaktor,
            prozessID: state.prozessID
        };
        const updatedTarget = await API.graphql(
            graphqlOperation(updateTarget, {input: target})
        ) as { data: UpdateTargetMutation };
        return updatedTarget.data.updateTarget;
    } catch (error) {
        console.log("Fehler beim Speichern des Targets", error)
    }
}

export const getTargetbyID = async (targetID: string) => {
    try {
        const targetData = await API.graphql({
            query: getTarget, variables: {
                id: targetID
            }
        }) as { data: GetTargetQuery };
        return targetData.data.getTarget;
    } catch (error) {
        console.log("Fetch Faild ", error);
    }
}
