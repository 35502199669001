import { Component } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";

import Dashboard from "./Dashboard/Dashboard";
import Prozess from "./Prozess/Prozess"
import NewProzess from "./Prozess/NewProzess"
import Reference from "./MessDaten/MessDaten";
import NewFactor from "./Factor/NewFactor";
import Factor from "./Factor/Factor";
import NotFound404 from "./404";

import NavigationHeader from "./NavigationHeader/NavigationHeader";
import Company from "./Admin/Company/Company";
import { UserContext, UserProvider } from "./UserContext";
import EnergieMessDatenOverview from "./Prozess/EnergieMessDaten/EnergieMessDatenOverview";
import FaktorMessDatenOverview from "./Factor/FaktorMessDaten/FaktorMessDatenOverview";
import { withAuthenticator } from "@aws-amplify/ui-react";
import Amplify from "aws-amplify";
import awsconfig from "./aws-exports";
import SelectProcessOverview from "./Energiekennzahlen/Flow/ProzessSelection/SelectProcessOverview";
import TargetSelection from "./Energiekennzahlen/Flow/TargetSelection/TargetSelection";
import SelectFaktorenOverview from "./Energiekennzahlen/Flow/FaktorSelection/SelectFaktorenOverview";
import Target from "./Dashboard/Target/Target";
import ProcessOverview from "./Dashboard/Processe/ProcessOverview";
import FaktorenOverview from "./Dashboard/Faktoren/FaktorenOverview";
import KennzahlenOverview from "./Dashboard/EnergieKennzahl/KennzahlenOverview";
import AbweichungsAnalyse from "./Energiekennzahlen/AbweichungsAnalyse/AbweichungsAnalyse";
import DetailsFactor from "./Factor/DetailsFactor";
import Grid from "@material-ui/core/Grid";
import NavigationItems from "./NavigationHeader/NavigationItems";
import Settings from "./Settings/Settings";
import DetailsProzess from "./Prozess/DetailsProzess";
import VirtualConsumerEdit from "./Prozess/VirtualConsumerEdit";
import EnergySourceEditor from "./EnergySource/EnergySourceEditor";

Amplify.configure(awsconfig);

class RootNavigator extends Component {
  render() {
    return (
      <div className="nav-root">
        <UserProvider>
          <BrowserRouter>
            <NavigationHeader />

            <Grid container spacing={3}>
              <Grid item xs={12} sm={2}>
                <div className="nav-menue-hide">
                  <NavigationItems />
                </div>
              </Grid>
              <Grid item xs={12} sm={10}>
                <div className="base-page">
                  <Switch>
                    <Route exact path={"/"} component={Dashboard} />

                    <Route exact path={"/Prozess/NewProzess/:company"} component={NewProzess} />
                    <Route exact path={"/Prozess/Prozess/:process"} component={Prozess} />
                    <Route exact path={"/Prozess/DetailsProzess/:process"}
                      component={DetailsProzess} />
                    <Route exact path={"/Prozess/:companyID"} component={ProcessOverview} />
                    <Route exact path={"/Prozess/EnergieMessDaten/:process"}
                      component={EnergieMessDatenOverview} />

                    <Route exact path={"/VirtualConsumer/New/:company"} render={() => <VirtualConsumerEdit mode='NEW' />} />
                    <Route exact path={"/VirtualConsumer/Edit/:consumer"} render={() => <VirtualConsumerEdit mode='EDIT' />} />

                    <Route exact path={"/Factor/NewFactor/:company"} component={NewFactor} />
                    <Route exact path={"/Factor/Factor/:factor"} component={Factor} />
                    <Route exact path={"/Factor/DetailsFactor/:factor"} component={DetailsFactor} />
                    <Route exact path={"/Factor/:companyID"} component={FaktorenOverview} />
                    <Route exact path={"/Factor/FactorMessDaten/:process"}
                      component={FaktorMessDatenOverview} />

                    <Route exact path={"/MessDaten/:type/:id/:canNavigateBack"} component={Reference} />
                    <Route exact path={"/MessDaten/:type/:id"} component={Reference} />

                    <Route exact path={"/EnergieKennzahl/NewEnergieKennzahlProcess/:id"} component={SelectProcessOverview} />
                    <Route exact path={"/EnergieKennzahl/NewEnergieKennzahlTarget/:id"} component={TargetSelection} />
                    <Route exact
                      path={"/EnergieKennzahl/NewEnergieKennzahlFaktor/:id/:company/:target"}
                      component={SelectFaktorenOverview} />
                    <Route exact path={"/EnergieKennzahl/:companyID"} component={KennzahlenOverview} />
                    <Route exact path={"/EnergieKennzahl/AbweichungsAnalyse/:id"} component={AbweichungsAnalyse} />

                    <Route exact path={"/Settings/:companyID"} component={Settings} />
                    <Route exact path={"/Settings/EnergySource/New"} render={() => <EnergySourceEditor mode='NEW' />} />
                    <Route exact path={"/Settings/EnergySource/Edit/:id"} render={() => <EnergySourceEditor mode='EDIT' />} />

                    <Route exact path={"/Target/:id"} component={Target} />

                    <Route exact path="/404" component={NotFound404} />
                    <Route exact path="/Company" component={Company} />
                  </Switch>
                </div>
              </Grid>
            </Grid>
          </BrowserRouter>
        </UserProvider>
      </div>
    );
  }
}

export default withAuthenticator(RootNavigator);
