import React, {Component} from "react";

import ReactApexChart from 'react-apexcharts'


class MessDatenAnalyseCanvasMonat extends Component {

    constructor(props) {
        super(props);
        this.jahreLabels = [];
        this.monateLabels = ["Januar", 'Februar', 'März', 'April', 'Mai', 'Juni', 'Juli', 'August', 'September', 'Oktober', 'November', 'Dezmber'];
        this.data = props.data;
        this.monatsDaten = [];
        //Monate
        for (let k = 0; k < this.data.length; k++) {
            this.jahreLabels.push(this.data[k].val.bezugsJahr|| "Kein Jahr Angegeben");
        }
        for (let i = 0; i <= 11; i++) {
            let monat = [];
            for (let k = 0; k < this.data.length; k++) {
                //this.jahreLabels.push(this.data[k].val.bezugsJahr|| "Kein Jahr Angegeben");
                if (this.data && this.data[k] && this.data[k].val && this.data[k].val.Messpunkte && this.data[k].val.Messpunkte[i]) {
                    monat.push(this.data[k].val.Messpunkte[i].toFixed(0));
                } else {
                    monat.push(null);
                }
            }
            this.monatsDaten.push(monat);
        }

        this.state = {
            monat: props.monat || 0,
            series: [{
                name: this.monateLabels[props.monat || 0],
                data: this.monatsDaten[props.monat || 0]
            }],
            options: {
                chart: {
                    height: 350,
                    stacked: false
                },
                dataLabels: {
                    enabled: false
                },
                stroke: {
                    width: [3, 3, 3]
                },
                xaxis: {
                    categories: this.jahreLabels,
                },
                yaxis: [
                    {
                        axisTicks: {
                            show: true,
                        },
                        axisBorder: {
                            show: true,
                            color: '#008FFB'
                        },
                        labels: {
                            style: {
                                colors: '#008FFB',
                            }
                        },
                        title: {
                            text: this.monateLabels[props.monat || 0],
                            style: {
                                color: '#008FFB',
                            }
                        },
                        tooltip: {
                            enabled: true
                        }
                    }
                ],
                tooltip: {
                    fixed: {
                        enabled: true,
                        position: 'bottomLeft', // topRight, topLeft, bottomRight, bottomLeft
                        offsetY: 30,
                        offsetX: 60
                    },
                },
                legend: {
                    horizontalAlign: 'left',
                    offsetX: 40
                }
            }
        }
    }

    componentWillReceiveProps(prevProps) {
        if (prevProps.monat !== this.state.monat) {
            this.setState({
                monat: prevProps.monat,
                series: [{
                    name: this.monateLabels[prevProps.monat],
                    data: this.monatsDaten[prevProps.monat]
                }],
                options: {
                    chart: {
                        height: 350,
                        stacked: false
                    },
                    dataLabels: {
                        enabled: false
                    },
                    stroke: {
                        width: [1, 1, 1]
                    },
                    xaxis: {
                        categories: this.jahreLabels,
                    },
                    yaxis: [
                        {
                            axisTicks: {
                                show: true,
                            },
                            axisBorder: {
                                show: true,
                                color: '#008FFB'
                            },
                            labels: {
                                style: {
                                    colors: '#008FFB',
                                }
                            },
                            title: {
                                text: this.monateLabels[prevProps.monat],
                                style: {
                                    color: '#008FFB',
                                }
                            },
                            tooltip: {
                                enabled: true
                            }
                        }
                    ],
                    tooltip: {
                        fixed: {
                            enabled: true,
                            position: 'bottomLeft', // topRight, topLeft, bottomRight, bottomLeft
                            offsetY: 30,
                            offsetX: 60
                        },
                    },
                    legend: {
                        horizontalAlign: 'left',
                        offsetX: 40
                    }
                }
            });
        }
    }


    render() {
        return (
            <div>
                <ReactApexChart options={this.state.options} series={this.state.series} type="bar" height={350}/>
            </div>
        );
    }
}

export default MessDatenAnalyseCanvasMonat;

