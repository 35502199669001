import React, { Component, createRef } from "react";

import {
	Grid,
	Collapse,
	Button
} from '@mui/material';

import ReactApexChart from 'react-apexcharts'
import { FiChevronDown, FiChevronUp } from "react-icons/fi";

import * as API from "../../API";

import FilterSelector from './FilterSelector';

interface DiagramData {
	categories?: string[],
	labels?: string[],
	series: any[]
}

type SortOrder = 'none' | 'alphabetically' | 'ascending-by-value' | 'descending-by-value';
type DiagramFilterType = 'energy-source' | 'year' | 'consumer-type' | 'consumer-type-ext';
type ConsumerType = 'all' | 'measured' | 'virtual' | 'energy-source';
type DiagramDataProvider = (enerySource?: API.EnergySource, year?: string, consumerType?: ConsumerType, sortOrder?: SortOrder) => DiagramData;

interface DiagramProps {
	energySources: API.EnergySource[],
	filters: DiagramFilterType[],
	years: string[],
	dataProvider: DiagramDataProvider,
	allowSort: boolean,
	diagramType: 'energy-usage' | 'co2-by-month' | 'co2-by-year' | 'co2',
	title: string
}

interface DiagramState {
	energySource: API.EnergySource | '',
	year: string | '',
	consumerType: ConsumerType | '',
	categories: string[],
	labels: string[],
	series: any[],
	sortOrder: SortOrder,
	diagramWidth: number,
	maxSeriesValue: number,
	axisScaleFactor: number,
	expandExtraSeries: boolean
}

interface FilterOption<T> {
	value: T,
	key: string,
	label: string
}

interface ConsumerTypeOption extends FilterOption<ConsumerType> {}

const CONSUMER_TYPE_OPTIONS: ConsumerTypeOption[] = [{
	value: 'measured',
	key: 'measured',
	label: 'Gemessen'
}, {
	value: 'virtual',
	key: 'virtual',
	label: 'Virtuell'
}, {
	value: 'all',
	key: 'all',
	label: 'Alle'
}];

const CONSUMER_TYPE_OPTIONS_EXT: ConsumerTypeOption[] = [{
	value: 'energy-source',
	key: 'energy-source',
	label: 'Energieträger'
} as ConsumerTypeOption].concat(CONSUMER_TYPE_OPTIONS);

interface SortOrderOption extends FilterOption<SortOrder> {}

const SORT_ORDER_OPTIONS: SortOrderOption[] = [{
	value: 'alphabetically',
	key: 'alphabetically',
	label: 'alphabetisch'
}, {
	value: 'ascending-by-value',
	key: 'ascending-by-value',
	label: 'niedrigsten Verbrauch'
}, {
	value: 'descending-by-value',
	key: 'descending-by-value',
	label: 'höchsten Verbrauch'
}];

function selectDefaultOrNone<T>(
	currentValue: T | '',
	newValues: T[],
	getDefault = (values) => values[0]
): (T | '') {
	if (newValues.length > 0) {
		if (currentValue === '' || !newValues.includes(currentValue)) {
			return getDefault(newValues);
		} else {
			return currentValue;
		}
	} else {
		return '';
	}
}

function findEnergieMix(values: API.EnergySource[]): API.EnergySource {
	const energieMix = values.find(({ name }) => name === 'Elektrischer Strom Mix');
	
	return energieMix || values[0];
}

function wrapLabel (s: string, fontSize: string, fontFamily: string, maxWidth = 125, maxLines = 2): (string | string[]) {
	const svgNS = 'http://www.w3.org/2000/svg';
	const textElement = document.createElementNS(svgNS, 'text');
	const svgElement = document.querySelector('.apexcharts-svg');

	textElement.setAttributeNS(null, 'x', '-100');
	textElement.setAttributeNS(null, 'y', '-100');
	textElement.setAttributeNS(null, 'fill', 'black');
	textElement.setAttributeNS(null, 'font-size', fontSize);
	textElement.setAttributeNS(null, 'font-family', fontFamily);
	textElement.replaceChildren(document.createTextNode(s));
	svgElement.appendChild(textElement);
	const originalWidth = textElement.getBBox().width;
	
	if (originalWidth > maxWidth) {
		const parts = [];
		let part = '';
		
		for (const c of s) {
			if (c === ' ') {
				parts.push(part);
				parts.push(' ');
				part = '';
			} else if (c === '-') {
				parts.push(part + c);
				part = '';
			} else {
				part += c;
			}
		}
		
		if (part !== '') {
			parts.push(part);
		}
		
		const lines = [];
		let line = '';
		let lineWidth = 0;
		let partIndex = 0;
		
		for (; partIndex < parts.length && lines.length < maxLines; partIndex++) {
			const part = parts[partIndex];
			
			textElement.replaceChildren(document.createTextNode(part === ' ' ? '\u00A0' : part));
			const partWidth = textElement.getBBox().width;
			
			if (lineWidth + partWidth > maxWidth) {
				if (line.length > 0) {
					lines.push(line);
					line = part !== ' ' ? part : '';
					lineWidth = part !== ' ' ? partWidth : 0;
				} else if (lines.length < maxLines - 1) {
					lineWidth = 0;
					let i = 0;
					
					for (; i < part.length && lines.length < maxLines; i++) {
						const c = part[i];
						
						textElement.replaceChildren(document.createTextNode(c));
						const characterWidth = textElement.getBBox().width;
						
						if (lineWidth + characterWidth > maxWidth) {
							lines.push(line);
							line = c;
							lineWidth = characterWidth;
						} else {
							line += c;
							lineWidth += characterWidth;
						}
					}
					
					for (; i < part.length; i++) {
						line += part[i];
					}
				} else {
					lines.push(part);
				}
			} else {
				line += part;
				lineWidth += partWidth;
			}
		}
		
		if (lines.length < maxLines) {
			if (line !== '') {
				lines.push(line);
			}
		} else {
			lines[lines.length - 1] += line;
		}
		
		for (; partIndex < parts.length; partIndex++) {
			lines[lines.length - 1] += parts[partIndex];
		}
	
		svgElement.removeChild(textElement);
		
		return lines.map((line) => line.trim());
	} else {
		svgElement.removeChild(textElement);

		return s;
	}
}

function shortenNumber (value: number, factor = 1): string {
	const shortValue = factor > 0 ? value / factor : value;
	const numberOfDigits =
		(shortValue * 10) % 1 > 0
			? 2
			: shortValue % 1 > 0
				? 1
				: 0;
	
	return formatNumber(shortValue, numberOfDigits);
}

function formatWeight (value: number, digits = 0, mode = 't'): string {
	if (mode === 't') {
		return formatNumber(value / 1000, digits, 0.01) + 't';
	} else {
		return formatNumber(value, digits, 0.01) + 'kg';
	}
}

function formatNumber (value: number, numberOfDecimalPlaces = 0, smallValueThreshold?: number): string {
	if (value === undefined || value === null) {
		return '';
	} else if (typeof value !== 'number') {
		return value;
	} else if (value === Infinity) {
		return '' + value;
	} else {
		const sign = value < 0 ? '-' : '';
		const absValue = Math.abs(value)
		const belowSmallValueThreshold = smallValueThreshold > 0 && absValue > 0 && absValue < smallValueThreshold
		const fixedValue = (belowSmallValueThreshold ? smallValueThreshold : absValue).toFixed(numberOfDecimalPlaces);
		const decimalPlaces = numberOfDecimalPlaces > 0 ? ',' + fixedValue.slice(-numberOfDecimalPlaces) : '';
		let integerPlaces = numberOfDecimalPlaces > 0 ? fixedValue.slice(0, -numberOfDecimalPlaces - 1) : fixedValue;
		const parts = [];
		
		while (integerPlaces.length > 3) {
			parts.unshift(integerPlaces.slice(-3));
			integerPlaces = integerPlaces.slice(0, -3);
		}
		
		parts.unshift(integerPlaces);
		
		return (belowSmallValueThreshold ? (sign ? '>' : '<') : '') + sign + parts.join('.') + decimalPlaces;
	}
}

function formatUnit (energySource: API.EnergySource | '', axisScaleFactor = 1) {
	const prefix = axisScaleFactor < 1000 ? '' : axisScaleFactor < 1000000 ? 'tsd. ' : 'Mio. ';
	
	return prefix + ((energySource || {}).unit || '-');
}

function getMaxY (opts: any | any[]): number | undefined {
	if (opts instanceof Array) {
		if (opts.length > 0) {
			const diagramOptions = opts[opts.length - 1];
			return (((diagramOptions || {}).w || diagramOptions || {}).globals || {}).maxY;
		} else {
			return undefined;
		}
	} else {
		return (((opts || {}).w || opts || {}).globals || {}).maxY;
	}
}

class Diagram extends Component<DiagramProps, DiagramState> {
	deferDiagramUpdate: ReturnType<typeof setTimeout> | null = null;
	deferDiagramWidthUpdate: ReturnType<typeof setTimeout> | null = null;
	onResize = () => {
		this.updateDiagramWidth();
	};
	diagramRef: any;
	
	constructor(props) {
        super(props);
		
		this.diagramRef = createRef();
		
		const energySource = selectDefaultOrNone<API.EnergySource>('', props.energySources || [], findEnergieMix);
		const year = selectDefaultOrNone<string>('', props.years || []);
		const consumerType = selectDefaultOrNone<ConsumerType>('', CONSUMER_TYPE_OPTIONS.map(({ value }) => value));
		
		this.state = {
			energySource,
			year,
			consumerType,
			categories: [],
			labels: [],
			series: [],
			sortOrder: 'descending-by-value',
			diagramWidth: 0,
			maxSeriesValue: 0,
			axisScaleFactor: 1,
			expandExtraSeries: false
		}
	}
	
	componentDidMount () {
		this.updateDiagramData();
		this.updateDiagramWidth();
		window.addEventListener('resize', this.onResize);
	}

	componentWillUnmount() {
		window.removeEventListener('resize', this.onResize);
	}
	
	updateDiagramData () {
		if (this.deferDiagramUpdate !== null) {
			clearTimeout(this.deferDiagramUpdate);
			this.deferDiagramUpdate = null;
		}
		
		if (
			this.deferDiagramUpdate === null
			&& typeof this.props.dataProvider === 'function'
		) {
			this.deferDiagramUpdate = setTimeout(() => {
				const { categories, labels, series } = this.props.dataProvider(
					this.state.energySource !== '' ? this.state.energySource : undefined,
					this.state.year !== '' ? this.state.year : undefined,
					this.state.consumerType !== '' ? this.state.consumerType : undefined,
					this.state.sortOrder
				);
				
				const maxSeriesValue = series.reduce(
					(accumulator, entry) =>
						Math.max(accumulator, (entry.data ? Math.max(...entry.data) : entry))
					, 0
				);
				
				const axisScaleFactor = maxSeriesValue < 1000 ? 1 : maxSeriesValue < 1000000 ? 1000 : 1000000;

				this.setState({
					categories: categories || [],
					labels: labels || [],
					series,
					maxSeriesValue,
					axisScaleFactor
				});
				this.deferDiagramUpdate = null;
			}, 100);
		}
	}
	
	updateDiagramWidth () {
		if (this.deferDiagramWidthUpdate !== null) {
			clearTimeout(this.deferDiagramWidthUpdate);
			this.deferDiagramWidthUpdate = null;
		}
		if (this.deferDiagramWidthUpdate === null) {
			this.deferDiagramWidthUpdate = setTimeout(() => {
				const bound = this.diagramRef.current.getBoundingClientRect();
				
				this.setState({ diagramWidth: bound.width - 16 + (this.props.diagramType === 'energy-usage' ? 30 : 0) });
			}, 100);
		}
	}
	
	componentDidUpdate (previousProps, previousState) {
		if (previousProps.energySources !== this.props.energySources) {
			this.setState({ energySource: selectDefaultOrNone<API.EnergySource>(this.state.energySource, this.props.energySources || [], findEnergieMix) });
		}
		
		if (previousProps.years !== this.props.years) {
			this.setState({ year: selectDefaultOrNone<string>(this.state.year, this.props.years || []) });
		}
		
		if (
			previousState.energySource !== this.state.energySource
			|| previousState.year !== this.state.year
			|| previousState.consumerType !== this.state.consumerType
			|| previousState.sortOrder !== this.state.sortOrder
		) {
			this.updateDiagramData();
		}
	}
	
    render() {
        return (
			<Grid className="dashboard-diagram" item xs={12} lg={6}>
				<Grid
					container
					columnSpacing={2}
					ref={this.diagramRef}
				>
					<Grid item xs={12}>
						<h3>{this.props.title}</h3>
					</Grid>
					{(this.props.filters || []).map((filter) => {
						const width = 12 / this.props.filters.length;
						
						if (filter === 'energy-source') {
							return <Grid key='energy-source' item xs={width}>
								<FilterSelector
									label="Energieträger"
									items={(this.props.energySources || []).map((source) => ({ value: source, key: source.id, label: source.name }))}
									value={this.state.energySource}
									onChange={(event) => this.setState({ energySource: (event.target.value as any) as API.EnergySource })}
								/>
							</Grid>
						} else if (filter === 'year') {
							return <Grid key='year' item xs={width}>
								<FilterSelector
									label="Jahr"
									items={(this.props.years || []).map((year) => ({ value: year, key: year, label: year }))}
									value={this.state.year}
									onChange={(event) => this.setState({ year: event.target.value })}
								/>
							</Grid>
						} else if (filter === 'consumer-type') {
							return <Grid key='consumer-type' item xs={width}>
								<FilterSelector
									label="Verbraucherart"
									items={CONSUMER_TYPE_OPTIONS}
									value={this.state.consumerType}
									onChange={(event) => this.setState({ consumerType: (event.target.value as any) as ConsumerType })}
								/>
							</Grid>
						} else if (filter === 'consumer-type-ext') {
							return <Grid key='consumer-type-ext' item xs={width}>
								<FilterSelector
									label="Verbraucherart"
									items={CONSUMER_TYPE_OPTIONS_EXT}
									value={this.state.consumerType}
									onChange={(event) => this.setState({ consumerType: (event.target.value as any) as ConsumerType })}
								/>
							</Grid>
						} else {
							return null
						}
					})}
					<Grid item xs={12}>
						{this.state.series.length > 0 ?
							<ReactApexChart
								options={{
									xaxis: {
										categories: this.state.categories,
										labels: {
											formatter: this.props.diagramType === 'energy-usage'
												? (value) => shortenNumber((value as any) as number, this.state.axisScaleFactor)
												: this.props.diagramType === 'co2-by-month'
													? (value) => value.slice(0, 1)
													: (value) => value,
											style: {
												colors: this.props.diagramType === 'co2-by-month' || this.props.diagramType === 'co2-by-year' ? 'rgb(70, 70, 70)' : '#A19D9D',
												fontFamily: 'inherit',
												fontSize: '14px',
												cssClass: this.props.diagramType === 'co2-by-month' || this.props.diagramType === 'co2-by-year' ? 'dark-label' : 'light-label',
											},
											rotateAlways: this.props.diagramType === 'co2-by-year' || (this.state.diagramWidth < 360 && this.props.diagramType === 'energy-usage'),
										},
										axisBorder: {
											color: '#A19D9D',
										},
										axisTicks: {
											color: '#A19D9D',
										},
										title: {
											text: this.props.diagramType === 'energy-usage' ? formatUnit(this.state.energySource, this.state.axisScaleFactor) : undefined,
											style: {
												color: '#A19D9D',
												fontWeight: 400,
											},
											offsetY: this.state.diagramWidth < 360 && this.props.diagramType === 'energy-usage' ? 5 : -5,
										},
									},
									yaxis: {
										floating: this.props.diagramType === 'energy-usage',
										labels: {
											formatter: this.props.diagramType === 'energy-usage'
												? (value) => wrapLabel(
													(value as any) as string,
													'14px',
													'inherit'
												)
												: (value) => this.state.maxSeriesValue < 1000
													? shortenNumber(value, 1) + 'kg'
													: shortenNumber(value, 1000) + 't',
											style: {
												colors: this.props.diagramType === 'energy-usage' ? 'rgb(70, 70, 70)' : '#A19D9D',
												fontFamily: 'inherit',
												fontSize: '14px',
												cssClass: this.props.diagramType === 'energy-usage' ? 'dark-label' : 'light-label',
											},
											offsetY: this.props.diagramType === 'co2-by-month' || this.props.diagramType === 'co2-by-year' ? 14 : 0,
											offsetX: this.props.diagramType === 'energy-usage' ? -137 : 0,
											align: this.props.diagramType === 'energy-usage' ? 'left' : 'right',
											minWidth: this.props.diagramType === 'energy-usage' ? 155 : 0,
											maxWidth: this.props.diagramType === 'energy-usage' ? 155 : 160,
										},
										axisBorder: {
											color: '#A19D9D',
										},
										axisTicks: {
											show: this.props.diagramType === 'co2-by-month' || this.props.diagramType === 'co2-by-year',
											width: 40,
											offsetY: -13.5,
											color: '#A19D9D',
										},
									},
									grid: {
										borderColor: '#A19D9D',
										xaxis: {
											lines: {
												show: this.props.diagramType === 'energy-usage',
											},
										},
										yaxis: {
											lines: {
												show: this.props.diagramType === 'co2-by-month' || this.props.diagramType === 'co2-by-year',
											},
										},
									},
									plotOptions: {
										bar: {
											horizontal: this.props.diagramType === 'energy-usage',
											barHeight: this.props.diagramType === 'energy-usage' ? '12%' : '57%',
										},
										pie: {
											offsetY: 30,
											dataLabels: {
												offset: this.state.diagramWidth / 17,
											},
											donut: {
												size: '70%',
												labels: {
													show: true,
													name: {
														show: true,
														fontFamily: 'inherit',
													},
													value: {
														show: true,
														fontFamily: 'inherit',
													},
													total: {
														show: true,
														showAlways: true,
														fontFamily: 'inherit',
														label: 'Gesamt CO2-Emissionen',
														fontSize: '20px',
														formatter: (w) => formatWeight(w.globals.seriesTotals.reduce((accumulator, t) => accumulator + t, 0), 2, this.state.maxSeriesValue < 1000 ? 'kg' : 't'),
													},
												},
											},
										},
									},
									dataLabels: {
										enabled: this.props.diagramType === 'co2',
										style: {
											fontFamily: 'inherit',
											colors: ['#A6CBFF'],
										},
										background: {
											enabled: true,
											foreColor: '#000000',
											borderRadius: 5,
											borderColor: '#A6CBFF',
											padding: 5,
											opacity: 1,
											dropShadow: {
												enabled: false,
											},
										},
										dropShadow: {
											enabled: false,
										},
									},
									chart: {
										stacked: this.props.diagramType === 'co2-by-month' || this.props.diagramType === 'co2-by-year',
										toolbar: {
											show: false,
										},
										fontFamily: 'inherit',
										foreColor: 'rgb(70, 70, 70)',
										offsetX: this.props.diagramType === 'energy-usage' ? -30 : 0,
									},
									legend: {
										show: this.props.diagramType !== 'co2',
										showForSingleSeries: this.props.diagramType === 'co2-by-month' || this.props.diagramType === 'co2-by-year',
										fontFamily: 'inherit',
										horizontalAlign: 'left',
									},
									tooltip: {
										x: {
											formatter: (value) => '' + value,
										},
										y: {
											formatter: (value) =>
												this.props.diagramType === 'energy-usage'
													? formatNumber(value, 2, 0.01) + ((this.state.energySource || {}).unit || '')
													: formatWeight(value, 2, this.state.maxSeriesValue < 1000 ? 'kg' : 't'),
										},
										shared: false,
										intersect: this.props.diagramType !== 'energy-usage',
									},
									labels: this.state.labels,
									colors: this.props.diagramType === 'co2-by-month' || this.props.diagramType === 'co2-by-year'
										? ['#5EA2FF', '#58C497', '#F8CA45', '#BDB6F9', '#FF897A', '#A7A7A7', '#EE6E92', '#28548B', '#FCE374', '#565656', '#59A4D4', '#F65031']
										: ['#0094FF'],
								}}
								series={this.state.series}
								type={this.props.diagramType === 'co2' ? 'donut' : 'bar'}
								height={
									this.props.diagramType === 'energy-usage'
										? 94 + this.state.categories.length * 50
										: this.props.diagramType === 'co2'
											? 'auto'
											: 650
								}
								width={this.state.diagramWidth}
								className={this.props.diagramType === 'energy-usage' ? 'webkit-offset-fix' : ''}
							/>
						:
							<div className="dashboard-diagram-no-series">
								Keine Verbraucher gefunden.
							</div>
						}
					</Grid>
					{this.props.diagramType === 'co2' && (this.state.series || []).length > 0 ?
						<Grid item xs={12} sx={{ py: 2 }}>
							<Collapse
								in={this.state.expandExtraSeries}
								collapsedSize={Math.min((this.state.series || []).length * 50, 200)}
								style={{ height: this.state.expandExtraSeries ? 'auto' : Math.min((this.state.series || []).length * 50, 200) }}
							>
								<div className="extra-series-list">
									{(() => {
										const series = this.state.series || []
										const sumSeriesValue = series.reduce((accumulator, value) => accumulator + value, 0);
										
										return (this.state.labels || []).map((label, index) => {
											const ratio = sumSeriesValue > 0 ? series[index] * 100 / sumSeriesValue : 0;
											
											return [
												<div key={'extra-series-list-bar-' + index}>
													<div className="process-label">{label}</div>
													<div className="process-ratio" style={{ width: ratio + '%' }}></div>
												</div>
											,
												<div key={'extra-series-list-values-' + index}>
													<div className="process-value-label">{formatWeight(series[index], 2, this.state.maxSeriesValue < 1000 ? 'kg' : 't')}</div>
													<div className="process-ratio-label">{formatNumber(ratio, 2, 0.01)}%</div>
												</div>
											]
										})
									})()}
								</div>
							</Collapse>
							{(this.state.series || []).length > 4 ?
								<div className="extra-series-list-actions">
									<Button
										endIcon={this.state.expandExtraSeries ? <FiChevronUp /> : <FiChevronDown />}
										onClick={() => this.setState({ expandExtraSeries: !this.state.expandExtraSeries })}
										color="inherit"
									>
										{this.state.expandExtraSeries ? 'Weniger' : 'Mehr'}
									</Button>
								</div>
							:
								null
							}
						</Grid>
					:
						null
					}
					{this.props.allowSort ?
						<Grid item className="dashboard-diagram-sort">
							<div className="dashboard-diagram-sort-label">
								Sortieren nach:
							</div>
							<FilterSelector
								label="Sortieren nach"
								items={SORT_ORDER_OPTIONS}
								value={this.state.sortOrder}
								onChange={(event) => this.setState({ sortOrder: (event.target.value as any) as SortOrder })}
							/>
						</Grid>
					:
						null
					}
				</Grid>
			</Grid>
        )
    }
}

export default Diagram;

