import { deleteProcessCascade } from "../DataBase/Prozess/ProzessAPI";
import {Button} from 'react-bootstrap';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Prozess } from "../API";

interface DeleteProzessProps {
    consumer: Prozess;
    open: boolean;
    onDelete: () => void;
    onClose: () => void;
}

const DeleteProzessDialog = (props: DeleteProzessProps) => {
    const deleteProcess = async () => {
        await deleteProcessCascade(props.consumer.id);
        props.onDelete();
    }

    const closeDialog = () => {
        props.onClose();
    }

    return (
        <Dialog
            fullScreen={false}
            open={props.open}
            onClose={closeDialog}
            aria-labelledby="responsive-dialog-title"
        >
            <DialogTitle id="responsive-dialog-title">
                {"Prozess löschen"}
            </DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Bist du sicher, dass du den Verbraucher <b>{props.consumer.prozessname}</b> löschen möchtest?
                    Dies kann nicht rückgängig gemacht werden.
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button className="red-button" onClick={deleteProcess}>
                    Löschen
                </Button>
                <Button className="link-button" onClick={closeDialog}>
                    Abbrechen
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default DeleteProzessDialog;