import React, {Component} from "react";
import {Chart} from "react-google-charts";

import {getBaselineEnergieKennzahlenById} from "../DataBase/BaselineEnergieKennzahlen/BaselineEnergieKennzahlenAPI";
import {getEinflussFaktorbyID} from "../DataBase/EinflussFaktor/EinflussfaktorAPI";
import {getEnergieMessDatenbyId, getFaktorMessDatenbyId} from "../DataBase/MessDaten/MessDatenAPI";


class EnergikennZahlenBaselineCanvas extends Component {

    constructor(props) {
        super(props);
        this.history = props.history;
        this.einheit = props.einheit;
        this.state = {
            baseline: {
                id: props.baselineID,
                prozessID: "",
                companyID: "",
                name: "",
                jahr: "",

                einflussfaktorID: "",
                baselineEinflussFaktorMessDatenID: "",
                baselineEnergieverbrauchsDatenID: "",
                energieKennzahlenDaten: []
            },
            einflussfaktor: {
                id: "",
                companyID: "",
                messDaten: [],
                einflussFaktorNummer: "",
                einflussFaktorName: "",
                einheit: "",
                verantwortlicher: ""
            },
            canvasdaten: undefined
        };
        this.fetchEnergieKennZahlenBaseline(this.state.baseline.id);

    }

    async fetchEnergieKennZahlenBaseline(baselineEnergieKennzahlenID) {

        let baseline = await getBaselineEnergieKennzahlenById(baselineEnergieKennzahlenID);
        this.setState({baseline: baseline});
        await this.fetchEinflussfaktor(baseline.einflussfaktorID);
        await this.fetchCanvasData(baseline.baselineEinflussFaktorMessDatenID, baseline.baselineEnergieverbrauchsDatenID);

    }

    async fetchEinflussfaktor(einflussFaktorID) {

        let einflussFaktorData = await getEinflussFaktorbyID(einflussFaktorID);

        this.setState({einflussfaktor: einflussFaktorData});

    }

    async fetchCanvasData(einflussfaktorMessdatenID, energieMessdatenID) {
        if (einflussfaktorMessdatenID && energieMessdatenID) {

            let energieDaten = (await getEnergieMessDatenbyId(energieMessdatenID)).Messpunkte;
            let faktorDaten = (await getFaktorMessDatenbyId(einflussfaktorMessdatenID)).Messpunkte;


            let canvasData = [[this.state.einflussfaktor.einheit, 'Verbrauchte Energie (in kWh)']]; //FIXME BY Data
            for (let i = 0; i < faktorDaten.length; i++) {
                let point = [Number.parseFloat(faktorDaten[i]), Number.parseFloat(energieDaten[i])]
                canvasData.push(point);
            }
            this.setState({canvasdaten: canvasData});
        }

    }

    render() {
        return (

            <div>
                {this.state.canvasdaten && this.einheit &&
                <Chart
                    width={'100%'}
                    height={'450px'}
                    chartType="ScatterChart"
                    loader={<div>Loading Chart</div>}
                    data={
                        this.state.canvasdaten
                    }
                    options={{
                        title: 'Lineare Regression',
                        hAxis: {title: this.state.einflussfaktor.einflussFaktorName + " in (" + this.state.einflussfaktor.einheit + ")"},
                        vAxis: {title: 'Verbrauchte Energie (in '+ this.einheit +')'},
                        legend: 'none',
                        trendlines: {0: {}},
                    }}
                    rootProps={{'data-testid': '1'}}
                />
                }
            </div>

        );

    }
}

export default EnergikennZahlenBaselineCanvas;

