import { useState } from "react";
import EnergieMessDatenListItem from "./EnergieMessDatenListItem";
import { RouteComponentProps, useHistory, withRouter } from "react-router-dom";

import { newEnergieMessDaten } from "../../DataBase/MessDaten/MessDatenAPI";
import { updateProcess } from "../../DataBase/Prozess/ProzessAPI";
import { isVirtualConsumer } from "../../Model/VirtualConsumer";
import { useConstructor } from "../../Util/ReactUtils";
import { getEnergySourceById } from "../../DataBase/EnergySourceAPI";
import { EnergySource, Prozess } from "../../API";
import EnpiButton from "../../ui/EnpiButton";

interface EnergieMessDatenOverviewProps extends RouteComponentProps {
    data;
    process: Prozess;
}

const EnergieMessDatenOverview = (props: EnergieMessDatenOverviewProps) => {
    const [energySource, setEnergySource] = useState({} as EnergySource);
    const history = useHistory();
    
    useConstructor(async () => {
        setEnergySource(await getEnergySourceById(props.process.energySourceID));
    });

    const neuesMessJahr = async() => {
        let messdaten = await newEnergieMessDaten(props.process.id);
        props.process.aktuelleMessungID = messdaten.id;
        await updateProcess(props.process);
        history.push('/MessDaten/EnergieDaten/' + props.process.aktuelleMessungID + '/true');
    }

    return (
        <div>
            <div>
                <div>
                    <div>
                        <header>
                            <h4>Übersicht Messdaten: {energySource.name}</h4>
                        </header>
                        <br/>

                        <div className="table-responsive">
                            <table
                                className="table table-vcenter">
                                <thead>
                                <tr className="fixed-cell">
                                    <th className="no-border">Jahr</th>
                                    <th className="text-right no-border">Verbrauch Gesamt</th>
                                    <th className="text-right no-border">Kosten Gesamt</th>
                                    <th className="text-right no-border">CO<sub>2</sub> Gesamt</th>
                                    <th className="no-border">Status Messdaten</th>
                                    <th className="no-border"></th>
                                </tr>
                                </thead>
                                <tbody>
                                {props.data.map((energieDaten, index) =>
                                    <EnergieMessDatenListItem key={index} state={energieDaten} energietraeger={energySource.co2factor} editable={!isVirtualConsumer(props.process)}/>
                                )}
                                </tbody>
                                
                            </table>
                        </div>
                        <br/>
                        {!isVirtualConsumer(props.process) &&
                            <EnpiButton onClick={neuesMessJahr}>Messdaten hinzufügen</EnpiButton>
                        }
                    </div>
                </div>
            </div>
        </div>

    );
}

export default withRouter(EnergieMessDatenOverview);
