import React, {Component} from "react";
import ReactApexChart from 'react-apexcharts';

class MessDatenAnalyseCanvasMonatszeitraum extends Component {

    constructor(props) {
        super(props);
        this.einheit = props.einheit;
        this.history = props.history;
        this.jahreLabels = [];
        this.data = props.data;

        // variables for several months
        this.startMonth = props.startMonth || 0;
        // if no end month is given, set end month to start month+2 or to the last month (december)
        this.endMonth = props.endMonth ? props.endMonth : (props.startMonth + 2 > this.monateLabels.length - 1 ? this.monateLabels.length - 1 : props.startMonth + 2);

        // if period of time <= 6 month -> detailed month name, else abbreviation
        this.monateLabels = this.endMonth <= 5 ? ['Januar', 'Februar', 'März', 'April', 'Mai', 'Juni', 'Juli', 'August', 'September', 'Oktober', 'November', 'Dezember'] : ['Jan', 'Feb', 'Mär', 'Apr', 'Mai', 'Jun', 'Jul', 'Aug', 'Sep', 'Okt', 'Nov', 'Dez'];

        this.monatsDaten = [];
        // Monate
        // get data for all months for all years
        for (let i = 0; i <= 11; i++) {
            let monat = [];
            for (let k = 0; k < this.data.length; k++) {
                this.jahreLabels.push(this.data[k].val.bezugsJahr || "Kein Jahr Angegeben");
                if (this.data && this.data[k] && this.data[k].val && this.data[k].val.Messpunkte && this.data[k].val.Messpunkte[i]) {
                    monat.push(this.data[k].val.Messpunkte[i].toFixed(0));
                } else {
                    monat.push(null);
                }
            }
            this.monatsDaten.push(monat);
        }

        if (this.startMonth <= this.endMonth && this.endMonth >= this.startMonth) {
            // get month labels for the current period
            this.currentMonthsLabels = this.prepareCurrentMonthsLabels(this.startMonth, this.endMonth);

            // get chart data
            this.series = this.prepareMonatszeitraumSeries(this.startMonth, this.endMonth);
        } else {
            this.currentMonthsLabels = null;
            this.series = null;
        }


        this.state = {
            // variables for several months
            startMonth: props.startMonth || 0,
            // if no end month is given, set end month to start month+1 or to the last month (december)
            endMonth: props.endMonth ? props.endMonth : (props.startMonth + 1 > this.monateLabels.length - 1 ? this.monateLabels.length - 1 : props.startMonth + 1),

            currentMonthsLabels: this.currentMonthsLabels,

            series: this.series,
            options: {
                chart: {
                    height: 350,
                    stacked: false
                },
                dataLabels: {
                    enabled: false
                },
                stroke: {
                    width: 2
                },
                xaxis: {
                    categories: this.currentMonthsLabels,
                },
                yaxis: [
                    {
                        axisTicks: {
                            show: true,
                        },
                        axisBorder: {
                            show: true,
                            color: '#008FFB'
                        },
                        labels: {
                            style: {
                                colors: '#008FFB',
                            }
                        },
                        title: {
                            text: this.relaceYLable ? this.einheit : " Energieverbrauch (" + this.einheit + ")",
                            style: {
                                color: '#008FFB',
                            }
                        },
                        tooltip: {
                            enabled: true
                        }
                    }
                ],
                tooltip: {
                    fixed: {
                        enabled: true,
                        position: 'bottomLeft', // topRight, topLeft, bottomRight, bottomLeft
                        offsetY: 30,
                        offsetX: 60
                    },
                },
                legend: {
                    horizontalAlign: 'left',
                    offsetX: 40
                }
            }
        }
    }

    prepareMonatszeitraumSeries(start, end) {
        let series = [];
        if (this.data && this.data.length) {
            for (let k = 0; k < this.data.length; k++) {
                let data = [];
                this.jahreLabels.push(this.data[k].val.bezugsJahr || "Kein Jahr Angegeben");
                if (this.data && this.data[k] && this.data[k].val && this.data[k].val.Messpunkte) {
                    for (let i = 0; i <= 11; i++) {

                        // select only data of the wished months
                        if (i >= start && i <= end) {
                            if (this.data[k].val.Messpunkte[i]) {
                                data.push(Number.parseFloat(this.data[k].val.Messpunkte[i].toFixed(0)));
                            } else {
                                data.push(null);
                            }
                        }

                    }
                }

                series.push({
                    name: this.data[k].val.bezugsJahr || "Kein Jahr Angegeben",
                    data: data
                })
            }
        }
        return series;
    }

    prepareCurrentMonthsLabels(start, end) {
        let currentMonthsLabels = []
        for (const [index, value] of this.monateLabels.entries()) {
            if (index >= start && index <= end) {
                currentMonthsLabels.push(value)
            }
        }
        return currentMonthsLabels
    }

    componentWillReceiveProps(prevProps) {
        if (prevProps.startMonth !== this.state.startMonth || prevProps.endMonth !== this.state.endMonth ) {
            this.data = prevProps.data;
            console.log("redraw");
            this.setState({
                startMonth: prevProps.startMonth,
                endMonth: prevProps.endMonth,
                series: this.prepareMonatszeitraumSeries(prevProps.startMonth, prevProps.endMonth),
                options: {
                    chart: {
                        height: 350,
                        stacked: false
                    },
                    dataLabels: {
                        enabled: false
                    },
                    stroke: {
                        width: 2
                    },
                    xaxis: {
                        categories: this.prepareCurrentMonthsLabels(prevProps.startMonth, prevProps.endMonth),
                    },
                    yaxis: [
                        {
                            axisTicks: {
                                show: true,
                            },
                            axisBorder: {
                                show: true,
                                color: '#008FFB'
                            },
                            labels: {
                                style: {
                                    colors: '#008FFB',
                                }
                            },
                            title: {
                                text: this.relaceYLable ? this.einheit : "Energieverbrauch (" + this.einheit + ")",
                                style: {
                                    color: '#008FFB',
                                }
                            },
                            tooltip: {
                                enabled: true
                            }
                        }
                    ],
                    tooltip: {
                        fixed: {
                            enabled: true,
                            position: 'bottomLeft', // topRight, topLeft, bottomRight, bottomLeft
                            offsetY: 30,
                            offsetX: 60
                        },
                    },
                    legend: {
                        horizontalAlign: 'left',
                        offsetX: 40
                    }
                }
            });
        }
    }


    render() {
        return (
            <div>
                <ReactApexChart options={this.state.options} series={this.state.series} type="bar" height={350}/>
            </div>
        );
    }
}

export default MessDatenAnalyseCanvasMonatszeitraum;

