import React, {Component} from "react";
import ReactApexChart from "react-apexcharts";


class DurchschnittsEntwicklung extends Component {

    constructor(props) {
        super(props);
        this.history = props.history;
        let data = props.data;
        this.einheit = props.einheit;

        let averagevalue = [];
        let normalisiert = [];
        for (let i = 1; i <= 12; i++) {
            if (data && data[i]) {

                if (data[i][1]) {
                    averagevalue.push(data[i][1]);
                    //console.log("averagevalue", averagevalue);
                }
                if (data[i][0]) {
                    normalisiert.push(data[i][0]);
                }
            }
        }

        this.state = {

            series: [{
                name: data[0][1],
                data: averagevalue
            }
            ],
            options: {
                chart: {
                    height: 350,
                    stacked: false,
                    locales: [{
                        "name": "de"
                    }],
                    defaultLocale: "de"
                },
                dataLabels: {
                    enabled: true
                },
                stroke: {
                    width: [3, 3, 3]
                },
                xaxis: {
                    categories: normalisiert,
                    title: {
                        text: 'Jahr'
                    }
                },
                yaxis: [
                    {
                        axisTicks: {
                            show: true,
                        },
                        axisBorder: {
                            show: true,
                            color: '#008FFB'
                        },
                        labels: {
                            style: {
                                colors: '#008FFB',
                                fontSize:  '14px'
                            }
                        },
                        title: {
                            text: data[0][1],
                            style: {
                                colors: '#008FFB',
                                fontSize:  '14px'
                            }
                        },
                        tooltip: {
                            enabled: true
                        }
                    }
                ],
                tooltip: {
                    fixed: {
                        enabled: true,
                        position: 'bottomLeft',
                        offsetY: -200,
                        offsetX: 100
                    },
                },
                legend: {
                    horizontalAlign: 'left',
                    offsetX: 40
                }
            }
        }
    }

    deNormalise(entry) {
        if (entry === null) {
            return "";
        }
        return ("".concat(entry)).replaceAll('.', ',');
    }


    render() {
        return (
            <div>
                <ReactApexChart options={this.state.options} series={this.state.series} type="line" height={350}/>
            </div>
        );
    }
}

export default DurchschnittsEntwicklung;

