import React, {Component} from "react";
import {Link, withRouter} from "react-router-dom";
import {faktorMessDatenForEinflussfaktorID} from "../DataBase/MessDaten/MessDatenAPI";
import Grid from "@material-ui/core/Grid";


import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import {FiEdit2} from "react-icons/fi";
import {formater} from "../Util/helper";
import FaktorMessDatenOverview from "./FaktorMessDaten/FaktorMessDatenOverview";
import {getEinflussFaktorbyID} from "../DataBase/EinflussFaktor/EinflussfaktorAPI";
import MessDatenAnalyseCanvasMonat from "../Prozess/MessDatenAnalyseCanvasMonat";
import MessDatenAnalyseCanvasJahre from "../Prozess/MessDatenAnalyseCanvasJahre";


class DetailsFactor extends Component {

    constructor(props) {
        super(props);
        this.history = props.history;
        this.einflussFaktorID = props.match.params.factor;
        this.state = {
            regexNumbersOnly: /^[+-]?\d*\.?,?\d*$/,
            showJahre: true,
            openzeitraum: false,
            openmode: false,
            openmonate: false,
            vonJahre: null,
            bisJahre: null,
            moeglicheJahre: [],
            openvonJahre: false,
            openbisJahre: false,
            changeData: false,
            mode: "all",
            selectedmonat: 0,
            faktorDatenViewlist: [],
            loading: true,
            id: this.einflussFaktorID,
            einflussFaktorNummer: "",
            einflussFaktorName: "",
            einheit: "",
            verantwortlicher: ""

        };
        this.fetchData();
    }

    async fetchData() {
        let einflussfaktor = await getEinflussFaktorbyID(this.einflussFaktorID);
        this.setState(einflussfaktor);

        let faktorMessDatenlist = await faktorMessDatenForEinflussfaktorID(this.state.id);

        let faktorMessDatenViewlist = [];
        let moeglicheJahre = [];
        let gesamtVerbrauch = 0;
        if (faktorMessDatenlist) {
            this.setState({vonJahre: faktorMessDatenlist[0].bezugsJahr});
            this.setState({bisJahre: faktorMessDatenlist[faktorMessDatenlist.length - 1].bezugsJahr});

            for (let i = 0; i < faktorMessDatenlist.length; i++) {
                moeglicheJahre.push(faktorMessDatenlist[i].bezugsJahr)
                faktorMessDatenlist[i].einheit = einflussfaktor.einheit;
                let item = {key: i, val: faktorMessDatenlist[i]};
                if (faktorMessDatenlist[i].summeMessdaten) {
                    gesamtVerbrauch = gesamtVerbrauch + faktorMessDatenlist[i].summeMessdaten
                }
                faktorMessDatenViewlist.push(item);
            }
            this.setState({data: faktorMessDatenViewlist, loading: false});
            this.setState({gesamtVerbrauch: gesamtVerbrauch});
            this.setState({alldata: faktorMessDatenlist});
            this.setState({moeglicheJahre: moeglicheJahre});
        }
    }

    changeData(von, bis) {
        let faktorMessDatenViewlist = [];
        let gesamtVerbrauch = 0;
        let alldata = this.state.alldata;
        if (alldata) {
            for (let i = 0; i < alldata.length; i++) {
                if (this.isInIntervall(alldata[i].bezugsJahr, von, bis)) {
                    let item = {key: i, val: alldata[i]};
                    if (alldata[i].summeMessdaten) {
                        gesamtVerbrauch = gesamtVerbrauch + alldata[i].summeMessdaten
                    }
                    faktorMessDatenViewlist.push(item);
                }
            }
            this.setState({data: faktorMessDatenViewlist, loading: false});
            this.setState({gesamtVerbrauch: gesamtVerbrauch});

            this.forceUpdate();

        }
        console.log("State", this.state)
    }

    isInIntervall(bezugsJahr, von, bis) {
        if (bezugsJahr) {
            let current = Number.parseInt(bezugsJahr);
            let from = von && Number.parseInt(von);
            let to = bis && Number.parseInt(bis);
            return from <= current && current <= to;
        }
        return false

    }

    handleZeitraumClose = () => {
        this.setState({openzeitraum: false});
    };

    handleZeitraumOpen = () => {
        this.setState({openzeitraum: true});
    };

    handleOpenmonateClose = () => {
        this.setState({openmonate: false});
    };

    handleOpenmonateOpen = () => {
        this.setState({openmonate: true});
    };

    handleModeClose = () => {
        this.setState({openmode: false});
    };

    handleModeOpen = () => {
        this.setState({openmode: true});
    };

    handlevonJahreClose = () => {
        this.setState({openvonJahre: false});
    };
    handlevonJahreOpen = () => {
        this.setState({openvonJahre: true});
    };

    handlebisJahreClose = () => {
        this.setState({openbisJahre: false});
    };
    handlebisJahreOpen = () => {
        this.setState({openbisJahre: true});
    };

    changevonJahr(e) {
        this.setState(e);
        this.changeData(e.vonJahre, this.state.bisJahre);
    }

    changebisJahr(e) {
        this.setState(e);
        this.changeData(this.state.vonJahre, e.bisJahre);
    }

    changeMonat(e) {
        this.setState(e)

    }


    changeAuswertung(e) {
        let alldata = this.state.alldata;
        if (alldata && alldata.length >= 1) {
            let von = alldata && alldata[0];
            let bis = alldata && alldata[0];
            if (e.mode === "all") {
                bis = alldata && alldata.length >= 1 && alldata[alldata.length - 1];
            } else if (e.mode === "zeitraum") {
                bis = alldata && alldata.length >= 1 && alldata[alldata.length - 1];
            }
            this.setState({vonJahre: von.bezugsJahr});
            this.setState({bisJahre: bis.bezugsJahr});
            this.changeData(von.bezugsJahr, bis.bezugsJahr);
            this.setState(e);
        }
    }


    render() {

        return (
            <div className="dashboard-root">
                <div className="base-content">
                    <header>
                        <h1>Details Einflussfaktor {this.state.einflussFaktorName}
                            <Link to={`/Factor/Factor/${this.state.id}`}><FiEdit2
                                className="float-right" title="Einflussfaktor anzeigen"
                            /></Link>
                        </h1>
                    </header>

                    <br/>
                    <div className="table-responsive">
                        <table
                            className="table table-vcenter">

                            <tbody>
                            <tr>
                                <td className="">
                                    Einheit
                                </td>
                                <td className="">
                                    {this.state.einheit}
                                </td>
                            </tr>

                            </tbody>
                        </table>
                    </div>
                    <br/>
                    <br/>

                    <header>
                        <h3>Auswertung Messdaten
                        </h3>
                    </header>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <div className="">
                                <div className="">
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} sm={12}>
                                            <div className="mb-3 auswertung float-right">
                                                <InputLabel id="auswertung-select-label">Auswertung</InputLabel>
                                                <Select
                                                    labelId="auswertung-select-label"
                                                    id="auswertung-open-select"
                                                    className="select-minsize"
                                                    open={this.state.openmode}
                                                    onClose={this.handleModeClose}
                                                    onOpen={this.handleModeOpen}
                                                    value={this.state.mode}
                                                    onChange={e => this.changeAuswertung({mode: e.target.value})}
                                                >
                                                    <MenuItem value={"all"}>Alle</MenuItem>
                                                    <MenuItem value={"zeitraum"}>Zeitraum</MenuItem>
                                                </Select>

                                            </div>

                                            <div className="mb-3 anzeigeZeitraum float-right">
                                                <InputLabel id="anzeigeZeitraum-select-label">Zeitraum</InputLabel>
                                                <Select
                                                    labelId="anzeigeZeitraum-select-label"
                                                    id="anzeigeZeitraum-open-select"
                                                    className="select-minsize"
                                                    open={this.state.openzeitraum}
                                                    onClose={this.handleZeitraumClose}
                                                    onOpen={this.handleZeitraumOpen}
                                                    value={this.state.showJahre}
                                                    onChange={e => this.setState({showJahre: e.target.value})}
                                                >

                                                    <MenuItem value={true}>Jahre</MenuItem>
                                                    <MenuItem value={false}>Monate</MenuItem>
                                                </Select>

                                            </div>

                                            {!this.state.showJahre &&
                                            <div className="mb-3 monatauswahl float-right">
                                                <InputLabel id="monatauswahl-select-label">Monat</InputLabel>
                                                <Select
                                                    labelId="monatauswahl-select-label"
                                                    id="monatauswahl-open-select"
                                                    className="select-minsize"
                                                    open={this.state.openmonate}
                                                    onClose={this.handleOpenmonateClose}
                                                    onOpen={this.handleOpenmonateOpen}
                                                    value={this.state.selectedmonat}
                                                    onChange={e => this.changeMonat({selectedmonat: e.target.value})}
                                                >

                                                    <MenuItem value={0}>Jan</MenuItem>
                                                    <MenuItem value={1}>Feb</MenuItem>
                                                    <MenuItem value={2}>März</MenuItem>
                                                    <MenuItem value={3}>April</MenuItem>
                                                    <MenuItem value={4}>Mai</MenuItem>
                                                    <MenuItem value={5}>Juni</MenuItem>
                                                    <MenuItem value={6}>Juli</MenuItem>
                                                    <MenuItem value={7}>Aug</MenuItem>
                                                    <MenuItem value={8}>Sep</MenuItem>
                                                    <MenuItem value={9}>Okt</MenuItem>
                                                    <MenuItem value={10}>Nov</MenuItem>
                                                    <MenuItem value={11}>Dez</MenuItem>

                                                </Select>
                                            </div>
                                            }

                                            {this.state.showJahre && this.state.mode && this.state.mode === "zeitraum" &&
                                            <div className="mb-3 bisJahrauswahl float-right">
                                                <InputLabel id="bisJahrauswahl-select-label">bis</InputLabel>
                                                <Select
                                                    labelId="bisJahrauswahl-select-label"
                                                    id="bisJahrauswahl-open-select"
                                                    className="select-minsize"
                                                    open={this.state.openbisJahre}
                                                    onClose={this.handlebisJahreClose}
                                                    onOpen={this.handlebisJahreOpen}
                                                    value={this.state.bisJahre}
                                                    onChange={e => this.changebisJahr({bisJahre: e.target.value})}
                                                >

                                                    {this.state.moeglicheJahre && this.state.moeglicheJahre.length > 0 ? this.state.moeglicheJahre.map((item, index) =>
                                                            <MenuItem key={index}
                                                                      value={item}>{item}</MenuItem>)
                                                        :
                                                        <div>Kein mögliches Jahr gefunden.</div>
                                                    }

                                                </Select>
                                            </div>

                                            }
                                            {this.state.showJahre && this.state.mode && this.state.mode === "zeitraum" &&
                                            <div className="mb-3 vonJahrauswahl float-right">
                                                <InputLabel id="vonJahrauswahl-select-label">von</InputLabel>
                                                <Select
                                                    labelId="vonJahrauswahl-select-label"
                                                    id="vonJahrauswahl-open-select"
                                                    className="select-minsize"
                                                    open={this.state.openvonJahre}
                                                    onClose={this.handlevonJahreClose}
                                                    onOpen={this.handlevonJahreOpen}
                                                    value={this.state.vonJahre}
                                                    onChange={e => this.changevonJahr({vonJahre: e.target.value})}
                                                >
                                                    {this.state.moeglicheJahre && this.state.moeglicheJahre.length > 0 ? this.state.moeglicheJahre.map((item, index) =>
                                                            <MenuItem key={index}
                                                                      value={item}>{item}</MenuItem>)
                                                        :
                                                        <div>Kein mögliches Jahr gefunden.</div>
                                                    }

                                                </Select>
                                            </div>
                                            }
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} sm={4}>
                                            <div className="grid-contents">
                                                <div className="base-content-grid">

                                                    <header className="border-bottom">
                                                        <h6 className="medium-font">Gesamtwert
                                                        </h6>
                                                    </header>

                                                    <div className="font-large">
                                                        {this.state.gesamtVerbrauch ?
                                                            formater(this.state.gesamtVerbrauch) + " " + this.state.einheit
                                                            :
                                                            "Daten hinzufügen"
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </Grid>
                                        <Grid item xs={12} sm={4}>
                                            <div className="grid-contents">

                                            </div>
                                        </Grid>
                                        <Grid item xs={12} sm={4}>
                                            <div className="grid-contents">

                                            </div>
                                        </Grid>

                                    </Grid>

                                    <div>
                                        <header>
                                            <h3>Entwicklung in {this.state.einheit}
                                            </h3>
                                        </header>
                                        <br/>
                                        <div>
                                            {!this.state.showJahre && this.state.data &&
                                            <MessDatenAnalyseCanvasMonat data={this.state.data}
                                                                         monat={this.state.selectedmonat}/>
                                            }

                                            {this.state.showJahre && this.state.data &&
                                            <MessDatenAnalyseCanvasJahre data={this.state.data}
                                                                         monat={this.state.changeData}
                                                                         einheit = {this.state.einheit}
                                                                         replace={true}
                                                                         type="line"/>
                                            }

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Grid>
                    </Grid>
                    <br/>
                    <br/>
                    {this.state.data &&
                    <FaktorMessDatenOverview data={this.state.data} faktor={this.state}/>
                    }
                </div>
            </div>
        );
    }
}

export default withRouter(DetailsFactor);
