import {API, graphqlOperation} from "aws-amplify";
import {
    getBaselineEnergieKennzahlen,
    listBaselineEnergieKennzahlens, listEinflussFaktorMessDatens,
    listEnergieKennzahlenJahresDatens, listEnergieMessDatens
} from "../../graphql/queries";
import {
    createBaselineEnergieKennzahlen,
    createEnergieKennzahlenJahresDaten,
    deleteBaselineEnergieKennzahlen,
    deleteEnergieKennzahlenJahresDaten,
    updateBaselineEnergieKennzahlen,
    updateEnergieKennzahlenJahresDaten
} from "../../graphql/mutations";
import {getEnergieMessDatenbyId, getFaktorMessDatenbyId} from "../MessDaten/MessDatenAPI";
import { CreateBaselineEnergieKennzahlenMutation, CreateEnergieKennzahlenJahresDatenMutation, GetBaselineEnergieKennzahlenQuery, ListBaselineEnergieKennzahlensQuery, ListEinflussFaktorMessDatensQuery, ListEnergieKennzahlenJahresDatensQuery, ListEnergieMessDatensQuery, UpdateBaselineEnergieKennzahlenMutation, UpdateEnergieKennzahlenJahresDatenMutation } from "../../API";

export const getBaselineEnergieKennzahlenById = async (baselineEnergieKennzahlenID: string) => {
    try {
        const baselineEnergieKennzahlen = await API.graphql({
            query: getBaselineEnergieKennzahlen, variables: {
                id: baselineEnergieKennzahlenID
            }
        }) as { data: GetBaselineEnergieKennzahlenQuery };
        return baselineEnergieKennzahlen.data.getBaselineEnergieKennzahlen;
    } catch (error) {
        console.log("Fetch getBaselineEnergieKennzahlen Faild ", error);
    }
}

export const updateBaselineEnergieKennzahlenByState = async (state: any) => {
    try {
        const baseline = {
            id: state.id,
            prozessID: state.prozessID,
            companyID: state.companyID,
            name: state.name,
            jahr: state.jahr,
            needProcessing: state.needProcessing,
            target: state.target,

            energieVerbrauchsFunktion: state.energieVerbrauchsFunktion,
            energieCoefizent: state.energieCoefizent,
            energieIntercept: state.energieIntercept,
            rwert: state.rwert,

            einflussfaktorID: state.einflussfaktorID,
            baselineEinflussFaktorMessDatenID: state.baselineEinflussFaktorMessDatenID,
            baselineEnergieverbrauchsDatenID: state.baselineEnergieverbrauchsDatenID
        };

        const updatedBaselineEnergieKennzahlen = await API.graphql(
            graphqlOperation(updateBaselineEnergieKennzahlen, {input: baseline})
        ) as { data: UpdateBaselineEnergieKennzahlenMutation };
        console.log(updatedBaselineEnergieKennzahlen.data.updateBaselineEnergieKennzahlen);
        return updatedBaselineEnergieKennzahlen.data.updateBaselineEnergieKennzahlen;
    } catch (error) {
        console.log("Update BaselineEnergieKennzahlen ", error);
    }
}


export const listBaselineEnergieKennzahlenByEinflussfaktorID = async (einflussfaktorID: string) => {
    const filter = {
        einflussfaktorID: {
            eq: einflussfaktorID
        }
    };
    try {
        const baselinelist = await API.graphql({
            query: listBaselineEnergieKennzahlens, variables: {
                filter: filter,
                limit: 10000
            }
        }) as { data: ListBaselineEnergieKennzahlensQuery };
        return baselinelist?.data?.listBaselineEnergieKennzahlens?.items;
    } catch (error) {
        console.log("Fetch getBaselineEnergieKennzahlen Faild ", error);
    }
}

export const listBaselineEnergieKennzahlenByProcessId = async (prozessID: string) => {
    const filter = {
        prozessID: {
            eq: prozessID
        }
    };
    try {
        const baselinelist = await API.graphql({
            query: listBaselineEnergieKennzahlens, variables: {
                filter: filter,
                limit: 10000
            }
        }) as { data: ListBaselineEnergieKennzahlensQuery };
        return baselinelist?.data?.listBaselineEnergieKennzahlens?.items;
    } catch (error) {
        console.log("Fetch getBaselineEnergieKennzahlen Faild ", error);
    }
}

export const listBaselineEnergieKennzahlenByEnergieMessDatenId = async (messdatenID) => {
    let filter = {
        baselineEnergieverbrauchsDatenID: {
            eq: messdatenID
        }
    };
    try {
        const baselinelist = await API.graphql({
            query: listBaselineEnergieKennzahlens, variables: {
                filter: filter,
                limit: 10000
            }
        }) as { data: ListBaselineEnergieKennzahlensQuery };
        return baselinelist.data.listBaselineEnergieKennzahlens.items;
    } catch (error) {
        console.log("Fetch getBaselineEnergieKennzahlen Faild ", error);
    }
}

export const listBaselineEnergieKennzahlenByFaktorMessDatenId = async (messdatenID: string) => {
    const filter = {
        baselineEinflussFaktorMessDatenID: {
            eq: messdatenID
        }
    };
    try {
        const baselinelist = await API.graphql({
            query: listBaselineEnergieKennzahlens, variables: {
                filter: filter,
                limit: 10000
            }
        }) as { data: ListBaselineEnergieKennzahlensQuery };
        return baselinelist?.data?.listBaselineEnergieKennzahlens?.items;
    } catch (error) {
        console.log("Fetch getBaselineEnergieKennzahlen Faild ", error);
    }
}

export const listBaselineEnergieKennzahlenbyCompanyID = async (companyID: string) => {
    const filter = {
        companyID: {
            eq: companyID
        }
    };
    try {
        const baselinelist = await API.graphql({
            query: listBaselineEnergieKennzahlens, variables: {
                filter: filter,
                limit: 10000
            }
        }) as { data: ListBaselineEnergieKennzahlensQuery };
        return baselinelist?.data?.listBaselineEnergieKennzahlens?.items;
    } catch (error) {
        console.log("Fetch getBaselineEnergieKennzahlen Faild ", error);
    }
}

export const deleteBaselineEnergieKennzahlenbyProcessID = async (prozessID: string) => {
    const baselines = await listBaselineEnergieKennzahlenByProcessId(prozessID);
    for (let i = 0; i < baselines.length; i++) {
        await deleteBaselineEnergieKennzahlenCascade(baselines[i].id);
    }
}

export const deleteBaselineEnergieKennzahlenbyEinflussfaktorID = async (einflussfaktorID: string) => {
    let baselines = await listBaselineEnergieKennzahlenByEinflussfaktorID(einflussfaktorID);
    for (let i = 0; i < baselines.length; i++) {
        await deleteBaselineEnergieKennzahlenCascade(baselines[i].id);
    }
}

export const deleteBaselineEnergieKennzahlenCascade = async (baselineID: string) => {
    console.log("Jahresdaten Baseline", baselineID);
    // Energie MessDaten Löschen,
    let filter = {
        baselineEnergieKennzahlenID: {
            eq: baselineID
        }
    };

    try {
        const energieDatenlist = await API.graphql({
            query: listEnergieKennzahlenJahresDatens, variables: {
                filter: filter,
                limit: 10000
            }
        }) as { data: ListEnergieKennzahlenJahresDatensQuery };
        console.log("Jahresdaten", energieDatenlist);
        if (energieDatenlist && energieDatenlist.data && energieDatenlist.data.listEnergieKennzahlenJahresDatens && energieDatenlist.data.listEnergieKennzahlenJahresDatens.items) {
            for (let i = 0; i < energieDatenlist.data.listEnergieKennzahlenJahresDatens.items.length; i++) {
                let datenInput = {
                    jahr: energieDatenlist.data.listEnergieKennzahlenJahresDatens.items[i].jahr,
                    id: energieDatenlist.data.listEnergieKennzahlenJahresDatens.items[i].id
                }
                await API.graphql(graphqlOperation(deleteEnergieKennzahlenJahresDaten, {input: datenInput}));
            }
        }
    } catch (error) {
        console.log("Delete EnergiekennzahlenJahresMessdaten Failed ", error);
    }

    let datenInput = {
        id: baselineID
    }
    try {
        await API.graphql(graphqlOperation(deleteBaselineEnergieKennzahlen, {input: datenInput}));
    } catch (error) {
        console.log("Fehler bei löschen einee Energiebaseline", error)
    }
}

export const deleteJahresDatenbyIDandJahr = async (id, jahr) => {
    try {
        let datenInput = {
            id: id,
            jahr: jahr
        }
        await API.graphql(graphqlOperation(deleteEnergieKennzahlenJahresDaten, {input: datenInput}));
    } catch (error) {
        console.log("Delete EnergiekennzahlenJahresMessdaten Failed ", error);
    }
}

export const getEnergieKennzahlenJahresDatenByBaselineId = async (baselineEnergieKennzahlenID) => {
    let filter = {
        baselineEnergieKennzahlenID: {
            eq: baselineEnergieKennzahlenID
        }
    };
    try {
        const energieKennzahlenJahresDaten = await API.graphql({
            query: listEnergieKennzahlenJahresDatens, variables: {
                filter: filter,
                limit: 10000
            }
        }) as { data: ListEnergieKennzahlenJahresDatensQuery };

        const jahreDatenList = energieKennzahlenJahresDaten?.data.listEnergieKennzahlenJahresDatens.items;
        return jahreDatenList.sort(compareDatenYear);
    } catch (error) {
        console.log("Fetch getBaselineEnergieKennzahlen Faild ", error);
    }
}

export const getEnergieKennzahlenJahresDatenByEnergieMessDatenId = async (energieverbrauchsDatenID: string) => {
    let filter = {
        energieverbrauchsDatenID: {
            eq: energieverbrauchsDatenID
        }
    };
    try {
        const energieKennzahlenJahresDaten = await API.graphql({
            query: listEnergieKennzahlenJahresDatens, variables: {
                filter: filter,
                
            }
        }) as { data: ListEnergieKennzahlenJahresDatensQuery };

        let jahreDatenList = [];
        if (energieKennzahlenJahresDaten && energieKennzahlenJahresDaten.data && energieKennzahlenJahresDaten.data.listEnergieKennzahlenJahresDatens && energieKennzahlenJahresDaten.data.listEnergieKennzahlenJahresDatens.items) {
            jahreDatenList = energieKennzahlenJahresDaten.data.listEnergieKennzahlenJahresDatens.items;
        }
        jahreDatenList.sort(compareDatenYear);
        return jahreDatenList;
    } catch (error) {
        console.log("Fetch getBaselineEnergieKennzahlen Faild ", error);
    }
}

export const getEnergieKennzahlenJahresDatenByFaktorMessDatenId = async (einflussFaktorMessDatenID) => {
    let filter = {
        einflussFaktorMessDatenID: {
            eq: einflussFaktorMessDatenID
        }
    };
    try {
        const energieKennzahlenJahresDaten = await API.graphql({
            query: listEnergieKennzahlenJahresDatens, variables: {
                filter: filter,
                limit: 10000
            }
        }) as { data: ListEnergieKennzahlenJahresDatensQuery };

        const jahreDatenList = energieKennzahlenJahresDaten.data.listEnergieKennzahlenJahresDatens.items;
        return jahreDatenList.sort(compareDatenYear);
    } catch (error) {
        console.log("Fetch getBaselineEnergieKennzahlen Faild ", error);
    }
}

export const createEnergiekennzahlenJahresDaten = async (energieKennzahlenJahresDaten) => {
    try {
        const jahresDaten = {
            id: energieKennzahlenJahresDaten.id,
            baselineEnergieKennzahlenID: energieKennzahlenJahresDaten.baselineEnergieKennzahlenID,
            jahr: energieKennzahlenJahresDaten.jahr,
            einflussFaktorMessDatenID: energieKennzahlenJahresDaten.einflussFaktorMessDatenID,
            energieverbrauchsDatenID: energieKennzahlenJahresDaten.energieverbrauchsDatenID,
            needProcessing: energieKennzahlenJahresDaten.needProcessing,

            normalisedFaktorMessdaten: energieKennzahlenJahresDaten.normalisedFaktorMessdaten,

            graphmetaDatenMonat: energieKennzahlenJahresDaten.graphmetaDatenMonat,
            durchschnittsEnergieAufwand: energieKennzahlenJahresDaten.durchschnittsEnergieAufwand
        };

        const newEnergieKennzahlenJahresDaten = await API.graphql(
            graphqlOperation(createEnergieKennzahlenJahresDaten, {input: jahresDaten})
        ) as { data: CreateEnergieKennzahlenJahresDatenMutation };

        return newEnergieKennzahlenJahresDaten.data.createEnergieKennzahlenJahresDaten;
    } catch (error) {
        console.log("Fehler beim erstellen der createEnergiekennzahlenJahresDaten", error)
    }
}

export const updateEnergiekennzahlenJahresDatenByData = async (energieKennzahlenJahresDaten) => {
    try {
        const jahresDaten = {
            id: energieKennzahlenJahresDaten.id,
            baselineEnergieKennzahlenID: energieKennzahlenJahresDaten.baselineEnergieKennzahlenID,
            jahr: energieKennzahlenJahresDaten.jahr,
            einflussFaktorMessDatenID: energieKennzahlenJahresDaten.einflussFaktorMessDatenID,
            energieverbrauchsDatenID: energieKennzahlenJahresDaten.energieverbrauchsDatenID,
            needProcessing: energieKennzahlenJahresDaten.needProcessing,

            normalisedFaktorMessdaten: energieKennzahlenJahresDaten.normalisedFaktorMessdaten,

            graphmetaDatenMonat: energieKennzahlenJahresDaten.graphmetaDatenMonat,
            durchschnittsEnergieAufwand: energieKennzahlenJahresDaten.durchschnittsEnergieAufwand
        };
        const newEnergieKennzahlenJahresDaten = await API.graphql(
            graphqlOperation(updateEnergieKennzahlenJahresDaten, {input: jahresDaten})
        ) as { data: UpdateEnergieKennzahlenJahresDatenMutation };
        return newEnergieKennzahlenJahresDaten.data.updateEnergieKennzahlenJahresDaten;
    } catch (error) {
        console.log("Fehler beim erstellen der updatenEnergiekennzahlenJahresDaten", error);
    }
}


export const createEnergiekennzahlenBaseline = async (energieKennzahlenBaselineDaten) => {
    console.log("energieKennzahlenBaselineDaten", energieKennzahlenBaselineDaten);
    try {
        const newEnergieKennzahlenBaseline = await API.graphql(
            graphqlOperation(createBaselineEnergieKennzahlen, {input: energieKennzahlenBaselineDaten})
        ) as { data: CreateBaselineEnergieKennzahlenMutation };
        return newEnergieKennzahlenBaseline.data.createBaselineEnergieKennzahlen;
    } catch (error) {
        console.log("Fehler beim erstellen der createEnergiekennzahlenBaseline", error)
    }
}

function compareDatenYear(itemA: { jahr: any }, itemB: { jahr: any }) {
    if (itemA.jahr && itemB.jahr) {
        return itemA.jahr > itemB.jahr ? 1 : -1;
    } else if (itemA.jahr) {
        return 1;
    } else {
        return -1
    }
}

export const createEnergieKennzahlenforNewYearFaktor = async (parrent, faktorMessDatenID, jahr) => {
    //searchAllRelevantBaselines
    console.log(parrent);
    let baselines = [];
    let filter = {
        einflussfaktorID: {
            eq: parrent
        }
    };
    try {
        const baselineslist = await API.graphql({
            query: listBaselineEnergieKennzahlens, variables: {
                filter: filter,
                limit: 10000
            }
        }) as { data: ListBaselineEnergieKennzahlensQuery };
        if (baselineslist && baselineslist.data && baselineslist.data.listBaselineEnergieKennzahlens && baselineslist.data.listBaselineEnergieKennzahlens.items) {
            for (let i = 0; i < baselineslist.data.listBaselineEnergieKennzahlens.items.length; i++) {
                let item = baselineslist.data.listBaselineEnergieKennzahlens.items[i];
                baselines.push(item);
            }
        }
    } catch (error) {
        console.log("Fetch Faild ", error);
    }
    console.log("baselineList", baselines);
    for (let i = 0; i < baselines.length; i++) {
        await searchAllRelevantEnergieMessDaten(baselines[i].id, baselines[i].prozessID, baselines[i].einflussfaktorID, jahr, faktorMessDatenID);
    }
}

export const createEnergieKennzahlenforNewYearProcess = async (parrent, currentMessDatenID, jahr) => {
    //searchAllRelevantBaselines
    let baselines = [];
    let filter = {
        prozessID: {
            eq: parrent
        }
    };
    try {
        const baselineslist = await API.graphql({
            query: listBaselineEnergieKennzahlens, variables: {
                filter: filter,
                limit: 10000
            }
        }) as { data: ListBaselineEnergieKennzahlensQuery };
        if (baselineslist && baselineslist.data && baselineslist.data.listBaselineEnergieKennzahlens && baselineslist.data.listBaselineEnergieKennzahlens.items) {
            for (let i = 0; i < baselineslist.data.listBaselineEnergieKennzahlens.items.length; i++) {
                let item = baselineslist.data.listBaselineEnergieKennzahlens.items[i];
                baselines.push(item);
            }
        }
        for (let i = 0; i < baselines.length; i++) {
            console.log("Baseline", baselines[i]);
            await searchAllRelevantFaktorMessDaten(baselines[i].id, baselines[i].prozessID, baselines[i].einflussfaktorID, jahr, currentMessDatenID);
        }
    } catch (error) {
        console.log("Fetch Faild ", error);
    }
}

export const searchAllRelevantFaktorMessDaten = async (baselineID, prozessID, einflussfaktorID, jahr, energieMessDatenID) => {
    console.log("searchAllRelevantFaktorMessDaten");
    let faktorMessDatenResults = [];
    let filter = { // FixMe Include Jahr
        einflussfaktorID: {
            eq: einflussfaktorID
        }
    };

    try {
        const energieMessDaten = await API.graphql({
            query: listEinflussFaktorMessDatens, variables: {
                filter: filter,
                limit: 10000
            }
        }) as { data: ListEinflussFaktorMessDatensQuery };

        if (energieMessDaten && energieMessDaten.data && energieMessDaten.data.listEinflussFaktorMessDatens && energieMessDaten.data.listEinflussFaktorMessDatens.items) {
            for (let i = 0; i < energieMessDaten.data.listEinflussFaktorMessDatens.items.length; i++) {
                let item = energieMessDaten.data.listEinflussFaktorMessDatens.items[i];
                faktorMessDatenResults.push(item);
            }
        }
        for (let i = 0; i < faktorMessDatenResults.length; i++) {
            if (faktorMessDatenResults[i].bezugsJahr === jahr) {
                await createEnergiekennzahlenJahresDatenbyBaseline(baselineID, jahr, faktorMessDatenResults[i].id, energieMessDatenID);
            }
        }

    } catch (error) {
        console.log("Fetch Faild ", error);
    }
}

export const searchAllRelevantEnergieMessDaten = async (baselineID, prozessID, einflussfaktorID, jahr, einflussFaktorMessDatenID) => {
    let energieMessDatenResults = [];
    let filter = { // FixMe Include Jahr
        prozessID: {
            eq: prozessID
        }
    };
    try {
        const energieMessDaten = await API.graphql({
            query: listEnergieMessDatens, variables: {
                filter: filter,
                limit: 10000
            }
        }) as { data: ListEnergieMessDatensQuery };
        if (energieMessDaten && energieMessDaten.data && energieMessDaten.data.listEnergieMessDatens && energieMessDaten.data.listEnergieMessDatens.items) {
            for (let i = 0; i < energieMessDaten.data.listEnergieMessDatens.items.length; i++) {
                let item = energieMessDaten.data.listEnergieMessDatens.items[i];
                energieMessDatenResults.push(item);
            }
        }
        for (let i = 0; i < energieMessDatenResults.length; i++) {
            if (energieMessDatenResults[i].bezugsJahr === jahr) {
                await createEnergiekennzahlenJahresDatenbyBaseline(baselineID, jahr, einflussFaktorMessDatenID, energieMessDatenResults[i].id);
            }
        }
    } catch (error) {
        console.log("Fetch Faild ", error);
    }
}

export const createEnergiekennzahlenJahresDatenbyBaseline = async (baselineID, jahr, einflussFaktorMessDatenID, energieMessDatenID) => {
    if (await checkEnergieKennzahlenJahresDatenExist(baselineID, jahr)) {
        console.log("JahresDaten Exist");
        return
    }

    let energieMessDaten = await getEnergieMessDatenbyId(energieMessDatenID);
    let einflussFaktorMessDaten = await getFaktorMessDatenbyId(einflussFaktorMessDatenID);
    let durchschnittsEnergieAufwand = undefined;

    if (energieMessDaten.summeMessdaten && einflussFaktorMessDaten.summeMessdaten) {
        durchschnittsEnergieAufwand = energieMessDaten.summeMessdaten / einflussFaktorMessDaten.summeMessdaten;
    }
    try {
        const energieKennzahlenJahresDaten = {
            baselineEnergieKennzahlenID: baselineID,
            jahr: jahr,
            einflussFaktorMessDatenID: einflussFaktorMessDatenID,
            energieverbrauchsDatenID: energieMessDatenID,
            durchschnittsEnergieAufwand: durchschnittsEnergieAufwand
        }
        await API.graphql(graphqlOperation(createEnergieKennzahlenJahresDaten, {input: energieKennzahlenJahresDaten}));
    } catch (error) {
        console.log("Fehler bei Speichern einer EnergieKennzahlenJahresDaten", error)
    }
}

export const checkEnergieKennzahlenJahresDatenExist = async (baselineID: string, jahr) => {
    const filter = {
        baselineEnergieKennzahlenID: {
            eq: baselineID
        }
    };
    try {
        const energieDatenlist = await API.graphql({
            query: listEnergieKennzahlenJahresDatens, variables: {
                filter: filter,
                limit: 10000
            }
        }) as { data: ListEnergieKennzahlenJahresDatensQuery };

        if (energieDatenlist && energieDatenlist.data && energieDatenlist.data.listEnergieKennzahlenJahresDatens && energieDatenlist.data.listEnergieKennzahlenJahresDatens.items) {
            console.log(energieDatenlist.data.listEnergieKennzahlenJahresDatens.items);
            for (let i = 0; i < energieDatenlist.data.listEnergieKennzahlenJahresDatens.items.length; i++) {
                if (energieDatenlist.data.listEnergieKennzahlenJahresDatens.items[i].jahr === jahr) {
                    return true;
                }
            }
        }
        return false;
    } catch (error) {
        console.log("fatch EnergiekennzahlenJahresMessdaten Failed ", error);
    }
}
