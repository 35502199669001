import { API, graphqlOperation } from "aws-amplify";
import { evaluate, parse } from "mathjs";
import { CreateEnergieMessDatenMutation, EnergieMessDaten, Prozess, UpdateEnergieMessDatenMutation } from "../API";
import { createEnergieKennzahlenforNewYearProcess } from "../DataBase/BaselineEnergieKennzahlen/BaselineEnergieKennzahlenAPI";
import { energieMessDatenForProcess, UpdateJahresDatenbyEnergieMessdatenID } from "../DataBase/MessDaten/MessDatenAPI";
import { getProcess, listProcessbyCompanyID } from "../DataBase/Prozess/ProzessAPI";
import { createEnergieMessDaten, updateEnergieMessDaten } from "../graphql/mutations";
import { EnergySource } from "../API";
import { getEnergySourceById } from "../DataBase/EnergySourceAPI";
import { MIXED_ENERGY_SOURCE } from "./EnergySource";
import { formater, isEmpty } from "../Util/helper";

export const getReferencedConsumerNames = (formula: string) => {
    const regex = /\${.*?}/g;
    const references: string[] = [];
    let m: RegExpExecArray;

    while ((m = regex.exec(formula)) !== null) {
        // This is necessary to avoid infinite loops with zero-width matches
        if (m.index === regex.lastIndex) {
            regex.lastIndex++;
        }
        m.forEach((match, groupIndex) => {
            references.push(match);
        });
    }
    return references;
}

const getReferencedConsumers = async (companyID: string, referencedConsumerNames: string[], allConsumers: Prozess[]) => {
    const consumers = allConsumers ? allConsumers : await listProcessbyCompanyID(companyID);
    const ret : Prozess[] = [];
    for (const ref of referencedConsumerNames) {
        const refName = ref.substring(2, ref.length - 1); // remove the surrounding '${' and '}'
        const consumer = consumers.find((consumer) => refName === consumer.prozessname);

        if (!consumer) {
            // we have an invalid consumer reference, stop calculations
            throw new Error(`Unbekannter Verbraucher: ${ref}`);
        }
        ret.push(consumer);
    }
    return ret;
}

const getDataFromReferencedConsumers = async (companyID: string, referencedConsumerNames: string[], allConsumers?: Prozess[]) => {
    const consumers = allConsumers ? allConsumers : await listProcessbyCompanyID(companyID);
    const allData = {};
    for (const ref of referencedConsumerNames) {
        const refName = ref.substring(2, ref.length - 1); // remove the surrounding '${' and '}'
        const consumer = consumers.find((consumer) => refName === consumer.prozessname);

        if (!consumer) {
            // we have an invalid consumer reference, stop calculations
            return [];
        }
        const data = consumer.energieverbrauchsDaten.items;
        allData[ref] = data;
    }
    return allData;
}

//replace our ${xxx} vars with v1,v2,... as mathjs doesn't like special chars
const createMappings = (formula: string, refs: string[]) => {
    const mappings = new Map<string, string>();
    let varIndex = 1;
    let mappedFormula = formula;
    for (const ref of refs) {
        const mappedName = 'v' + (varIndex++);
        mappings.set(ref, mappedName);
        mappedFormula = mappedFormula.replace(ref, mappings.get(ref));
    }
    return {
        mappedFormula,
        mappings
    };
};

export const validateFormula = async (companyID: string, formula: string, availableConsumers) => {
    try {
        const refs = getReferencedConsumerNames(formula);
        const { mappedFormula, mappings } = createMappings(formula, refs);
        const refConsumers = await getReferencedConsumers(companyID, refs, availableConsumers);
        parse(mappedFormula);
        const dummyValues = {};

        for (const value of refConsumers) {
            const mappedName = mappings.get(`\$\{${value.prozessname}\}`);
            dummyValues[mappedName] = 0;
        }
        console.log("dummyValues", dummyValues);

        evaluate(mappedFormula, dummyValues);
    } catch (e) {
        //NOTE we need to back-translate error messages from the vXX variables to the originals via "mappings"
        throw new Error('Fehler in der Formel!', {cause: e});
    }
}

export const isVirtualConsumer = (prozess: Prozess) => {
    return !!prozess.formula;
}

export const isMixedSourceVirtualConsumer = (prozess: Prozess) => {
    if (!isVirtualConsumer(prozess)) {
        return false;
    }
    return !prozess.energySourceID;
}

export const generateVirtualConsumer = async (prozess: Prozess, processes?: Prozess[], energySources?: EnergySource[]) : Promise<Prozess> => {
    try {
        if (!isVirtualConsumer(prozess)) {
            return prozess;
        }
        const refNames = getReferencedConsumerNames(prozess.formula)
        const refs = await getReferencedConsumers(prozess.companyID, refNames, processes);

        const _energySources = new Map<string, EnergySource>();
        const _allEnergySources = new Map<string, EnergySource>();
        if (energySources) {
            for (const es of energySources) {
                _allEnergySources.set(es.id, es);
            }
        }
        for (const ref of refs) {
            if (!ref.energySourceID) {
                continue;
            }
            if (!_energySources.has(ref.energySourceID)) {
                const energySource = _allEnergySources.get(ref.energySourceID) ?? await getEnergySourceById(ref.energySourceID);
                _energySources.set(energySource.id, energySource);
            }
        }

        const energySourcesArray = Array.from(_energySources.values());
        const energySourceID = _energySources.size === 1 ? energySourcesArray[0].id : null;
        const kohlendioxid = _energySources.size === 1 ? energySourcesArray[0].co2factor : null;
        const einheit = _energySources.size === 1 ? energySourcesArray[0].unit : null;
        return {
            ...prozess,
            energySourceID,
            kohlendioxid: '' + kohlendioxid,
            einheit: einheit,
        };
    }
    catch (e) {
        return {
            ...prozess,
            energietraeger: "-1",
        };
    }
}

export const generateDataForVirtualConsumer = async (prozess: Prozess, allConsumers?: Prozess[], onlyCalculate = false) => {
    if (!prozess.formula) {
        return;
    }
    const refs = getReferencedConsumerNames(prozess.formula);
    const data = await getDataFromReferencedConsumers(prozess.companyID, refs, allConsumers);
    const { mappedFormula, mappings } = createMappings(prozess.formula, refs);
    const yearData = {}; // data from referenced consumers, grouped by year
    const combinedData = [] as EnergieMessDaten[];

    if (refs.length === 0) {
        return null;
    }

    for (const consumerRef in data) {
        for (const year of data[consumerRef]) {
            yearData[year.bezugsJahr] = yearData[year.bezugsJahr] || {};
            yearData[year.bezugsJahr][consumerRef] = year.Messpunkte;
        }
    }

    for (const year in yearData) {
        const currentYearData = yearData[year];
        const calculatedYearData = [];
        for (let i = 0; i < 12; i++) {
            const vars = {};
            for (const consumerRef of refs) {
                const varValue = currentYearData?.[consumerRef]?.[i];
                vars[mappings.get(consumerRef)] = varValue ? varValue : 0;
            }
            const calculated = evaluate(mappedFormula, vars);
            calculatedYearData.push(calculated);
        }
        combinedData.push({
            bezugsJahr: year,
            Messpunkte: calculatedYearData,
            summeMessdaten: calculatedYearData.reduce((accumulator, value) => { return accumulator + value }, 0),
            prozessID: prozess.id,
        } as EnergieMessDaten);
    }

    if (!onlyCalculate) {
        const dataInDb = await energieMessDatenForProcess({id: prozess.id} as Prozess);
        const years = dataInDb.map((value) => value.bezugsJahr);

        for (const dataItem of combinedData) {
            let ref;
            const index = years.indexOf(dataItem.bezugsJahr);
            if (index > -1) {
                ref = (await API.graphql(graphqlOperation(updateEnergieMessDaten, {input: {...dataItem, id: dataInDb[index].id}})) as { data: UpdateEnergieMessDatenMutation }).data.updateEnergieMessDaten;
            } else {
                ref = (await API.graphql(graphqlOperation(createEnergieMessDaten, {input: dataItem})) as { data: CreateEnergieMessDatenMutation }).data.createEnergieMessDaten;
            }
    
            await createEnergieKennzahlenforNewYearProcess(ref.prozessID, ref.id, ref.bezugsJahr);
            await UpdateJahresDatenbyEnergieMessdatenID(ref.id);
        }
    }
    return combinedData;
}

const calculateTotalsForRealConsumer = (cpd: CachedProcessDetails, startYear: number, endYear: number, startMonth: number, endMonth: number) => {
    const energyDataViewList = cpd.energyMeasurements.map((measurement, idx) => ({
        key: idx,
        val: { ...measurement, einheit: cpd.process.einheit },
    }));

    let gesamtVerbrauch = 0;
    let energiekosten = 0;
    let dataError = "";
    if (!energyDataViewList) {
        return {
            gesamtVerbrauch: 0,
            energiekosten: 0,
        };
    }
    for (let y = 0; y < energyDataViewList.length; y++) {
        const year = Number.parseInt(energyDataViewList[y].val.bezugsJahr, 10);
        if (energyDataViewList[y].val.kostenpu === undefined) {
            dataError = dataError + energyDataViewList[y].val.bezugsJahr + " ";
        }
        for (let m = startMonth; m <= endMonth; m++) {
            if (startYear <= year && year <= endYear) {
                if (energyDataViewList[y].val.Messpunkte) {
                    gesamtVerbrauch += energyDataViewList[y].val.Messpunkte[m];
                }
                if (energyDataViewList[y].val.kostenpu) {
                    energiekosten += parseFloat(energyDataViewList[y].val.kostenpu) * energyDataViewList[y].val.Messpunkte[m];
                }
            }
        }
    }
    return {
        dataError,
        gesamtVerbrauch,
        energiekosten,
        gesamtCO2: gesamtVerbrauch * cpd.energySource.co2factor,
    };
};

class CachedProcessDetails { // cached data for a real (non-virtual) consumer
    process: Prozess;
    energySource: EnergySource;
    energyMeasurements: EnergieMessDaten[];

    static async load(process: Prozess) {
        const cpd = new CachedProcessDetails();
        cpd.process = process;
        cpd.energySource = await getEnergySourceById(cpd.process.energySourceID);
        cpd.energyMeasurements = await energieMessDatenForProcess(cpd.process);
        return cpd;
    }
}

export class CachedConsumer
{
    public primaryProcess: Prozess;
    private childProcesses?: CachedProcessDetails[]; // for real consumers length = 1

    static async load(processId: string) {
        const cc = new CachedConsumer();
        const process = await getProcess(processId);
        if (isVirtualConsumer(process)) {
            const allProcesses = await listProcessbyCompanyID(process.companyID);
            const refNames = getReferencedConsumerNames(process.formula);
            const refs = await getReferencedConsumers(process.companyID, refNames, allProcesses);
            const childProcesses = [] as CachedProcessDetails[];
            for (const ref of refs) {
                childProcesses.push(await CachedProcessDetails.load(ref));
            }
            cc.childProcesses = childProcesses;
        } else {
            cc.childProcesses = [await CachedProcessDetails.load(process)];
        }
        cc.primaryProcess = process;
        return cc;
    }

    calcUsingFormula(data: any) {
        const refs = getReferencedConsumerNames(this.primaryProcess.formula);
        const { mappedFormula, mappings } = createMappings(this.primaryProcess.formula, refs);
        const vars = {};
        for (const ref of refs) {
            const cpd = this.getCpdForRef(ref);
            vars[mappings.get(ref)] = data[cpd.process.id];
        }
        const result = evaluate(mappedFormula, vars);
        return isFinite(result) ? result : 0.0;
    }

    getTotals(startYear: number, endYear: number, startMonth: number, endMonth: number) {
        let gesamtVerbrauch = 0;
        let energiekosten = 0;
        let gesamtCO2 = 0;
        let dataError = "";

        if (this.isVirtual()) {
            const gesamtVerbrauchMap = {};
            const energiekostenMap = {};
            const gesamtCO2Map = {};
            for (const cpd of this.childProcesses) {
                const totals = calculateTotalsForRealConsumer(cpd, startYear, endYear, startMonth, endMonth);
                gesamtVerbrauchMap[cpd.process.id] = totals.gesamtVerbrauch;
                energiekostenMap[cpd.process.id] = totals.energiekosten;
                gesamtCO2Map[cpd.process.id] = totals.gesamtCO2;
            }
            gesamtVerbrauch = this.calcUsingFormula(gesamtVerbrauchMap);
            energiekosten = this.calcUsingFormula(energiekostenMap);
            gesamtCO2 = this.calcUsingFormula(gesamtCO2Map);
        } else {
            for (const cpd of this.childProcesses) {
                const totals = calculateTotalsForRealConsumer(cpd, startYear, endYear, startMonth, endMonth);
                gesamtVerbrauch += totals.gesamtVerbrauch;
                energiekosten += totals.energiekosten;
                gesamtCO2 += totals.gesamtCO2;
                dataError += totals.dataError + " ";
            }
        }
        return {
            gesamtVerbrauch,
            energiekosten,
            gesamtCO2,
            dataError: dataError.trim(),
        };
    }

    getYears() {
        const allYears = [] as number[];
        for (const cpd of this.childProcesses) {
            allYears.push(...cpd.energyMeasurements.map(({ bezugsJahr }) => Number.parseInt(bezugsJahr, 10)));
        }
        return [...new Set(allYears)].sort((a, b) => a - b); //unique years
    }

    getEinheit() {
        if (isMixedSourceVirtualConsumer(this.primaryProcess)) {
            return "<Verschiedene>";
        }
        return this.primaryProcess.einheit;
    }

    getEnergySource() {
        // einheit will be 'null' for virtual consumers with different energy sources
        if (this.primaryProcess.einheit) {
            return this.childProcesses[0].energySource;
        } else {
            return MIXED_ENERGY_SOURCE;
        }
    }

    getCO2FactorStr() {
        if (isMixedSourceVirtualConsumer(this.primaryProcess)) {
            return "<Verschiedene>";
        }
        const energySource = this.getEnergySource();
        return formater(energySource.co2factor) + " kg/" + energySource.unit;
    }

    getTotalCO2Str(gesamtCO2: number | null) {
        if (isEmpty(gesamtCO2)) {
            return "-";
        } else {
            return formater(gesamtCO2) + " kg";
        }
    }

    getGesamtVerbrauchStr(gesamtVerbrauch: number | null) {
        if (!gesamtVerbrauch) {
            if (isVirtualConsumer(this.primaryProcess)) {
                return "-";
            } else {
                return "Daten hinzufügen";
            }
        } else {
            if (isMixedSourceVirtualConsumer(this.primaryProcess)) {
                return "<Verschiedene Einheiten>";
            } else {
                return formater(gesamtVerbrauch) + " " + this.getEnergySource().unit;
            }
        }
    }

    isVirtual() {
        return isVirtualConsumer(this.primaryProcess);
    }

    isMixedSource() {
        return isMixedSourceVirtualConsumer(this.primaryProcess);
    }

    private getCpdForRef(ref: string) {
        const refName = ref.substring(2, ref.length - 1); // remove the surrounding '${' and '}'
        return this.childProcesses.find((cp) => refName === cp.process.prozessname);
    }

    getDataViewList() {
        if (this.isMixedSource()) {
            return null;
        }
        if (!this.isVirtual()) {
            return this.childProcesses[0].energyMeasurements.map((measurement, idx) => ({
                key: idx,
                val: { ...measurement, einheit: this.getEinheit() },
            }));
        }

        const allYears = this.getYears();
        const years = new Map<string, Map<string, EnergieMessDaten>>();
        for (const year of allYears) {
            years.set("" + year, new Map());
        }

        for(const c of this.childProcesses) {
            c.energyMeasurements.map((measurement, idx) => {
                years.get(measurement.bezugsJahr).set(measurement.prozessID, measurement);
            });
        }
        const energyDataViewList = [];
        let index = 0;
        const refs = getReferencedConsumerNames(this.primaryProcess.formula);
        const { mappedFormula, mappings } = createMappings(this.primaryProcess.formula, refs);

        for (const year of allYears) {
            const yearData = years.get(year + "");
            const messpunkte: number[] = new Array(12);
            for (let i = 0; i < 12; i++) {
                const vars = {};
                for (const ref of refs) {
                    const cpd = this.getCpdForRef(ref);
                    const data = yearData.get(cpd.process.id);
                    const varValue = data ? (data.Messpunkte ? (data.Messpunkte[i] ?? 0.0): 0.0) : 0.0;
                    vars[mappings.get(ref)] = varValue;
                }
                const result = evaluate(mappedFormula, vars);
                messpunkte[i] = isFinite(result) ? result : 0.0;
            }
            energyDataViewList.push({
                key: index,
                val: {
                    prozessID: this.primaryProcess.id,
                    bezugsJahr: year + "",
                    Messpunkte: messpunkte,
                    summeMessdaten: messpunkte.reduce((sum, x) => sum + x),
                    einheit: this.getEinheit(),
                }
            });
            index++;
        }
        return energyDataViewList;
    }
}