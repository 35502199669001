import {Link, withRouter} from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import {PencilIcon} from '@primer/octicons-react';
import {formater, formaterEuro} from "../../../Util/helper";
import { EnergySource, Prozess } from "../../../API";

interface EnPiBeschreibungProps {
    target;
    process: Prozess;
    einflussfaktor;
    einflussFaktorMessDaten;
    energieMessDaten;
    energySource: EnergySource;
}

const EnPiBeschreibung = (props: EnPiBeschreibungProps) => {
    // constructor(props) {
    //     super(props);
    //     let messdaten = props.match.params.energieMessDaten || props.energieMessDaten;
    //     this.state = {
    //const target = props.target;//: props.match.params.target || props.target,
    //const process = props.process; //process: props.match.params.process || props.process,
    //const einflussfaktor = props.einflussfaktor;//: props.match.params.einflussfaktor || props.einflussfaktor,
    //const einflussFaktorMessDaten = props.einflussFaktorMessDaten; // props.match.params.einflussFaktorMessDaten || props.einflussFaktorMessDaten,
    const energieMessDaten = props.energieMessDaten; //energieMessDaten: messdaten,
    const currentKost = energieMessDaten[energieMessDaten.length - 1].kostenpu || 0.0 ;//currentKost: messdaten[messdaten.length - 1].kostenpu || 0.0
    
    return (
        <div className="root">
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <div className="grid-contents">
                        <div className="base-content-grid">

                            <div className="table-responsive">
                                <table
                                    className="table table-vcenter">

                                    <tbody>
                                    <tr>
                                        <td className="no-border">
                                            Verantwortlichkeit
                                        </td>
                                        <td className="no-border">
                                            {props.target.verantwortlicher}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            Einsparziel
                                        </td>
                                        <td className="text-dark">
                                            <Link to={`/Target/${props.target.id}`}>
                                                {props.target && props.target.amount + " % " + props.target.bewertungsfaktor + " in " + props.target.bezugsJahr}
                                            </Link>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="text-dark">
                                            Energiepreis
                                        </td>
                                        <td className="text-dark">
                                            {formaterEuro(currentKost)}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="text-dark">
                                            CO<sub>2</sub> Faktor
                                        </td>
                                        <td className="text-dark">
                                            {props.energySource.co2factor} kg/kWh
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <div className="grid-contents">
                        <div className="base-content-grid">
                            <div>
                                <header>
                                    <h6 className="medium-font">Verbraucher: {props.process.prozessnummer} {props.process.prozessname}
                                        <Link to={`/Prozess/Prozess/${props.process.id}`}><PencilIcon size={24} className="float-right" /*title="Verbraucher editieren"*/ /></Link>
                                    </h6>
                                </header>
                                <br/>
                                <div className="table-responsive">
                                    <table
                                        className="table table-vcenter">
                                        <thead>
                                        <tr>
                                            <td>Jahr</td>
                                            <td className="text-dark">
                                                Messdaten
                                            </td>
                                            <td className="text-right">Gesamt</td>
                                        </tr>

                                        </thead>
                                        <tbody>
                                        {props.energieMessDaten && props.energieMessDaten.map((energieDaten, index) =>

                                            <tr key={index}>
                                                <td>{energieDaten.bezugsJahr}</td>
                                                <td className="text-dark">
                                                    <Link
                                                        to={`/MessDaten/EnergieDaten/${energieDaten.id}/true`}> {energieDaten.status}</Link>

                                                </td>
                                                <td className="text-dark text-right">
                                                    {energieDaten.summeMessdaten && formater(energieDaten.summeMessdaten.toFixed(0))} 
                                                    {props.process.einheit}
                                                </td>
                                            </tr>
                                        )}
                                        </tbody>
                                    </table>
                                </div>
                                <br/>
                            </div>
                        </div>
                    </div>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <div className="grid-contents">
                        <div className="base-content-grid">
                            <div>
                                <header>
                                    <h6 className="medium-font">Einflussfaktor: {props.einflussfaktor.einflussFaktorNummer} {props.einflussfaktor.einflussFaktorName}
                                        <Link to={`/Factor/Factor/${props.einflussfaktor.id}`}><PencilIcon size={24} className="float-right" /* title="Einflussfaktor editieren" */ /></Link>
                                    </h6>
                                </header>
                                <br/>
                                <div className="table-responsive">
                                    <table
                                        className="table table-vcenter">
                                        <thead>
                                        <tr>
                                            <td>Jahr</td>
                                            <td className="text-dark">
                                                Messdaten
                                            </td>
                                            <td className="text-right">Gesamt</td>
                                        </tr>

                                        </thead>
                                        <tbody>
                                        {props.einflussFaktorMessDaten.map((faktorDaten, index) =>
                                            <tr key={index}>
                                                <td>{faktorDaten.bezugsJahr}</td>
                                                <td className="text-dark">
                                                    <Link
                                                        to={`/MessDaten/Faktor/${faktorDaten.id}/true`}>{faktorDaten.status}</Link>
                                                </td>
                                                <td className="text-dark text-right">
                                                    {faktorDaten.summeMessdaten && formater(faktorDaten.summeMessdaten.toFixed(0))} {props.einflussfaktor.einheit}
                                                </td>
                                            </tr>
                                        )}
                                        </tbody>
                                    </table>
                                </div>
                                <br/>
                            </div>
                        </div>
                    </div>
                </Grid>
            </Grid>
        </div>
    );
}

export default EnPiBeschreibung;
