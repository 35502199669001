import { API, graphqlOperation } from "aws-amplify";
import { getCorporation, listCorporations, listUsers } from "../../graphql/queries";
import { createCorporation, createUser, updateCorporation, updateUser } from "../../graphql/mutations";
import { CreateCorporationMutation, CreateUserMutation, GetCorporationQuery, ListCorporationsQuery, ListUsersQuery, UpdateCorporationMutation, UpdateUserMutation } from "../../API";

export const getCorporationbyID = async (corporationID: string) => {
    try {
        const corporationData = await API.graphql({
            query: getCorporation, variables: {
                id: corporationID
            }
        }) as { data: GetCorporationQuery };
        return corporationData.data.getCorporation;
    } catch (error) {
        console.log("getCorporationbyID Failed", error);
    }
}

export const listCorporationbyCompanyID = async (companyID: string) => {
    try {
        let filter = {
            company: {
                eq: companyID
            }
        };
        const processlist = await API.graphql({
            query: listCorporations, variables: {
                filter: filter,
                limit: 10000
            }
        }) as { data: ListCorporationsQuery };
        return processlist.data.listCorporations.items;
    } catch (error) {
        console.log("Fetch Faild ", error);
    }
}

export const createNewCorporation = async (state) => {
    try {
        const corporation = {
            company: state.company,
            corporationname: state.corporationname,
            street: state.street,
            housenumber: state.housenumber,
            zipcode: state.zipcode,
            town: state.town,
            phoneNumber: state.phoneNumber,
            mail: state.mail,
            standorteJson: state.standorteJson,
            energysourcesJson: state.energysourcesJson,
        }
        const newCorporation = await API.graphql(
            graphqlOperation(createCorporation, {input: corporation})
        ) as { data: CreateCorporationMutation };
        return newCorporation.data.createCorporation;
    } catch (error) {
        console.log("Fehler beim Speichern des Erstellen einer Corporastion", error)
    }
}

export const updateCorporationbyState = async (state) => {
    try {
        const corporation = {
            id: state.id,
            company: state.company,
            corporationname: state.corporationname,
            street: state.street,
            housenumber: state.housenumber,
            zipcode: state.zipcode,
            town: state.town,
            phoneNumber: state.phoneNumber,
            mail: state.mail,
            standorteJson: state.standorteJson,
            energysourcesJson: state.energysourcesJson,
        }
        const updatedCorporation = await API.graphql(
            graphqlOperation(updateCorporation, {input: corporation})
        ) as { data: UpdateCorporationMutation };
        return updatedCorporation.data.updateCorporation;
    } catch (error) {
        console.log("Fehler beim Speichern des Einflussfaktors", error)
    }
}

export const listUsersbyCorporationID = async (corporationID) => {
    try {
        const filter = {
            corporation: {
                eq: corporationID
            }
        };
        const userlist = await API.graphql({
            query: listUsers, variables: {
                filter: filter,
                limit: 10000
            }
        }) as { data: ListUsersQuery };
        return userlist.data.listUsers.items;
    } catch (error) {
        console.log("Fetch Faild ", error);
    }
}

export const createNewUser = async (state) => {
    try {
        const user = {
            corporation: state.corporation,
            firstname: state.firstname,
            lastname: state.lastname,
            position: state.position,
            //phoneNumber: state.phoneNumber,
            mail: state.mail
        }
        const newUser = await API.graphql(
            graphqlOperation(createUser, {input: user})
        ) as { data: CreateUserMutation };
        return newUser.data.createUser;
    } catch (error) {
        console.log("Fehler beim Speichern des Einflussfaktors", error)
    }
}

export const updateUserbyState = async (state) => {
    try {
        const user = {
            id: state.id,
            corporation: state.corporation,
            firstname: state.firstname,
            lastname: state.lastname,
            position: state.position,
            // phoneNumber: state.phoneNumber,
            mail: state.mail
        }
        const updatedUser = await API.graphql(
            graphqlOperation(updateUser, {input: user})
        ) as { data: UpdateUserMutation };
        return updatedUser.data.updateUser;
    } catch (error) {
        console.log("Fehler beim Speichern des Users", error)
    }
}
