import {Link} from "react-router-dom";
import {formater} from "../../Util/helper";
import {FiTrash2} from "react-icons/fi/index";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import {Button} from "react-bootstrap";
import {deleteFaktorMessdatenByID} from "../../DataBase/MessDaten/MessDatenAPI";
import { useState } from "react";

interface FaktorMessDatenListItemProps {
    energieDaten: {
        val: {
            id: any;
            bezugsJahr: any;
            summeMessdaten: any;
            einflussFaktorName: any;
            Messpunkte: any;
        }
    };
}

const FaktorMessDatenListItem = (props: FaktorMessDatenListItemProps) => {
    const [open, setOpen] = useState(false);

    const isMesspunkteComplete = (messpunkte) => {
        if (messpunkte && messpunkte.length === 12) {
            for (let i = 0; i < messpunkte.length; i++) {
                if (!isMesspunktDefined(messpunkte[i])) {
                    return "Unvollständig"
                }
            }
            return "Vollständig";
        }
        return "Unvollständig";
    }

    const isMesspunktDefined = (punkt) => {
        return (0 === punkt || (punkt && punkt !== ""));
    }

    const handleClickOpen = () => {
        setOpen(true);
    }

    const handleClose = () => {
        setOpen(false);
    }

    const deleteMessJahr = async() => {
        await deleteFaktorMessdatenByID(props.energieDaten.val.id);
        handleClose();
        window.location.reload();
    }

    return (
        <tr>
            <td>{props.energieDaten.val.bezugsJahr}</td>
            <td className="text-dark text-right">
                {props.energieDaten.val.summeMessdaten && formater(props.energieDaten.val.summeMessdaten)}
            </td>
            <td className="text-dark text-right">
                <Link to={`/MessDaten/FaktorDaten/${props.energieDaten.val.id}`}>
                    {isMesspunkteComplete(props.energieDaten.val.Messpunkte)}
                </Link>
            </td>
            <td>
                <FiTrash2 size={24} className="float-right" title="Messjahr löschen" onClick={handleClickOpen}/>
                <div>
                    <Dialog
                        fullScreen={false}
                        open={open}
                        onClose={handleClose}
                        aria-labelledby="responsive-dialog-title"
                    >
                        <DialogTitle id="responsive-dialog-title">
                            {"Messdaten löschen"}
                        </DialogTitle>
                        <DialogContent>
                            <DialogContentText>
                                Bist du sicher, dass du die Messdaten
                                für {props.energieDaten.val.bezugsJahr ? "das Jahr " + props.energieDaten.val.bezugsJahr : " unbekanntes Jahr"} 
                                {props.energieDaten.val.einflussFaktorName} löschen
                                möchtest?
                                Dies kann nicht rückgängig gemacht werden.
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button className="red-button" onClick={deleteMessJahr}>
                                Löschen
                            </Button>
                            <Button className="link-button" onClick={handleClose}>
                                Abbrechen
                            </Button>
                        </DialogActions>
                    </Dialog>
                </div>
            </td>

        </tr>
    );
}


export default FaktorMessDatenListItem;
