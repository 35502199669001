import useStateRef from 'react-usestateref'
import { withRouter } from "react-router-dom";
import {
    getBaselineEnergieKennzahlenById,
    getEnergieKennzahlenJahresDatenByBaselineId, getEnergieKennzahlenJahresDatenByEnergieMessDatenId
} from "../../DataBase/BaselineEnergieKennzahlen/BaselineEnergieKennzahlenAPI";
import { getProcess } from "../../DataBase/Prozess/ProzessAPI";
import { getTargetbyID } from "../../DataBase/Target/TargetAPI";
import Grid from "@material-ui/core/Grid";
import {
    getEnergieMessDatenbyId, getFaktorMessDatenbyId,
    messDatenSatus
} from "../../DataBase/MessDaten/MessDatenAPI";
import { getEinflussFaktorbyID } from "../../DataBase/EinflussFaktor/EinflussfaktorAPI";
import EnergikennZahlenBaselineCanvas from "../EnergikennZahlenBaselineCanvas";
import EnergieKennzahlTargetCanvas from "../EnergieKennzahlTargetCanvas";
import DurchschnittsEntwicklung from "./Graphen/DurchschnittsEntwicklung";
import CircularProgress from "@material-ui/core/CircularProgress";
import { prozentualeAbweichung } from "./Logik/AbweichungsAnalyseLogik";
import EnPiBeschreibung from "./Beschreibung/EnPiBeschreibung";
import EnPiAbweichungZiel from "./Abweichung/EnPiAbweichungZiel";
import EnPiAbweichungSum from "./Abweichung/EnPiAbweichungSum";
import { formater } from "../../Util/helper";
import BasicKennzahlenTable from "../BasicKennzahlenTable";
import BasicMonatskennzahlen from "../BasicMonatskennzahlen";
import { useConstructor } from "../../Util/ReactUtils";
import { BaselineEnergieKennzahlen,
         EinflussFaktorMessDaten, 
         EnergieMessDaten, EnergySource, Prozess, Target } from "../../API";
import { getEnergySourceById } from "../../DataBase/EnergySourceAPI";
import { useState } from 'react';

const AbweichungsAnalyse = (props) => {
    const baselineID = props.match.params.id;
    const [loading, setLoading] = useState(true);
    const [showState, setShowState] = useState("loading");
    const [isMessdatenDefined, setIsMessdatenDefined] = useState(false);
    const [baseline, setBaseline, baselineRef] = useStateRef({} as BaselineEnergieKennzahlen);
    const [energieKennzahlenDaten, setEnergieKennzahlenDaten, energieKennzahlenDatenRef] = useStateRef([]);
    const [process, setProcess, processRef] = useStateRef({} as Prozess);
    const [einflussfaktor, setEinflussfaktor, einflussfaktorRef] = useStateRef({
        id: "",
        companyID: "",
        messDaten: [],
        einflussFaktorNummer: "",
        einflussFaktorName: "",
        einheit: "",
        verantwortlicher: ""
    });
    const [target, setTarget, targetRef] = useStateRef({} as Target);
    const [energieMessDaten, setEnergieMessDaten, energieMessDatenRef] = useStateRef([]);
    const [einflussFaktorMessDaten, setEinflussFaktorMessDaten, einflussFaktorMessDatenRef] = useStateRef([]);
    const [energySource, setEnergySource] = useState({} as EnergySource);
    //abweichung: 0,
    const [abweichungZiel, setAbweichungZiel] = useState(0);
    const [summeNormalisiert, setSummeNormalisiert] = useState(0);
            // normalisiert: undefined,
            // gemessen: undefined,
    const [aktuelleAbweichung, setAktuelleAbweichung] = useState(0);
    const [abweichungProzentualAbsolute, setAbweichungProzentualAbsolute] = useState(0);
    const [summegemessen, setSummegemessen] = useState(0);
    const [mediancanvasdaten, setMediancanvasdaten] = useState(undefined);
    const [targetcanvasdaten, setTargetcanvasdaten] = useState(undefined);
    const [einheit, setEinheit] = useState(undefined as string);
    const [tempDataForTestingMonatskennzahlen, setTempDataForTestingMonatskennzahlen] = useState(undefined);

    const [bisherigeErsparnis, setBisherigeErsparnis] = useState(0);

    const loadData = async (baselineid) => {
        let baselineEnergieKennzahlen = await getBaselineEnergieKennzahlenById(baselineid);
        if (!baselineEnergieKennzahlen.rwert) {
            setShowState("noEnergieVerbrauchsFunktion");
            return;
        }
        setBaseline(baselineEnergieKennzahlen);
        setEnergieKennzahlenDaten(await getEnergieKennzahlenJahresDatenByBaselineId(baselineid));

        let processData = await getProcess(baselineEnergieKennzahlen.prozessID);
        if (processData.energySourceID) {
            setEnergySource(await getEnergySourceById(processData.energySourceID));
        }
        if (processData.energiekosten) {
            processData.energiekosten = processData.energiekosten.replaceAll(',', '.');
        }
        setProcess(processData);
        if (baselineEnergieKennzahlen.target) {
            let target = await getTargetbyID(baselineEnergieKennzahlen.target);
            setTarget(target);
        } else if (processData.aktuellesTarget) {
            let target = await getTargetbyID(processData.aktuellesTarget);
            setTarget(target);
        }

        console.log("baselineEnergieKennzahlen", baselineEnergieKennzahlen);
        let einflussfaktor = await getEinflussFaktorbyID(baselineEnergieKennzahlen.einflussfaktorID);
        setEinflussfaktor(einflussfaktor);

        await loadMessDaten(baselineEnergieKennzahlen);
        await fetchEnergieKennZahlenJahresDaten(baselineID);
        setEinheit(processRef.current.einheit);
    }

    useConstructor(() => {
        if (baselineID) {
            loadData(baselineID);
        } else {
            console.log("Error no Baseline ID");
        }
    });

    const fetchEnergieKennZahlenJahresDaten = async (baselineEnergieKennzahlenID) => {
        const energieKennzahlenDaten = await getEnergieKennzahlenJahresDatenByBaselineId(baselineEnergieKennzahlenID);
        setEnergieKennzahlenDaten(energieKennzahlenDaten);
        createMedianCanvasData(energieKennzahlenDaten);
        //state.baseline.energieKennzahlenDaten gives all tables from EnergieKennzahlenJahresDaten table maching the actual BaselineEnergieKennzahlen ID

        // TODO: replace when backend is implemented
        // calculate tempDataForTestingMonatskennzahlen
        createMonatskennzahlen();
        setLoading(false);
    }

    //Function returns an object with arrays of length two, the first one includes the Einheit the other year and Verbraucher/Einflussfaktor
    const createMedianCanvasData = (energieMessDaten) => {
        if (energieMessDaten) {
            let header = ['Jahr'];
            for (let i = 0; i < energieMessDaten.length; i++) {
                header.push(energieMessDaten[i].jahr)
            }

            let canvas = [];
            console.log("processRef.current", processRef.current);
            console.log("processRef.current.einheit", processRef.current.einheit);
            console.log("einflussfaktorRef.current.einheit", einflussfaktorRef.current.einheit);
            let line = [header[0], processRef.current.einheit + " / " + einflussfaktorRef.current.einheit];
            canvas.push(line);
            for (let i = 0; i < energieMessDaten.length; i++) {
                let line = [header[i + 1]];
                if (energieMessDaten[i].durchschnittsEnergieAufwand) {
                    line.push(""+Number.parseFloat((energieMessDaten[i].durchschnittsEnergieAufwand).toFixed(3)));
                } else {
                    line.push("");
                }

                canvas.push(line);
            }
            setMediancanvasdaten(canvas);
        }
    }

    // TODO: move this calculation to MessDatenAPI.js when backend is implemented
    // calculate tempDataForTestingMonatskennzahlen
    const createMonatskennzahlen = () => {
        const energieMessDaten = energieMessDatenRef.current;
        const einflussFaktorMessDaten = einflussFaktorMessDatenRef.current;
        console.log("createMonatskennzahlen", energieMessDaten, einflussFaktorMessDaten);
        // store month data
        let monatsEnergiekennzahlen = [];
        if (energieMessDaten && energieMessDaten.length > 0 && einflussFaktorMessDaten && einflussFaktorMessDaten.length > 0) {

            // for all years providing data (einflussFaktorMessDaten should have same length as energieMessDaten)
            for (let i = 0; i < energieMessDaten.length; i++) {
                // if data is provided calculate energiekennzahl = energieMessDaten / einflussFaktorMessDaten

                let temp = 0;
                if(einflussFaktorMessDaten[i] && einflussFaktorMessDaten[i].Messpunkte) {
                    monatsEnergiekennzahlen[i] = {bezugsJahr: einflussFaktorMessDaten[i].bezugsJahr, kennzahlen: []}
                    energieMessDaten[i].Messpunkte.forEach((value, index) => {
                        if(einflussFaktorMessDaten[i].Messpunkte[index]) {
                            temp = einflussFaktorMessDaten[i].Messpunkte[index];
                            monatsEnergiekennzahlen[i].kennzahlen[index] = value / temp;
                        }
                    })
                }
            }
            setTempDataForTestingMonatskennzahlen(monatsEnergiekennzahlen);
        } else {
            setTempDataForTestingMonatskennzahlen(undefined);
        }
    }

    // load data for energieMessDaten which will be displayed later as "Verbraucher-Messdaten"
    const loadMessDaten = async (baseline: BaselineEnergieKennzahlen) => {
        let _energieMessDaten = [];
        let _einflussFaktorMessDaten = [];
        let cancasData = [];
        let baselineYear = '';

        //let yearOfInterest = this.state.target.bezugsJahr;
        let allJahresDaten = energieKennzahlenDatenRef.current;

        let energieMessDatenJahr = await getEnergieMessDatenbyId(baseline.baselineEnergieverbrauchsDatenID) as 
            (EnergieMessDaten & {status: string});

        baselineYear = energieMessDatenJahr.bezugsJahr;
        const targetYear = targetRef.current.bezugsJahr;

        energieMessDatenJahr.status = "Abgeschlossen (Referenzjahr)";
        _energieMessDaten.push(energieMessDatenJahr);

        let faktorMessDatenJahr = await getFaktorMessDatenbyId(baseline.baselineEinflussFaktorMessDatenID) as
            (EinflussFaktorMessDaten & {status: string});
        faktorMessDatenJahr.status = "Abgeschlossen (Referenzjahr)";
        _einflussFaktorMessDaten.push(faktorMessDatenJahr);

        //Load Date which are needed for createTargetCanvasData which is than called by EnergieKennzahlenTargetCanvas 
        for (let i = 0; i < allJahresDaten.length; i++) {
            // show data of all years that provide data
            if (allJahresDaten[i].jahr !== baselineYear) {
                let energieMessDatenJahr = await getEnergieMessDatenbyId(allJahresDaten[i].energieverbrauchsDatenID) as
                    (EnergieMessDaten & {status: string});
                energieMessDatenJahr.status = messDatenSatus(energieMessDatenJahr.Messpunkte);
                //console.log("energieMessDatenJahr", energieMessDatenJahr);
                _energieMessDaten.push(energieMessDatenJahr);
                //console.log("AfterPushenergieMessDaten", energieMessDatenJahr);
                cancasData.push([].concat(["Gemessen " + energieMessDatenJahr.bezugsJahr], energieMessDatenJahr.Messpunkte));

                let faktorMessDatenJahr = await getFaktorMessDatenbyId(allJahresDaten[i].einflussFaktorMessDatenID) as
                    (EinflussFaktorMessDaten & {status: string});
                faktorMessDatenJahr.status = messDatenSatus(faktorMessDatenJahr.Messpunkte);
                //setIsMessdatenDefined(energieMessDatenJahr.status !== "Keine Messdaten" || faktorMessDatenJahr.status !== "Keine Messdaten");
                _einflussFaktorMessDaten.push(faktorMessDatenJahr);
                cancasData.push([].concat(["Normalisiert " + faktorMessDatenJahr.bezugsJahr], faktorMessDatenJahr.Messpunkte));
                //this.setState({targetFaktorMessDaten: faktorMessDatenJahr.id});
            }
            if (allJahresDaten[i].jahr === targetYear) {
                setIsMessdatenDefined(energieMessDatenJahr.status !== "Keine Messdaten" || faktorMessDatenJahr.status !== "Keine Messdaten");
            }
        }
        setEnergieMessDaten(_energieMessDaten);
        //energieMessDaten only saves object for baselineyear and targetyear 
        setEinflussFaktorMessDaten(_einflussFaktorMessDaten);
        createTargetCanvasData(cancasData);
        berechneAbweichung();
    }

    const createTargetCanvasData = (data) => {
        const target = targetRef.current;
        let amount = 0.0;
        if (target && target.amount) {
            amount = Number.parseFloat(target.amount);
        }
        let header = ['Monat', "Jan", 'Feb', 'März', 'April', 'Mai', 'Juni', 'Juli', 'Aug', 'Sep', 'Okt', 'Nov', 'Dez'];
        let canvas = [];
        for (let i = 0; i < header.length; i++) {
            if (data && data[0] && data[1]) {
                let line = [header[i], data[0][i], data[1][i]];
                if (i !== 0) {
                    line = [header[i], data[0][i], Number.parseFloat(((applyEnergieVerbrauchfunktion(data[1][i]) / 100 * (100 - amount)).toFixed(0)))];
                }
                canvas.push(line);
            }
        }
        setTargetcanvasdaten(canvas);
    }

    const applyEnergieVerbrauchfunktion = (energieKennzahlMonat) => {
        const baseline = baselineRef.current;
        if (baseline.energieCoefizent && baseline.energieIntercept) {
            return baseline.energieCoefizent * Number.parseFloat(energieKennzahlMonat) + baseline.energieIntercept;
        }
        return Number.parseFloat(energieKennzahlMonat);
    }

    const berechneAbweichung = () => {
        const energieMessDaten = energieMessDatenRef.current;
        const einflussFaktorMessDaten = einflussFaktorMessDatenRef.current;
        // Variable gemessen saves Verbrauchsdaten for targetyear
        let gemessen = energieMessDaten && energieMessDaten.length > 1 && energieMessDaten[1].Messpunkte || [];
        // Variable gemessenFaktor saves Einflussdaten data for targetyear
        let gemessenFaktor = einflussFaktorMessDaten && einflussFaktorMessDaten.length > 1 && einflussFaktorMessDaten[1].Messpunkte || [];
        // Variable gemessenEnergieBaseline saves Verbrauchsdaten for baseline(Referenzjahr)
        let gemessenEnergieBaseline = energieMessDaten && energieMessDaten.length > 0 && energieMessDaten[0].Messpunkte || [];
        // Variable gemessenFaktorbaseline saves Einflussdaten for baseline(Referenzjahr)
        let gemessenFaktorbaseline = einflussFaktorMessDaten && einflussFaktorMessDaten.length > 0 && einflussFaktorMessDaten[0].Messpunkte || [];

        let summeFaktorbaseline = 0;
        let summegemessenEnergiebaseline = 0;
        let normalisiert = [];
        let summeNormalisiert = 0;
        let abweichung = [];
        let abweichungProzentual = [];
        let summe = 0;
        let abweichungProzentualAbsolute = 0;
        let abweichungZiel = 0;
        let bisherigeErsparnis = 0;
        let summegemessen = 0;
        let summeFaktor = 0;

        for (let i = 0; i < 12; i++) {
            if (gemessen[i] && !isNaN(gemessen[i])) {
                summegemessen = Number.parseFloat(summegemessen + gemessen[i]);
            }
            if (gemessenFaktor[i] && !isNaN(gemessenFaktor[i])) {
                summeFaktor = Number.parseFloat(summeFaktor + gemessenFaktor[i]);
                normalisiert.push(applyEnergieVerbrauchfunktion(gemessenFaktor[i]));
                summeNormalisiert = Number.parseFloat(summeNormalisiert + normalisiert[i]);
            } else {
                normalisiert.push(null);
            }
            if (gemessen[i] && !isNaN(gemessen[i]) && normalisiert[i] && !isNaN(normalisiert[i])) {
                abweichung.push(gemessen[i] - normalisiert[i]);
                summe = summe + (gemessen[i] - normalisiert[i]);

                abweichungProzentual.push(prozentualeAbweichung(gemessen[i], normalisiert[i]));
                abweichungProzentualAbsolute = abweichungProzentualAbsolute + prozentualeAbweichung(gemessen[i], normalisiert[i]);
            }
            if (gemessenEnergieBaseline[i] && !isNaN(gemessenEnergieBaseline[i])) {
                summegemessenEnergiebaseline = summegemessenEnergiebaseline + gemessenEnergieBaseline[i];
            }

            if (gemessenFaktorbaseline[i] && !isNaN(gemessenFaktorbaseline[i])) {
                summeFaktorbaseline = summeFaktorbaseline + gemessenFaktorbaseline[i];
            }
        }

        setSummeNormalisiert(summeNormalisiert);
        setSummegemessen(summegemessen);

        //this.setState({abweichung: summe});
        //this.setState({abweichungProzentual: abweichungProzentual});

        setAbweichungProzentualAbsolute(prozentualeAbweichung(summegemessen, summeNormalisiert));

        bisherigeErsparnis = summeNormalisiert * (1 - (Number.parseFloat("0." + (100.00 - (Number.parseFloat(targetRef.current.amount))))));

        setBisherigeErsparnis(-1 * bisherigeErsparnis);

        let aktuelleAbweichung = summegemessen - summeNormalisiert;
        abweichungZiel = bisherigeErsparnis + aktuelleAbweichung;

        setAbweichungZiel(abweichungZiel);
        setAktuelleAbweichung(aktuelleAbweichung);

        //this.setState({targetSollAbweichung: aktuelleAbweichung});
    }

    const checkLoadingState = () => {
        if (showState === "noEnergieVerbrauchsFunktion") {
            return <div>
                <div className="base-page">
                    <header className="align-center">
                        <h3>Die Energieverbrauchsfunktion konnte nicht berechnet werden</h3>
                    </header>
                    <br/>
                    <br/>
                </div>
            </div>
        }
        if (showState === "noData") {
            return;
        }
        return (
            <div className="loading-screen">
                <CircularProgress className="loading-icon"/>
            </div>
        );
    }

    return (
        loading && showState ?
            checkLoadingState()
            :
            <div className="dashboard-root">
                <div className="base-page">
                    <header className="align-center">
                        <h1>{baseline.name}</h1>
                    </header>
                    <br/>
                    {!isMessdatenDefined &&
                    <div>
                        <br/>
                        <br/>
                        <div className="base-page">
                            <header className="align-center">
                                <h3 className="attention-result">Für eine vollständige Auswertung bitte weitere
                                    Messdaten eingeben</h3>
                            </header>
                            <br/>
                        </div>
                    </div>
                    }
                    {energieMessDaten && einflussFaktorMessDaten &&
                    <EnPiBeschreibung target={target}
                                      process={process}
                                      einflussfaktor={einflussfaktor}
                                      einflussFaktorMessDaten={einflussFaktorMessDaten}
                                      energieMessDaten={energieMessDaten}
                                      energySource={energySource}/>
                    }
                    <br/>

                    <header>
                        <h3>Kennzahlenentwicklung </h3>
                    </header>
                    <br/>
                    <div>
                        <BasicKennzahlenTable data={mediancanvasdaten}/>
                    </div>
                    <br/>
                    <div>
                        <h5>Kennzahlen pro Monat</h5>
                        <BasicMonatskennzahlen
                            data={tempDataForTestingMonatskennzahlen}
                            einheit={mediancanvasdaten[0][1]}
                            type="bar"/>
                    </div>


                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <div className="font-large">
                                <div className="base-content-grid">
                                    <header>
                                        <h6 className="float-left">Aufwandsentwicklung
                                            pro {einflussfaktor.einheit} {einflussfaktor.einflussFaktorName}
                                        </h6>
                                    </header>
                                    <br/>
                                    <div>
                                        {mediancanvasdaten &&
                                        <DurchschnittsEntwicklung data={mediancanvasdaten}/>
                                        }
                                    </div>
                                </div>
                            </div>
                        </Grid>
                    </Grid>


                    {isMessdatenDefined &&
                    <div>
                        <header>
                            <h2>Statistische Kennzahlen mit Soll-Ist-Vergleich</h2>
                        </header>
                        <br/>

                        {!loading && energieMessDaten.length > 1 &&
                        <EnPiAbweichungZiel
                            abweichungProzentualAbsolute={abweichungProzentualAbsolute}
                            abweichungZiel={abweichungZiel}
                            aktuelleAbweichung={aktuelleAbweichung}
                            baseline={baseline}
                            bisherigeErsparnis={bisherigeErsparnis}
                            einflussFaktorMessDaten={einflussFaktorMessDaten}
                            energieMessDaten={energieMessDaten}
                            energySource={energySource}
                            process={process}
                            target={target}
                        />
                        }

                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <div className="grid-contents">
                                    <div className="base-content-grid">
                                        <div>
                                            <header>
                                                <h6>Verbräuche nach Monaten für {target.bezugsJahr}</h6>
                                            </header>
                                            <br/>
                                            <div>
                                                {targetcanvasdaten && einheit &&
                                                <EnergieKennzahlTargetCanvas data={targetcanvasdaten}
                                                                                target={target}
                                                                                einheit={einheit}
                                                                                type="line"/>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Grid>
                        </Grid>

                        {!loading && einheit &&
                        <EnPiAbweichungSum
                            einheit={einheit}
                            target={target}
                            summegemessen={summegemessen}
                            summeNormalisiert={summeNormalisiert}
                        />
                        }
                        <br/>
                        <br/>
                    </div>}

                    <br/>
                    <br/>

                    <header>
                        <h2>Energieverbrauchsfunktion</h2>
                    </header>
                    <br/>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <div className="grid-contents">
                                <div className="base-content-grid">
                                    <header className="border-bottom">
                                        <h6 className="medium-font">Energieverbrauchsfunktion auf Basis von {baseline.jahr}</h6>
                                    </header>
                                    <br/>
                                    <h6>{baseline.energieCoefizent && baseline.energieIntercept && "EnPI(Monat) = " + formater(baseline.energieCoefizent) + " " + einheit + "/" + einflussfaktor.einheit + " * " + einflussfaktor.einflussFaktorName + " + " + formater(baseline.energieIntercept) + " " + einheit} </h6>
                                </div>
                            </div>
                        </Grid>
                    </Grid>

                    <br/>
                    <br/>
                    <header>
                        <h3>Regressionsanalysen</h3>
                    </header>
                    <br/>

                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <div className="grid-contents">
                                <div className="base-content-grid">
                                    <header>
                                        <h6 className="medium-font">Regressionsanalysen {baseline.jahr}</h6>
                                        <h6>{"R² = " + formater((baseline.rwert)) + "  "}</h6>
                                    </header>
                                    <br/>
                                    <div>
                                        {baseline.id && einheit &&
                                        <EnergikennZahlenBaselineCanvas baselineID={baseline.id}
                                                                        einheit={einheit}/>
                                        }
                                    </div>
                                </div>
                            </div>
                        </Grid>
                    </Grid>
                </div>
            </div>

    );
}

export default withRouter(AbweichungsAnalyse);
