/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createCompany = /* GraphQL */ `
  mutation CreateCompany(
    $input: CreateCompanyInput!
    $condition: ModelCompanyConditionInput
  ) {
    createCompany(input: $input, condition: $condition) {
      id
      group
      processes {
        items {
          id
          companyID
          naechsteMessung
          aktuelleMessungID
          aktuellesTarget
          targets {
            id
            name
            amount
            bewertungsfaktor
            savings
            bezugsJahr
            verantwortlicher
            prozessID
            createdOn
            updatedOn
            owner
          }
          energieverbrauchsDaten {
            items {
              id
              prozessID
              naechsteMessung
              isvollstaendig
              bezugsJahr
              Messpunkte
              summeMessdaten
              kostenpu
              createdOn
              updatedOn
              owner
            }
            nextToken
          }
          energieKennzahlen {
            items {
              id
              prozessID
              companyID
              name
              jahr
              needProcessing
              target
              energieVerbrauchsFunktion
              energieCoefizent
              energieIntercept
              rwert
              einflussfaktorID
              baselineEinflussFaktorMessDatenID
              baselineEnergieverbrauchsDatenID
              energieKennzahlenJahresDaten {
                nextToken
              }
              createdOn
              updatedOn
              owner
            }
            nextToken
          }
          prozessnummer
          prozessname
          einheit
          verantwortlicher
          energietraeger
          energiekosten
          kohlendioxid
          formula
          energySourceID
          createdOn
          updatedOn
          owner
        }
        nextToken
      }
      name
      mail
      phoneNumber
      createdOn
      updatedOn
    }
  }
`;
export const updateCompany = /* GraphQL */ `
  mutation UpdateCompany(
    $input: UpdateCompanyInput!
    $condition: ModelCompanyConditionInput
  ) {
    updateCompany(input: $input, condition: $condition) {
      id
      group
      processes {
        items {
          id
          companyID
          naechsteMessung
          aktuelleMessungID
          aktuellesTarget
          targets {
            id
            name
            amount
            bewertungsfaktor
            savings
            bezugsJahr
            verantwortlicher
            prozessID
            createdOn
            updatedOn
            owner
          }
          energieverbrauchsDaten {
            items {
              id
              prozessID
              naechsteMessung
              isvollstaendig
              bezugsJahr
              Messpunkte
              summeMessdaten
              kostenpu
              createdOn
              updatedOn
              owner
            }
            nextToken
          }
          energieKennzahlen {
            items {
              id
              prozessID
              companyID
              name
              jahr
              needProcessing
              target
              energieVerbrauchsFunktion
              energieCoefizent
              energieIntercept
              rwert
              einflussfaktorID
              baselineEinflussFaktorMessDatenID
              baselineEnergieverbrauchsDatenID
              energieKennzahlenJahresDaten {
                nextToken
              }
              createdOn
              updatedOn
              owner
            }
            nextToken
          }
          prozessnummer
          prozessname
          einheit
          verantwortlicher
          energietraeger
          energiekosten
          kohlendioxid
          formula
          energySourceID
          createdOn
          updatedOn
          owner
        }
        nextToken
      }
      name
      mail
      phoneNumber
      createdOn
      updatedOn
    }
  }
`;
export const deleteCompany = /* GraphQL */ `
  mutation DeleteCompany(
    $input: DeleteCompanyInput!
    $condition: ModelCompanyConditionInput
  ) {
    deleteCompany(input: $input, condition: $condition) {
      id
      group
      processes {
        items {
          id
          companyID
          naechsteMessung
          aktuelleMessungID
          aktuellesTarget
          targets {
            id
            name
            amount
            bewertungsfaktor
            savings
            bezugsJahr
            verantwortlicher
            prozessID
            createdOn
            updatedOn
            owner
          }
          energieverbrauchsDaten {
            items {
              id
              prozessID
              naechsteMessung
              isvollstaendig
              bezugsJahr
              Messpunkte
              summeMessdaten
              kostenpu
              createdOn
              updatedOn
              owner
            }
            nextToken
          }
          energieKennzahlen {
            items {
              id
              prozessID
              companyID
              name
              jahr
              needProcessing
              target
              energieVerbrauchsFunktion
              energieCoefizent
              energieIntercept
              rwert
              einflussfaktorID
              baselineEinflussFaktorMessDatenID
              baselineEnergieverbrauchsDatenID
              energieKennzahlenJahresDaten {
                nextToken
              }
              createdOn
              updatedOn
              owner
            }
            nextToken
          }
          prozessnummer
          prozessname
          einheit
          verantwortlicher
          energietraeger
          energiekosten
          kohlendioxid
          formula
          energySourceID
          createdOn
          updatedOn
          owner
        }
        nextToken
      }
      name
      mail
      phoneNumber
      createdOn
      updatedOn
    }
  }
`;
export const createCorporation = /* GraphQL */ `
  mutation CreateCorporation(
    $input: CreateCorporationInput!
    $condition: ModelCorporationConditionInput
  ) {
    createCorporation(input: $input, condition: $condition) {
      id
      company
      corporationname
      street
      housenumber
      zipcode
      town
      phoneNumber
      mail
      standorteJson
      energysourcesJson
      createdOn
      updatedOn
      owner
    }
  }
`;
export const updateCorporation = /* GraphQL */ `
  mutation UpdateCorporation(
    $input: UpdateCorporationInput!
    $condition: ModelCorporationConditionInput
  ) {
    updateCorporation(input: $input, condition: $condition) {
      id
      company
      corporationname
      street
      housenumber
      zipcode
      town
      phoneNumber
      mail
      standorteJson
      energysourcesJson
      createdOn
      updatedOn
      owner
    }
  }
`;
export const deleteCorporation = /* GraphQL */ `
  mutation DeleteCorporation(
    $input: DeleteCorporationInput!
    $condition: ModelCorporationConditionInput
  ) {
    deleteCorporation(input: $input, condition: $condition) {
      id
      company
      corporationname
      street
      housenumber
      zipcode
      town
      phoneNumber
      mail
      standorteJson
      energysourcesJson
      createdOn
      updatedOn
      owner
    }
  }
`;
export const createUser = /* GraphQL */ `
  mutation CreateUser(
    $input: CreateUserInput!
    $condition: ModelUserConditionInput
  ) {
    createUser(input: $input, condition: $condition) {
      id
      corporation
      firstname
      lastname
      position
      phoneNumber
      mail
      createdOn
      updatedOn
      owner
    }
  }
`;
export const updateUser = /* GraphQL */ `
  mutation UpdateUser(
    $input: UpdateUserInput!
    $condition: ModelUserConditionInput
  ) {
    updateUser(input: $input, condition: $condition) {
      id
      corporation
      firstname
      lastname
      position
      phoneNumber
      mail
      createdOn
      updatedOn
      owner
    }
  }
`;
export const deleteUser = /* GraphQL */ `
  mutation DeleteUser(
    $input: DeleteUserInput!
    $condition: ModelUserConditionInput
  ) {
    deleteUser(input: $input, condition: $condition) {
      id
      corporation
      firstname
      lastname
      position
      phoneNumber
      mail
      createdOn
      updatedOn
      owner
    }
  }
`;
export const createTarget = /* GraphQL */ `
  mutation CreateTarget(
    $input: CreateTargetInput!
    $condition: ModelTargetConditionInput
  ) {
    createTarget(input: $input, condition: $condition) {
      id
      name
      amount
      bewertungsfaktor
      savings
      bezugsJahr
      verantwortlicher
      prozessID
      createdOn
      updatedOn
      owner
    }
  }
`;
export const updateTarget = /* GraphQL */ `
  mutation UpdateTarget(
    $input: UpdateTargetInput!
    $condition: ModelTargetConditionInput
  ) {
    updateTarget(input: $input, condition: $condition) {
      id
      name
      amount
      bewertungsfaktor
      savings
      bezugsJahr
      verantwortlicher
      prozessID
      createdOn
      updatedOn
      owner
    }
  }
`;
export const deleteTarget = /* GraphQL */ `
  mutation DeleteTarget(
    $input: DeleteTargetInput!
    $condition: ModelTargetConditionInput
  ) {
    deleteTarget(input: $input, condition: $condition) {
      id
      name
      amount
      bewertungsfaktor
      savings
      bezugsJahr
      verantwortlicher
      prozessID
      createdOn
      updatedOn
      owner
    }
  }
`;
export const createProzess = /* GraphQL */ `
  mutation CreateProzess(
    $input: CreateProzessInput!
    $condition: ModelProzessConditionInput
  ) {
    createProzess(input: $input, condition: $condition) {
      id
      companyID
      naechsteMessung
      aktuelleMessungID
      aktuellesTarget
      targets {
        id
        name
        amount
        bewertungsfaktor
        savings
        bezugsJahr
        verantwortlicher
        prozessID
        createdOn
        updatedOn
        owner
      }
      energieverbrauchsDaten {
        items {
          id
          prozessID
          naechsteMessung
          isvollstaendig
          bezugsJahr
          Messpunkte
          summeMessdaten
          kostenpu
          createdOn
          updatedOn
          owner
        }
        nextToken
      }
      energieKennzahlen {
        items {
          id
          prozessID
          companyID
          name
          jahr
          needProcessing
          target
          energieVerbrauchsFunktion
          energieCoefizent
          energieIntercept
          rwert
          einflussfaktorID
          baselineEinflussFaktorMessDatenID
          baselineEnergieverbrauchsDatenID
          energieKennzahlenJahresDaten {
            items {
              id
              baselineEnergieKennzahlenID
              jahr
              einflussFaktorMessDatenID
              energieverbrauchsDatenID
              needProcessing
              normalisedFaktorMessdaten
              graphmetaDatenMonat
              durchschnittsEnergieAufwand
              createdOn
              updatedOn
              owner
            }
            nextToken
          }
          createdOn
          updatedOn
          owner
        }
        nextToken
      }
      prozessnummer
      prozessname
      einheit
      verantwortlicher
      energietraeger
      energiekosten
      kohlendioxid
      formula
      energySourceID
      createdOn
      updatedOn
      owner
    }
  }
`;
export const updateProzess = /* GraphQL */ `
  mutation UpdateProzess(
    $input: UpdateProzessInput!
    $condition: ModelProzessConditionInput
  ) {
    updateProzess(input: $input, condition: $condition) {
      id
      companyID
      naechsteMessung
      aktuelleMessungID
      aktuellesTarget
      targets {
        id
        name
        amount
        bewertungsfaktor
        savings
        bezugsJahr
        verantwortlicher
        prozessID
        createdOn
        updatedOn
        owner
      }
      energieverbrauchsDaten {
        items {
          id
          prozessID
          naechsteMessung
          isvollstaendig
          bezugsJahr
          Messpunkte
          summeMessdaten
          kostenpu
          createdOn
          updatedOn
          owner
        }
        nextToken
      }
      energieKennzahlen {
        items {
          id
          prozessID
          companyID
          name
          jahr
          needProcessing
          target
          energieVerbrauchsFunktion
          energieCoefizent
          energieIntercept
          rwert
          einflussfaktorID
          baselineEinflussFaktorMessDatenID
          baselineEnergieverbrauchsDatenID
          energieKennzahlenJahresDaten {
            items {
              id
              baselineEnergieKennzahlenID
              jahr
              einflussFaktorMessDatenID
              energieverbrauchsDatenID
              needProcessing
              normalisedFaktorMessdaten
              graphmetaDatenMonat
              durchschnittsEnergieAufwand
              createdOn
              updatedOn
              owner
            }
            nextToken
          }
          createdOn
          updatedOn
          owner
        }
        nextToken
      }
      prozessnummer
      prozessname
      einheit
      verantwortlicher
      energietraeger
      energiekosten
      kohlendioxid
      formula
      energySourceID
      createdOn
      updatedOn
      owner
    }
  }
`;
export const deleteProzess = /* GraphQL */ `
  mutation DeleteProzess(
    $input: DeleteProzessInput!
    $condition: ModelProzessConditionInput
  ) {
    deleteProzess(input: $input, condition: $condition) {
      id
      companyID
      naechsteMessung
      aktuelleMessungID
      aktuellesTarget
      targets {
        id
        name
        amount
        bewertungsfaktor
        savings
        bezugsJahr
        verantwortlicher
        prozessID
        createdOn
        updatedOn
        owner
      }
      energieverbrauchsDaten {
        items {
          id
          prozessID
          naechsteMessung
          isvollstaendig
          bezugsJahr
          Messpunkte
          summeMessdaten
          kostenpu
          createdOn
          updatedOn
          owner
        }
        nextToken
      }
      energieKennzahlen {
        items {
          id
          prozessID
          companyID
          name
          jahr
          needProcessing
          target
          energieVerbrauchsFunktion
          energieCoefizent
          energieIntercept
          rwert
          einflussfaktorID
          baselineEinflussFaktorMessDatenID
          baselineEnergieverbrauchsDatenID
          energieKennzahlenJahresDaten {
            items {
              id
              baselineEnergieKennzahlenID
              jahr
              einflussFaktorMessDatenID
              energieverbrauchsDatenID
              needProcessing
              normalisedFaktorMessdaten
              graphmetaDatenMonat
              durchschnittsEnergieAufwand
              createdOn
              updatedOn
              owner
            }
            nextToken
          }
          createdOn
          updatedOn
          owner
        }
        nextToken
      }
      prozessnummer
      prozessname
      einheit
      verantwortlicher
      energietraeger
      energiekosten
      kohlendioxid
      formula
      energySourceID
      createdOn
      updatedOn
      owner
    }
  }
`;
export const createEinflussfaktor = /* GraphQL */ `
  mutation CreateEinflussfaktor(
    $input: CreateEinflussfaktorInput!
    $condition: ModelEinflussfaktorConditionInput
  ) {
    createEinflussfaktor(input: $input, condition: $condition) {
      id
      companyID
      naechsteMessung
      aktuelleMessungID
      messDaten {
        items {
          id
          einflussfaktorID
          naechsteMessung
          isvollstaendig
          bezugsJahr
          Messpunkte
          summeMessdaten
          kostenpu
          createdOn
          updatedOn
          owner
        }
        nextToken
      }
      einflussFaktorNummer
      einflussFaktorName
      einheit
      verantwortlicher
      createdOn
      updatedOn
      owner
    }
  }
`;
export const updateEinflussfaktor = /* GraphQL */ `
  mutation UpdateEinflussfaktor(
    $input: UpdateEinflussfaktorInput!
    $condition: ModelEinflussfaktorConditionInput
  ) {
    updateEinflussfaktor(input: $input, condition: $condition) {
      id
      companyID
      naechsteMessung
      aktuelleMessungID
      messDaten {
        items {
          id
          einflussfaktorID
          naechsteMessung
          isvollstaendig
          bezugsJahr
          Messpunkte
          summeMessdaten
          kostenpu
          createdOn
          updatedOn
          owner
        }
        nextToken
      }
      einflussFaktorNummer
      einflussFaktorName
      einheit
      verantwortlicher
      createdOn
      updatedOn
      owner
    }
  }
`;
export const deleteEinflussfaktor = /* GraphQL */ `
  mutation DeleteEinflussfaktor(
    $input: DeleteEinflussfaktorInput!
    $condition: ModelEinflussfaktorConditionInput
  ) {
    deleteEinflussfaktor(input: $input, condition: $condition) {
      id
      companyID
      naechsteMessung
      aktuelleMessungID
      messDaten {
        items {
          id
          einflussfaktorID
          naechsteMessung
          isvollstaendig
          bezugsJahr
          Messpunkte
          summeMessdaten
          kostenpu
          createdOn
          updatedOn
          owner
        }
        nextToken
      }
      einflussFaktorNummer
      einflussFaktorName
      einheit
      verantwortlicher
      createdOn
      updatedOn
      owner
    }
  }
`;
export const createEinflussFaktorMessDaten = /* GraphQL */ `
  mutation CreateEinflussFaktorMessDaten(
    $input: CreateEinflussFaktorMessDatenInput!
    $condition: ModelEinflussFaktorMessDatenConditionInput
  ) {
    createEinflussFaktorMessDaten(input: $input, condition: $condition) {
      id
      einflussfaktorID
      naechsteMessung
      isvollstaendig
      bezugsJahr
      Messpunkte
      summeMessdaten
      kostenpu
      createdOn
      updatedOn
      owner
    }
  }
`;
export const updateEinflussFaktorMessDaten = /* GraphQL */ `
  mutation UpdateEinflussFaktorMessDaten(
    $input: UpdateEinflussFaktorMessDatenInput!
    $condition: ModelEinflussFaktorMessDatenConditionInput
  ) {
    updateEinflussFaktorMessDaten(input: $input, condition: $condition) {
      id
      einflussfaktorID
      naechsteMessung
      isvollstaendig
      bezugsJahr
      Messpunkte
      summeMessdaten
      kostenpu
      createdOn
      updatedOn
      owner
    }
  }
`;
export const deleteEinflussFaktorMessDaten = /* GraphQL */ `
  mutation DeleteEinflussFaktorMessDaten(
    $input: DeleteEinflussFaktorMessDatenInput!
    $condition: ModelEinflussFaktorMessDatenConditionInput
  ) {
    deleteEinflussFaktorMessDaten(input: $input, condition: $condition) {
      id
      einflussfaktorID
      naechsteMessung
      isvollstaendig
      bezugsJahr
      Messpunkte
      summeMessdaten
      kostenpu
      createdOn
      updatedOn
      owner
    }
  }
`;
export const createEnergieMessDaten = /* GraphQL */ `
  mutation CreateEnergieMessDaten(
    $input: CreateEnergieMessDatenInput!
    $condition: ModelEnergieMessDatenConditionInput
  ) {
    createEnergieMessDaten(input: $input, condition: $condition) {
      id
      prozessID
      naechsteMessung
      isvollstaendig
      bezugsJahr
      Messpunkte
      summeMessdaten
      kostenpu
      createdOn
      updatedOn
      owner
    }
  }
`;
export const updateEnergieMessDaten = /* GraphQL */ `
  mutation UpdateEnergieMessDaten(
    $input: UpdateEnergieMessDatenInput!
    $condition: ModelEnergieMessDatenConditionInput
  ) {
    updateEnergieMessDaten(input: $input, condition: $condition) {
      id
      prozessID
      naechsteMessung
      isvollstaendig
      bezugsJahr
      Messpunkte
      summeMessdaten
      kostenpu
      createdOn
      updatedOn
      owner
    }
  }
`;
export const deleteEnergieMessDaten = /* GraphQL */ `
  mutation DeleteEnergieMessDaten(
    $input: DeleteEnergieMessDatenInput!
    $condition: ModelEnergieMessDatenConditionInput
  ) {
    deleteEnergieMessDaten(input: $input, condition: $condition) {
      id
      prozessID
      naechsteMessung
      isvollstaendig
      bezugsJahr
      Messpunkte
      summeMessdaten
      kostenpu
      createdOn
      updatedOn
      owner
    }
  }
`;
export const createBaselineEnergieKennzahlen = /* GraphQL */ `
  mutation CreateBaselineEnergieKennzahlen(
    $input: CreateBaselineEnergieKennzahlenInput!
    $condition: ModelBaselineEnergieKennzahlenConditionInput
  ) {
    createBaselineEnergieKennzahlen(input: $input, condition: $condition) {
      id
      prozessID
      companyID
      name
      jahr
      needProcessing
      target
      energieVerbrauchsFunktion
      energieCoefizent
      energieIntercept
      rwert
      einflussfaktorID
      baselineEinflussFaktorMessDatenID
      baselineEnergieverbrauchsDatenID
      energieKennzahlenJahresDaten {
        items {
          id
          baselineEnergieKennzahlenID
          jahr
          einflussFaktorMessDatenID
          energieverbrauchsDatenID
          needProcessing
          normalisedFaktorMessdaten
          graphmetaDatenMonat
          durchschnittsEnergieAufwand
          createdOn
          updatedOn
          owner
        }
        nextToken
      }
      createdOn
      updatedOn
      owner
    }
  }
`;
export const updateBaselineEnergieKennzahlen = /* GraphQL */ `
  mutation UpdateBaselineEnergieKennzahlen(
    $input: UpdateBaselineEnergieKennzahlenInput!
    $condition: ModelBaselineEnergieKennzahlenConditionInput
  ) {
    updateBaselineEnergieKennzahlen(input: $input, condition: $condition) {
      id
      prozessID
      companyID
      name
      jahr
      needProcessing
      target
      energieVerbrauchsFunktion
      energieCoefizent
      energieIntercept
      rwert
      einflussfaktorID
      baselineEinflussFaktorMessDatenID
      baselineEnergieverbrauchsDatenID
      energieKennzahlenJahresDaten {
        items {
          id
          baselineEnergieKennzahlenID
          jahr
          einflussFaktorMessDatenID
          energieverbrauchsDatenID
          needProcessing
          normalisedFaktorMessdaten
          graphmetaDatenMonat
          durchschnittsEnergieAufwand
          createdOn
          updatedOn
          owner
        }
        nextToken
      }
      createdOn
      updatedOn
      owner
    }
  }
`;
export const deleteBaselineEnergieKennzahlen = /* GraphQL */ `
  mutation DeleteBaselineEnergieKennzahlen(
    $input: DeleteBaselineEnergieKennzahlenInput!
    $condition: ModelBaselineEnergieKennzahlenConditionInput
  ) {
    deleteBaselineEnergieKennzahlen(input: $input, condition: $condition) {
      id
      prozessID
      companyID
      name
      jahr
      needProcessing
      target
      energieVerbrauchsFunktion
      energieCoefizent
      energieIntercept
      rwert
      einflussfaktorID
      baselineEinflussFaktorMessDatenID
      baselineEnergieverbrauchsDatenID
      energieKennzahlenJahresDaten {
        items {
          id
          baselineEnergieKennzahlenID
          jahr
          einflussFaktorMessDatenID
          energieverbrauchsDatenID
          needProcessing
          normalisedFaktorMessdaten
          graphmetaDatenMonat
          durchschnittsEnergieAufwand
          createdOn
          updatedOn
          owner
        }
        nextToken
      }
      createdOn
      updatedOn
      owner
    }
  }
`;
export const createEnergieKennzahlenJahresDaten = /* GraphQL */ `
  mutation CreateEnergieKennzahlenJahresDaten(
    $input: CreateEnergieKennzahlenJahresDatenInput!
    $condition: ModelEnergieKennzahlenJahresDatenConditionInput
  ) {
    createEnergieKennzahlenJahresDaten(input: $input, condition: $condition) {
      id
      baselineEnergieKennzahlenID
      jahr
      einflussFaktorMessDatenID
      energieverbrauchsDatenID
      needProcessing
      normalisedFaktorMessdaten
      graphmetaDatenMonat
      durchschnittsEnergieAufwand
      createdOn
      updatedOn
      owner
    }
  }
`;
export const updateEnergieKennzahlenJahresDaten = /* GraphQL */ `
  mutation UpdateEnergieKennzahlenJahresDaten(
    $input: UpdateEnergieKennzahlenJahresDatenInput!
    $condition: ModelEnergieKennzahlenJahresDatenConditionInput
  ) {
    updateEnergieKennzahlenJahresDaten(input: $input, condition: $condition) {
      id
      baselineEnergieKennzahlenID
      jahr
      einflussFaktorMessDatenID
      energieverbrauchsDatenID
      needProcessing
      normalisedFaktorMessdaten
      graphmetaDatenMonat
      durchschnittsEnergieAufwand
      createdOn
      updatedOn
      owner
    }
  }
`;
export const deleteEnergieKennzahlenJahresDaten = /* GraphQL */ `
  mutation DeleteEnergieKennzahlenJahresDaten(
    $input: DeleteEnergieKennzahlenJahresDatenInput!
    $condition: ModelEnergieKennzahlenJahresDatenConditionInput
  ) {
    deleteEnergieKennzahlenJahresDaten(input: $input, condition: $condition) {
      id
      baselineEnergieKennzahlenID
      jahr
      einflussFaktorMessDatenID
      energieverbrauchsDatenID
      needProcessing
      normalisedFaktorMessdaten
      graphmetaDatenMonat
      durchschnittsEnergieAufwand
      createdOn
      updatedOn
      owner
    }
  }
`;
export const createEnergySource = /* GraphQL */ `
  mutation CreateEnergySource(
    $input: CreateEnergySourceInput!
    $condition: ModelEnergySourceConditionInput
  ) {
    createEnergySource(input: $input, condition: $condition) {
      id
      companyID
      name
      unit
      co2factor
      sourceDescription
      sourceUrl
      createdOn
      updatedOn
      owner
    }
  }
`;
export const updateEnergySource = /* GraphQL */ `
  mutation UpdateEnergySource(
    $input: UpdateEnergySourceInput!
    $condition: ModelEnergySourceConditionInput
  ) {
    updateEnergySource(input: $input, condition: $condition) {
      id
      companyID
      name
      unit
      co2factor
      sourceDescription
      sourceUrl
      createdOn
      updatedOn
      owner
    }
  }
`;
export const deleteEnergySource = /* GraphQL */ `
  mutation DeleteEnergySource(
    $input: DeleteEnergySourceInput!
    $condition: ModelEnergySourceConditionInput
  ) {
    deleteEnergySource(input: $input, condition: $condition) {
      id
      companyID
      name
      unit
      co2factor
      sourceDescription
      sourceUrl
      createdOn
      updatedOn
      owner
    }
  }
`;
