const NotFound404 = () => {
    return (
        <div>
            <header>
                <h1>Page Not found</h1>
            </header>
        </div>
    );
}
export default NotFound404;
