import { useHistory, withRouter } from "react-router-dom";
import { listProcessbyCompanyID } from "../../../DataBase/Prozess/ProzessAPI";
import EnpiButton from "../../../ui/EnpiButton";
import { useConstructor } from "../../../Util/ReactUtils";
import { useState } from "react";
import { useUser } from "../../../UserContext";

const SelectProcessOverview = () => {
    const { companyID } = useUser();
    const history = useHistory();
    const [loading, setLoading] = useState(true);
    const [processViewlist, setProcessViewlist] = useState([]);
    const [selectedIndex, setSelectedIndex] = useState("");

    const fetchProcessData = async(companyID) => {
        const processlist = await listProcessbyCompanyID(companyID);
        let processViewlist = [];
        for (let i = 0; i < processlist.length; i++) {
            let item = {key: i, val: processlist[i]};
            item.val.checked = false;
            processViewlist.push(item);
        }
        setProcessViewlist(processViewlist);
        setLoading(false);
    }

    useConstructor(() => {
        fetchProcessData(companyID);
    });

    const selectionChanged = (index) => {
        let item;
        if (selectedIndex !== "") {
            item = processViewlist[selectedIndex];
            item.val.checked = false;
        }
        processViewlist[index].val.checked = !processViewlist[index].val.checked;
        setSelectedIndex(index);
    }

    const navigateToNewEnergieKennzahlFlowTarget = () => {
        history.push('/EnergieKennzahl/NewEnergieKennzahlTarget/' + processViewlist[selectedIndex].val.id);
    }

    return (
        <div className="dashboard-root">
            <div className="base-page">
                <div className="base-content">
                    <div>
                        <header>
                            <h4>Verbraucher für Energiekennzahl auswählen</h4>
                        </header>
                        <br/>

                        <div className="table-responsive">
                            <table
                                className="table table-vcenter">
                                <thead>
                                <tr>
                                    <th>Verbraucher</th>
                                    <th></th>
                                </tr>
                                </thead>
                                <tbody>
                                {processViewlist.map((process, index) =>
                                    <tr key={index}>
                                        <td className="text-muted">
                                            {process.val.prozessname}
                                        </td>

                                        <td className="text-muted">
                                            <label className="form-colorinput form-colorinput-light">
                                                <input name="color" type="checkbox" value="white"
                                                        className="form-colorinput-input"
                                                        onChange={() => { selectionChanged(index) }}
                                                        checked={process.val.checked}
                                                />
                                            </label>
                                        </td>
                                    </tr>
                                )}
                                </tbody>
                            </table>
                        </div>
                        <EnpiButton onClick={navigateToNewEnergieKennzahlFlowTarget} disabled={selectedIndex === ""}>
                            Verbraucher auswählen und weiter
                        </EnpiButton>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default withRouter(SelectProcessOverview);
