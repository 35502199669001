import { EnergySource } from "../API";
import { NUMCAMP_COMPANY_ID } from "./Enpi";

export const MIXED_ENERGY_SOURCE = {
    name: '<Verschiedene>',
    co2factor: 0,
    unit: '',
} as EnergySource;

export const getEnergySource = (energySource?: EnergySource): EnergySource => {
    if (energySource) {
        return energySource;
    }
    return {
        __typename: "EnergySource",
        companyID: '',
        id: '',
        name: 'Unbekannt',
        unit: '',
        co2factor: 0,
        createdOn: '',
        updatedOn: '',
    }
}

export const isEnpiEnergySource = (energySource: EnergySource) => {
    return energySource.companyID === NUMCAMP_COMPANY_ID;
}