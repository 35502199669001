import {Component} from "react";
import {withRouter} from "react-router-dom";
import {getProcess} from "../../../DataBase/Prozess/ProzessAPI";
import {createNewTarget} from "../../../DataBase/Target/TargetAPI";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import DatePicker from "@mui/lab/DatePicker";
import TextField from "@mui/material/TextField";
import EnpiButton from "../../../ui/EnpiButton";


class TargetSelection extends Component {

    constructor(props) {
        super(props);
        this.history = props.history;
        this.processID = props.match.params.id;
        this.state = {
            regexNumbersOnly: /^[+-]?\d*\.?,?\d*$/,
            open: false,
            openBewertung: false,
            name: "",
            amount: "",
            amount_error_text: "",
            bezugsJahr: new Date().getFullYear(),
            verantwortlicher: "",
            bewertungsfaktor: "Energie",
            prozessID: props.match.params.id,
            process: {
                id: "",
                companyID: "",
                prozessname: "",
                prozessnummer: "",
                referenceJahrEnergieverbrauch: "",
                referenceJahrEnergieverbrauchID: "",
                verantwortlicher: "",
                energiekosten: ""
            }

        };
        this.fetchProcessData(this.processID);
    }

    async fetchProcessData(prozessID) {
        let processData = await getProcess(prozessID);
        this.setState({process: processData});
    }

    async saveTarget() {
        await this.save();

        this.history.push(`/EnergieKennzahl/NewEnergieKennzahlFaktor/${this.processID}/${this.state.process.companyID}/${this.state.target.id}`);
    }

    async save() {
        console.log("bevor", this);
        let newTarget = await createNewTarget(this.state);
        console.log("after", this);
        this.setState({target: newTarget});
    }

    setYear(selection) {
        if (selection) {
            this.setState({bezugsJahr: selection.getFullYear()});
        } else {
            this.setState({bezugsJahr: null});
        }
    }

    handleBewertungClose = () => {
        this.setState({openBewertung: false});
    };

    handleBewertungOpen = () => {
        this.setState({openBewertung: true});
    };

    setValue(fieldAndValue) {
        let inputfield = document.getElementsByName(Object.keys(fieldAndValue)[0])[0];
        if (this.validate(fieldAndValue[Object.keys(fieldAndValue)[0]])) {
            if (inputfield.id === "input-amount") {
                fieldAndValue[Object.keys(fieldAndValue)[0]] = this.deNormalise(fieldAndValue[Object.keys(fieldAndValue)[0]]);
            }
            this.setState(fieldAndValue);
            this.unsetErrorFor(inputfield);
        } else {
            this.setErrorFor(inputfield);
        }
    }

    validate(value) {
        console.log(value);
        return value === '' || this.state.regexNumbersOnly.test(value);
    }

    setErrorFor(input) {
        if (input.id === "input-amount") {
            this.setState({amount_error_text: "Es können nur Zahlen eingegeben werden"});
        }
    }

    unsetErrorFor(input) {
        if (input.id === "input-amount") {
            this.setState({amount_error_text: ""});
        }
    }

    deNormalise(entry) {
        if (entry === null) {


            return "";
        }
        return ("".concat(entry)).replaceAll('.', ',');
    }

    render() {

        return (
            <div className="dashboard-root">
                <div className="base-page">
                    <header>
                        <h3>Ziel für
                            Energiekennzahl {this.state.process.prozessnummer} {this.state.process.prozessname}  </h3>
                    </header>
                    <div className="base-content">

                        <div className="mb-3">
                            <TextField
                                fullWidth
                                id="input-verantwortlichkeit"
                                label="Verantwortlichkeit"
                                name="verantwortlicher"
                                InputLabelProps={{shrink: true}}
                                onChange={e => this.setState({verantwortlicher: e.target.value})}
                                value={this.state.verantwortlicher}/>
                        </div>

                        <div className="mb-3">
                            <InputLabel id="bezugsjahr-select-label">Einsparziel für das Jahr *</InputLabel>
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <DatePicker
                                    views={['year']}
                                    value={new Date().setFullYear(this.state.bezugsJahr)}
                                    minDate={new Date().setFullYear(2000)}
                                    maxDate={new Date().setFullYear((new Date().getFullYear() + 1))}
                                    onChange={(newValue) => this.setYear(newValue)}
                                    renderInput={(params) => <TextField {...params} helperText={null}/>}
                                />
                            </LocalizationProvider>
                        </div>


                        <div className="mb-3">
                            <TextField
                                fullWidth
                                id="input-amount"
                                label="Einsparziel in %"
                                name="amount"
                                error={this.state.amount_error_text !== ""}
                                helperText={this.state.amount_error_text}
                                InputLabelProps={{shrink: true}}
                                onChange={e => this.setValue({amount: e.target.value})}
                                value={this.state.amount}/>
                            <small>Error message</small>
                        </div>

                        <div className="mb-3 bewertungsfaktor">
                            <InputLabel id="bewertungsfaktor-select-label">Bewertungsfaktor</InputLabel>
                            <Select
                                labelId="bewertungsfaktor-select-label"
                                id="bewertungsfaktor-open-select"
                                className="select-minsize"
                                open={this.state.openBewertung}
                                onClose={this.handleBewertungClose}
                                onOpen={this.handleBewertungOpen}
                                value={this.state.bewertungsfaktor}
                                onChange={e => this.setState({bewertungsfaktor: e.target.value})}
                            >

                                <MenuItem value={"Energie"}>Energie</MenuItem>
                                <MenuItem value={"Emission"}>Emission</MenuItem>
                            </Select>

                        </div>
                        <EnpiButton
                            onClick={() => this.saveTarget()}
                            disabled={this.state.amount === "" || this.state.bezugsJahr === ""}>
                            Speichern und weiter
                        </EnpiButton>
                        <br/>
                        <br/>
                    </div>
                </div>
            </div>
        );
    }
}

export default withRouter(TargetSelection);
