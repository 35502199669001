import { useContext, useState } from "react";

import Amplify, {API} from "aws-amplify";
import awsconfig from "../aws-exports";

import {listCompanys} from "../graphql/queries"
import {UserContext, useUser} from "../UserContext";
import {Auth} from "@aws-amplify/auth";
import {withRouter} from "react-router-dom";
import {listBaselineEnergieKennzahlenbyCompanyID} from "../DataBase/BaselineEnergieKennzahlen/BaselineEnergieKennzahlenAPI";
import { listEnergySourcesByCompany } from "../DataBase/EnergySourceAPI";
import {getTargetbyID} from "../DataBase/Target/TargetAPI";
import KennzahlDashboardItem from "./EnergieKennzahl/KennzahlDashboardItem";
import Diagram from "./Diagram/Diagram";
import { Grid } from '@mui/material';
import { isVirtualConsumer, getReferencedConsumerNames, generateDataForVirtualConsumer } from "../Model/VirtualConsumer";
import { useConstructor } from "../Util/ReactUtils";
import { EnergySource, ListCompanysQuery, Prozess } from "../API";
import { listProcessbyCompanyID } from "../DataBase/Prozess/ProzessAPI";

Amplify.configure(awsconfig);
Auth.configure(awsconfig);

const MONTH_LABELS = ['Januar', 'Februar', 'März', 'April', 'Mai', 'Juni', 'Juli', 'August', 'September', 'Oktober', 'November', 'Dezember'];

const SORTERS = {
	alphabetically: (left, right) => {
		if (left.seriesName.toLowerCase() < right.seriesName.toLowerCase()) {
			return -1;
		} else if (left.seriesName.toLowerCase() > right.seriesName.toLowerCase()) {
			return 1;
		} else {
			return 0;
		}
	},
	'ascending-by-value': (left, right) => {
		if (left.value < right.value) {
			return -1;
		} else if (left.value > right.value) {
			return 1;
		} else {
			return 0;
		}
	},
	'descending-by-value': (left, right) => {
		if (left.value < right.value) {
			return 1;
		} else if (left.value > right.value) {
			return -1;
		} else {
			return 0;
		}
	}
};

function filterByConsumerType (processes, consumerType) {
	if (consumerType && (consumerType === 'virtual' || consumerType === 'measured')) {
		return processes.filter(
			(process) =>
				(
					consumerType === 'virtual'
					&& isVirtualConsumer(process)
				)
				|| (
					consumerType === 'measured'
					&& !isVirtualConsumer(process)
				)
		);
	} else {
		return processes;
	}
}

function groupByEnergySource (processes) {
	return processes.reduce((accumulator, process) => {
		const { energySourceID } = process;
		
		if (accumulator[energySourceID] === undefined) {
			accumulator[energySourceID] = [];
		}
		
		accumulator[energySourceID].push(process);
		return accumulator;
	}, {});
}

function sortByName (left, right) {
	if (left.name.toLowerCase() < right.name.toLowerCase()) {
		return -1;
	} else if (left.name.toLowerCase() > right.name.toLowerCase()) {
		return 1;
	} else {
		return 0;
	}
}

function parseCo2Factor (process: Prozess) {
	const co2factor = parseFloat((process.kohlendioxid || '').replace(',', '.'));
	return Number.isNaN(co2factor) ? 0 : co2factor;
}

function getReferenceName (process) {
	return '${' + process.prozessname + '}';
}

function createReferenceNameMap (values) {
	return values.reduce((accumulator, value) => {
		accumulator[getReferenceName(value)] = value;
		return accumulator;
	}, {});
}

const Dashboard = () => {
	const userContext = useContext(UserContext);
	const { companyID } = useUser();
	const [processes, setProcesses] = useState([] as Prozess[]);
    const [energySources, setEnergySources] = useState([] as EnergySource[]);
	const [years, setYears] = useState([] as string[]);
	const [selectedKennzahl, setSelectedKennzahl] = useState();
	const [kennzahlenViewlist, setKennzahlenViewlist] = useState([]);

	const findMessdaten = (process: Prozess) => {
		const { energieverbrauchsDaten } = process;
		return (energieverbrauchsDaten || {}).items || [];
	}

	const findMessdatenForYear = (process: Prozess, year: string) => {
		return findMessdaten(process).find(({ bezugsJahr }) => bezugsJahr === year);
	}

    const fetchCompanyData = async (name) => {
        let filter = {
            group: {
                eq: name
            }
        };

        try {
            const companyData = await API.graphql({
                query: listCompanys, variables: {
                    filter: filter,
                    limit: 10000
                }
            }) as { data: ListCompanysQuery };
            if (companyData.data.listCompanys === undefined || companyData.data.listCompanys.items.length === 0) {
                await Auth.signOut();
            }
            const { id, name, processes } = companyData.data.listCompanys.items[0];
			const earliestYear = (processes.items || []).reduce(
				(accumulator, { energieverbrauchsDaten = {} }) =>
					((energieverbrauchsDaten as any).items || []).reduce(
						(accumulator, { bezugsJahr }) => {
							const bezugsJahrNumber = parseInt(bezugsJahr, 10);
							return Number.isNaN(bezugsJahrNumber) ? accumulator : Math.min(accumulator, parseInt(bezugsJahr, 10));
						}, 
						accumulator
					)
				, Number.POSITIVE_INFINITY
			);
			const currentYear = new Date().getFullYear();
			const years = Array.from({ length: currentYear - earliestYear + 1 }, (element, index) => (currentYear - index).toString());
			setYears(years);

			userContext.setUser({
				name,
				companyID: id,
			});
        } catch (error) {
            console.log("Fetch Faild ", error);
        }
    }

    const getUser = async () => {
        try {
            const user = await Auth.currentAuthenticatedUser();
            const companyname = user.signInUserSession.idToken.payload['cognito:groups'][0];
            if (companyname === null || companyname === undefined || companyname === "") {
                console.log("Company not Registerd ");
                await Auth.signOut();
                return;
            }

            await fetchCompanyData(companyname);
			const energySources = await listEnergySourcesByCompany(companyID);
			const processes = await listProcessbyCompanyID(companyID, energySources);
			//listTargets()...

			for (const proc of processes) {
				if (isVirtualConsumer(proc)) {
					proc.energieverbrauchsDaten.items = await generateDataForVirtualConsumer(proc, processes, true);
				}
			}
			precalculateCO2Data(processes);
			await fetchKennzahlenData();
			setProcesses(processes);
			setEnergySources(energySources
				.filter(({ id }) => processes.some(({ energySourceID }) => energySourceID === id))
				.sort(sortByName)
			);
        } catch (error) {
            console.log("User not Allowed ", error);
            await Auth.signOut();
        }
    }
	
	const precalculateCO2Data = (processes: Prozess[]) => {
		const processMap = createReferenceNameMap(processes);
		const co2Done = {};
		
		const unprocessed = processes.filter(
			(process) => {
				const names = getReferencedConsumerNames(process.formula);				
				return !isVirtualConsumer(process) || names.every((name) => processMap[name])
			}
		);		
		let previousLength = -1;
		
		while (unprocessed.length !== previousLength) {
			previousLength = unprocessed.length;
			
			for (let i = unprocessed.length - 1; i >= 0; i--) {
				const process = unprocessed[i];
				
				if (isVirtualConsumer(process)) {
					const referencedProcessNames = getReferencedConsumerNames(process.formula);
					
					if (referencedProcessNames.every((name) => co2Done[name])) {
						const referencedProcesses = referencedProcessNames.map((name) => processMap[name]);
						const items = findMessdaten(process);

						for (const messdaten of items) {
							let summeCO2 = 0;
							const MesspunkteCO2 = Array.from({ length: 12 }, () => null);
							
							for (const referencedProcess of referencedProcesses) {
								const referencedMessdaten = findMessdatenForYear(referencedProcess, messdaten.bezugsJahr) || {} as any;
								
								summeCO2 += referencedMessdaten.summeCO2 || 0;
								
								for (let i = 0; i < (referencedMessdaten.MesspunkteCO2 || []).length && i < MesspunkteCO2.length; i++) {
									if (typeof referencedMessdaten.MesspunkteCO2[i] === 'number') {
										MesspunkteCO2[i] = (MesspunkteCO2[i] || 0) + referencedMessdaten.MesspunkteCO2[i];
									}
								}
							}
							
							(messdaten as any).summeCO2 = summeCO2;
							if (MesspunkteCO2.some((value) => value !== null)) {
								(messdaten as any).MesspunkteCO2 = MesspunkteCO2;
							}
						}
						
						co2Done[getReferenceName(process)] = true;
						unprocessed.splice(i, 1);
					}
				} else {
					const { energieverbrauchsDaten } = process;
					const items = (energieverbrauchsDaten || {}).items || [];
					const co2factor = parseCo2Factor(process);
					
					for (const messdaten of items) {
						if (typeof messdaten.summeMessdaten === 'number') {
							(messdaten as any).summeCO2 = messdaten.summeMessdaten * co2factor;
						}

						if (messdaten.Messpunkte instanceof Array) {
							(messdaten as any).MesspunkteCO2 = messdaten.Messpunkte.map((value) => typeof value === 'number' ? value * co2factor : null);
						}
					}
					
					co2Done[getReferenceName(process)] = true;
					unprocessed.splice(i, 1);
				}
			}
		}
	}

    useConstructor(() => {
        getUser();
    });

    const fetchKennzahlenData = async () => {
        let kennzahlenViewlist = [];
        let baselineslist = await listBaselineEnergieKennzahlenbyCompanyID(companyID);
		if (!baselineslist) {
			return;
		}
		for (let i = 0; i < baselineslist.length; i++) {
			if(baselineslist[i].rwert){
				//let item = {key: i, val: baselineslist[i]};
				let target = undefined;
				if (baselineslist[i].target) {
					target = await getTargetbyID(baselineslist[i].target);
				}
				const item = {key: i, val: baselineslist[i], target: target};
				kennzahlenViewlist.push(item);
			}
		}
		setKennzahlenViewlist(kennzahlenViewlist);
		if (kennzahlenViewlist && kennzahlenViewlist.length > 0) {
			setSelectedKennzahl(kennzahlenViewlist[0].val.id);
		}
    }

	return (
		<div className="dashboard-root">
			<div className="dashboard-header">
				<h2>Analytics</h2>
			</div>
			<div className="dashboard-content dashboard-diagrams">
				<Grid container rowSpacing={15} columnSpacing={{ xs: 0, lg: 15 }} className="dasboard-diagrams-container">
					<Diagram
						title="Energieverbrauch"
						energySources={energySources}
						years={years}
						allowSort={true}
						diagramType="energy-usage"
						filters={['energy-source', 'year', 'consumer-type']}
						dataProvider={(energySource, year, consumerType, sortOrder) => {
							const processesByEnergySource =
								energySource
									? processes.filter(({ energySourceID }) => energySourceID === energySource.id)
									: processes;
							const processesByConsumerType = filterByConsumerType(processesByEnergySource, consumerType);
							const diagramData = processesByConsumerType.flatMap((process) => {
								const messdaten = findMessdatenForYear(process, year);
								
								return messdaten ? [{
									seriesName: process.prozessname,
									value: messdaten.summeMessdaten || 0
								}] : [];
							})
							const sortedProcesses = sortOrder !== 'none'
								? diagramData.sort(SORTERS[sortOrder])
								: diagramData;
							
							const categories = sortedProcesses.map(({ seriesName }) => seriesName);
							const series = categories.length > 0 ? [{
								name: 'Jahr ' + year,
								data: sortedProcesses.map(({ value }) => value)
							}] : [];
							
							return {
								categories,
								series
							};
						}}
					/>
					<Diagram
						title="CO₂-Emissionen"
						energySources={energySources}
						years={years}
						allowSort={true}
						diagramType="co2"
						filters={['consumer-type-ext', 'year']}
						dataProvider={(energySource, year, consumerType, sortOrder) => {
							let diagramData = [];
							
							if (consumerType === 'energy-source') {
								const processesByEnergySource = groupByEnergySource(processes);
								
								diagramData = energySources.flatMap(({ id, name }) => {
									let summeGesamt = 0;
									let hasData = false;

									for (const process of processesByEnergySource[id] || []) {
										const { summeCO2 } = findMessdatenForYear(process, year) || {} as any;
										
										if (typeof summeCO2 === 'number') {
											hasData = true;
											summeGesamt += summeCO2;
										}
									}
									
									return hasData ? [{
										seriesName: name,
										value: summeGesamt
									}] : [];
								})
							} else {
								const processesByConsumerType = filterByConsumerType(processes, consumerType);
								diagramData = processesByConsumerType.flatMap((process) => {
									const { summeCO2 } = findMessdatenForYear(process, year) || {} as any;
									
									return typeof summeCO2 === 'number' ? [{
										seriesName: process.prozessname,
										value: summeCO2
									}] : [];
								});
							}
							
							const sortedProcesses = sortOrder !== 'none'
								? diagramData.sort(SORTERS[sortOrder])
								: diagramData;

							return {
								labels: sortedProcesses.map(({ seriesName }) => seriesName),
								series: sortedProcesses.map(({ value }) => value)
							};
						}}
					/>
					<Diagram
						title="CO₂-Emissionen pro Monat"
						energySources={energySources}
						years={years}
						allowSort={false}
						diagramType="co2-by-month"
						filters={['consumer-type-ext', 'year']}
						dataProvider={(energySource, year, consumerType) => {
							let series = [];
							
							if (consumerType === 'energy-source') {
								const processesByEnergySource = groupByEnergySource(processes);
								
								series = energySources.flatMap(({ id, name }) => {
									const data = Array.from({ length: 12 }, () => null);
									let hasData = false;
									
									for (const process of processesByEnergySource[id] || []) {
										const { MesspunkteCO2 } = findMessdatenForYear(process, year) || {} as any;
										
										if (MesspunkteCO2 && MesspunkteCO2.length > 0) {
											for (let i = 0; i < data.length; i++) {
												if (typeof MesspunkteCO2[i] === 'number') {
													hasData = true;
													data[i] = (data[i] || 0) + MesspunkteCO2[i];
												}
											}
										}
									}
									
									return hasData ? [{
										name,
										data
									}] : [];
								});
							} else {
								const processesByConsumerType = filterByConsumerType(processes, consumerType);
								
								series = processesByConsumerType.flatMap((process) => {
									const { MesspunkteCO2 } = findMessdatenForYear(process, year) || {} as any;
									
									return MesspunkteCO2 && MesspunkteCO2.length > 0 ? [{
										name: process.prozessname,
										data: MesspunkteCO2
									}] : [];
								});
							}
							
							return {
								categories: MONTH_LABELS,
								series
							};
						}}
					/>
					<Diagram
						title="CO₂-Emissionen pro Jahr"
						energySources={energySources}
						years={years}
						allowSort={false}
						diagramType="co2-by-year"
						filters={['consumer-type-ext']}
						dataProvider={(energySource, year, consumerType) => {
							const _years = Array.from(years).reverse();
							const yearToIndexMap = _years.reduce((accumulator, year, index) => {
								accumulator[year] = index;
								return accumulator;
							}, {});
							let series = [];
							
							if (consumerType === 'energy-source') {
								const processesByEnergySource = groupByEnergySource(processes);
								
								series = energySources.flatMap(({ id, name }) => {
									const data = Array.from({ length: _years.length }, () => null);
									let hasData = false;
									
									for (const process of processesByEnergySource[id] || []) {
										const items = (process.energieverbrauchsDaten || {}).items || []
										
										for (const { bezugsJahr, summeCO2 } of items) {
											const index = yearToIndexMap[bezugsJahr];
											
											if (index !== undefined && typeof summeCO2 === 'number') {
												hasData = true;
												data[index] = (data[index] || 0) + summeCO2;
											}
										}
									}
									return hasData ? [{
										name,
										data
									}] : [];
								});
							} else {
								const processesByConsumerType = filterByConsumerType(processes, consumerType);
								
								series = processesByConsumerType.flatMap((process) => {
									const items = (process.energieverbrauchsDaten || {}).items || [];
									const data = Array.from({ length: _years.length }, () => null);
									let hasData = false;
									
									for (const { bezugsJahr, summeCO2 } of items) {
										const index = yearToIndexMap[bezugsJahr];
										
										if (index !== undefined && typeof summeCO2 === 'number') {
											hasData = true;
											data[index] = (data[index] || 0) + summeCO2;
										}
									}
									
									return hasData ? [{
										name: process.prozessname,
										data
									}] : [];
								});
							}
							return {
								categories: _years,
								series
							};
						}}
					/>
				</Grid>
			</div>
			<div className="dashboard-header">
				<h2>Übersicht aller Kennzahlen mit Abweichung zum Zieljahr</h2>
			</div>
			<br/>
			{selectedKennzahl && kennzahlenViewlist.map((kennzahl, index) =>
				<div key={kennzahl.val.id} className="dashboard-content">
					<KennzahlDashboardItem baselineID={kennzahl.val.id} companyID={companyID}/>
				</div>
			)}
		</div>
	)
}

export default withRouter(Dashboard);

