const numberFormat = new Intl.NumberFormat('de-DE', {minimumFractionDigits: 2, maximumFractionDigits: 2});

export const formater = (entry) => {
    return entry === null ? "" : numberFormat.format(Number.parseFloat(entry));
}

const numberFormatEuro = new Intl.NumberFormat('de-DE', {minimumFractionDigits: 2, style: 'currency', currency: 'EUR'});

export const formaterEuro = (entry) => {
    return entry === null ? "" : numberFormatEuro.format(Number.parseFloat(entry));
}

export const formatFloat = (variable: Number) => {
    return variable !== undefined ? (variable + "").replaceAll(".", ",") : "";
}

export const isEmpty = (value: any) => value === undefined || value === null;