import { useState } from "react";
import { useHistory, withRouter } from "react-router-dom";
import { getProcess, updateProcess } from "../DataBase/Prozess/ProzessAPI";
import GoBackButton from "../Util/GoBackButton";
import TextField from "@mui/material/TextField";
import InputLabel from "@material-ui/core/InputLabel";
import Select from '@mui/material/Select';
import MenuItem from "@material-ui/core/MenuItem";
import Grid from "@material-ui/core/Grid";
import { FiTrash2 } from "react-icons/fi/index";
import * as API from "../API";
import { useConstructor } from "../Util/ReactUtils";
import { useUser } from "../UserContext";
import { listEnergySourcesByCompany } from "../DataBase/EnergySourceAPI";
import EnpiButton from "../ui/EnpiButton";
import DeleteProzessDialog from "./DeleteProzessDialog";

const Prozess = (props) => {
    const { companyID } = useUser();
    const processID = props.match.params.process;
    const [consumer, setConsumer] = useState({} as API.Prozess);
    const [energySources, setEnergySources] = useState([] as API.EnergySource[]);
    const history = useHistory();
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);

    const fetchData = async() => {
        setEnergySources(await listEnergySourcesByCompany(companyID));
        setConsumer(await getProcess(processID));
    }

    useConstructor(() => {
        fetchData();
    });

    const saveProcess = async() => {
        await updateProcess(consumer);
        history.goBack();
    }

    const updateConsumer = (k,v) => {
        setConsumer({
            ...consumer,
            [k]: v,
        });
    }

    const changeEnergietraeger = (energySourceId: string) => {
        console.log("changeEnergietraeger", energySourceId);
        const source = energySources.find((val) => val.id === energySourceId);
        console.log("source", source);
        if (source) {
            setConsumer({
                ...consumer,
                energySourceID: energySourceId,
                kohlendioxid: ''+source.co2factor,
                einheit: source.unit
            });
        }
    }

    const deleteProcess = () => {
        setDeleteDialogOpen(true);
    }

    const onProcessDeleted = () => {
        setDeleteDialogOpen(false);
        history.push('/Prozess/' + companyID);
    }

    const onDeleteDialogClosed = () => {
        setDeleteDialogOpen(false);
    }

    return (
        <div className="dashboard-root">
            <div className="base-page">
                <header>
                    <h4>Verbraucher bearbeiten
                        <FiTrash2 size={24} className="float-right" title="Prozess löschen" onClick={deleteProcess}/>
                    </h4>
                </header>
                <div className="base-content">
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={6}>
                            <div className="mb-3">
                                <TextField
                                    required
                                    fullWidth
                                    id="input-prozessname"
                                    label="Bezeichnung Verbraucher"
                                    name="prozessname"
                                    InputLabelProps={{shrink: true}}
                                    onChange={e => updateConsumer('prozessname', e.target.value)}
                                    value={consumer.prozessname || ''}/>
                            </div>

                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <div className="mb-3">
                                <TextField
                                    fullWidth
                                    id="input-verantwortlichkeit"
                                    label="Verantwortlichkeit (optional)"
                                    name="verantwortlicher"
                                    InputLabelProps={{shrink: true}}
                                    onChange={e => updateConsumer('verantwortlicher', e.target.value)}
                                    value={consumer.verantwortlicher || ''}/>
                            </div>
                        </Grid>
                    </Grid>
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={6}>
                            <div className="mb-3 energietraeger">
                                <InputLabel id="energietraeger-select-label">Energieträger</InputLabel>
                                <Select
                                    fullWidth
                                    label="Energieträger"
                                    labelId="energietraeger-select-label"
                                    id="energietraeger-open-select"
                                    className="select-minsize"
                                    value={consumer.energySourceID || ''}
                                    onChange={e => changeEnergietraeger(e.target.value)}
                                >
                                    {energySources.map((item) =>
                                        <MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>
                                    )}
                                </Select>
                            </div>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <div className="mb-3">
                                <TextField
                                    fullWidth
                                    id="input-einheit"
                                    label="Einheit Energieträger (automatisch)"
                                    disabled={true}
                                    name="einheit"
                                    InputLabelProps={{shrink: true}}
                                    value={consumer.einheit || ''}/>
                            </div>
                        </Grid>
                    </Grid>

                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={6}>
                            <div className="mb-3">
                                <TextField
                                    fullWidth
                                    id="input-kohlendioxid"
                                    label="CO2 Faktor (automatisch)"
                                    name="kohlendioxid"
                                    disabled={true}
                                    InputLabelProps={{shrink: true}}
                                    value={consumer.kohlendioxid + " kg/" + consumer.einheit || ''}/>
                            </div>
                        </Grid>
                    </Grid>
                    <EnpiButton onClick={saveProcess} disabled={!consumer.prozessname || !consumer.energySourceID}>Änderung speichern</EnpiButton>
                    <br/>
                    <br/>
                    <GoBackButton/>
                    <br/>
                </div>
            </div>
            <DeleteProzessDialog
                consumer={consumer}
                open={deleteDialogOpen}
                onDelete={onProcessDeleted}
                onClose={onDeleteDialogClosed}
            />
        </div>
    );
}

export default withRouter(Prozess);
