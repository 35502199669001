import React, {Component} from "react";
import {withRouter} from "react-router-dom";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import ProfileSettings from "./ProfileSettings";
import UnternehmenSettings from "./UnternehmenSettings";
import StandorteSettings from "./StandorteSettings";
import EnergietraegerSettings from "./EnergietraegerSettings";
import TabPanel from "@mui/lab/TabPanel";
import TabList from "@mui/lab/TabList";
import TabContext from "@mui/lab/TabContext";
import {UserContext} from "../UserContext";

class Settings extends Component {

    static contextType = UserContext;

    constructor(props) {
        super(props);
        this.companyID = props.match.params.companyID;
        this.state = {
            loading: true,
            showTab: "1",

        };
    }

    handleChange = (event, newValue) => {
        this.setState({showTab: newValue});
    };

    render() {
        return (
            <div className="dashboard-root">
                <div className="base-page">

                    <header>
                        <h3>Einstellungen</h3>
                    </header>
                    <br/>
                    <TabContext value={this.state.showTab}>
                        <Box sx={{borderBottom: 1, borderColor: 'divider'}}>
                            <TabList onChange={this.handleChange} aria-label="lab API tabs example">
                                <Tab value="1" label="Energieträger"/>
                            </TabList>
                        </Box>
                        <TabPanel value="1" index={0}>
                            <EnergietraegerSettings companyID={this.companyID}/>
                        </TabPanel>
                    </TabContext>
                </div>
            </div>
        );
    }
}

export default withRouter(Settings);
