import React, {Component} from "react";
import {FiEdit2} from "react-icons/fi";


class StandortListItem extends Component {
    constructor(props) {
        super(props);
        this.state = props.state;
        console.log(this.state);
        this.setState({open: false});
        if (props.state && props.state.value) {
            this.value = this.state.value;
        }
    }


    handleClickOpen() {
        this.setState({open: true});
    }


    render() {
        return (
            <tr>
                <td>{this.state.bezeichnung}</td>
                <td className="text-dark text-right">
                    {this.state.verantwortlichkeit}
                </td>
                <td className="text-dark text-right">
                    {this.state.subbereiche}
                </td>


                <td>
                    <FiEdit2 size={24} className="float-right" title="Standort Bearbeiten"
                             onClick={() => this.handleClickOpen()}/>
                </td>

            </tr>
        );

    }
}


export default StandortListItem;
