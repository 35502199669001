import { Button } from "@mui/material";
import { MouseEventHandler } from "react";
import { Link } from "react-router-dom";

interface EnpiButtonProps {
  children?: React.ReactNode;
  disabled?: boolean;
  link?: string;
  onClick?: MouseEventHandler<HTMLButtonElement>;
  variant?: 'text' | 'outlined' | 'contained';
}

const EnpiButton = (props: EnpiButtonProps) => {
  return (
    <Button
      className="full-size-button"
      disabled={props.disabled}
      onClick={props.onClick}
      sx={{marginTop: '1rem'}}
      variant={props.variant ?? "contained"}
    >
      {props.link
        ? <Link to={props.link}>{props.children}</Link>
        : props.children
      }
    </Button>
  );
};

export default EnpiButton;
