import { useState } from "react";
import { Link } from "react-router-dom";
import { Button } from "@mui/material";
import { useConstructor } from "../Util/ReactUtils";
import { EnergySource } from "../API";
import EnergySourceListItem from "../EnergySource/EnergySourceListItem";
import { listEnergySourcesByCompany } from "../DataBase/EnergySourceAPI";
import { NUMCAMP_COMPANY_ID } from "../Model/Enpi";

interface EnergySourceSettingsProps {
    companyID: string;
}

const EnergietraegerSettings = (props: EnergySourceSettingsProps) => {
    const [energySources, setEnergySources] = useState([] as EnergySource[]);
    useConstructor(async () => {
        const sources = await listEnergySourcesByCompany(props.companyID);
        sources.sort((a,b) => {
            if (a.companyID === NUMCAMP_COMPANY_ID && b.companyID !== NUMCAMP_COMPANY_ID) return +10;
            if (a.companyID !== NUMCAMP_COMPANY_ID && b.companyID === NUMCAMP_COMPANY_ID) return -10;
            return (a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1);
        });
        setEnergySources(sources);
    });

    return (
        <div className="dashboard-root">
            <div className="base-page">
                <div className="table-responsive">
                    <table className="table table-vcenter">
                        <thead>
                        <tr className="fixed-cell">
                            <th className="no-border">Bezeichnung</th>
                            <th className="text-right no-border">kg CO<sub>2</sub>/ Einheit</th>
                            <th className="text-right no-border">CO<sub>2</sub> Faktor</th>
                            <th className="no-border"></th>
                        </tr>
                        </thead>
                        <tbody>
                        {energySources.map((source, index) =>
                            <EnergySourceListItem key={index} energySource={source}/>
                        )}
                        </tbody>
                    </table>
                </div>
                <br/>
                <br/>                
                <Button className="new-process-button full-size-button" variant="contained">
                    <Link to={`/Settings/EnergySource/New`}>Neuen Energieträger anlegen</Link>
                </Button>
            </div>
        </div>    
    );
}

export default EnergietraegerSettings;
