import {Component} from "react";
import KennzahlenListItem from "./KennzahlenListItem";
import {withRouter} from "react-router-dom";
import {listBaselineEnergieKennzahlenbyCompanyID} from "../../DataBase/BaselineEnergieKennzahlen/BaselineEnergieKennzahlenAPI";
import {getTargetbyID} from "../../DataBase/Target/TargetAPI";
import EnpiButton from "../../ui/EnpiButton";

class KennzahlenOverview extends Component {
    constructor(props) {
        super(props);
        this.history = props.history;
        this.companyID = props.companyID;
        if (this.companyID === undefined) {
            this.companyID = props.match.params.companyID;
        }

        this.state = {
            kennzahlenViewlist: []
        };
        this.fetchKennzahlenData(this.companyID);
    }

    async fetchKennzahlenData(companyID) {
        let kennzahlenViewlist = [];
        let baselineslist = await listBaselineEnergieKennzahlenbyCompanyID(companyID);

        for (let i = 0; i < baselineslist.length; i++) {
            let item = {key: i, val: baselineslist[i]};
            let target = undefined;
            if (baselineslist[i].target) {
                target = await getTargetbyID(baselineslist[i].target);
            }
            item = {key: i, val: baselineslist[i], target: target};

            kennzahlenViewlist.push(item);
        }
        this.setState({kennzahlenViewlist: kennzahlenViewlist, loading: false});

    }


    render() {

        return (
            <div className="dashboard-root" >
                <div className="base-content">
                    <div>
                        <header>
                            <h1>Kennzahlen</h1>
                        </header>
                        <br/>
                        <div className="table-responsive">
                            <table className="table table-vcenter">
                                <thead>
                                <tr>
                                    <th>Bezeichnung</th>
                                    <th>Referenzjahr</th>
                                    <th>Vergleichsjahr</th>
                                    <th>Aktionen</th>
                                </tr>
                                </thead>
                                <tbody>
                                {this.state.kennzahlenViewlist.map((kennzahl, index) =>
                                    <KennzahlenListItem key={index} state={kennzahl}/>
                                )}
                                </tbody>
                            </table>
                        </div>
                        <br/>

                        <EnpiButton link={`/EnergieKennzahl/NewEnergieKennzahlProcess/${this.companyID}`}>
                            Neue Energiekennzahl anlegen
                        </EnpiButton>
                    </div>
                </div>
            </div>

        );
    }

}

export default withRouter(KennzahlenOverview);
