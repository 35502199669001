import { useState } from "react";
import { useHistory, withRouter } from "react-router-dom";
import BackToDashboardButton from "../Util/GoBackButton";
import { createEinflussFaktor, updateEinflussFaktor } from "../DataBase/EinflussFaktor/EinflussfaktorAPI";
import { newFaktorMessDaten } from "../DataBase/MessDaten/MessDatenAPI";
import TextField from "@mui/material/TextField";
import Grid from "@material-ui/core/Grid";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import DatePicker from "@mui/lab/DatePicker";
import EnpiButton from "../ui/EnpiButton";
import { useUser } from "../UserContext";

const NewFactor = () => {
    const { companyID } = useUser();
    const history = useHistory();
    const [state, setState] = useState({
        companyID: companyID,
        einflussFaktorNummer: "",
        einflussFaktorName: "",
        einheit: "",
        verantwortlicher: "",
        startJahr: null,
        aktuelleMessungID: null,
    });

    const saveEinflussFaktor = async() => {
        const newEinflussfaktor = await createEinflussFaktor(state);
        setState(newEinflussfaktor);

        let messdaten;
        let messdatenidsList = [];
        for (let i = Number.parseInt(state.startJahr); i <= new Date().getFullYear(); i++) {
            messdaten = await newFaktorMessDaten(newEinflussfaktor.id, "" + i);
            messdatenidsList.push(messdaten.id);
        }

        setState({...state, aktuelleMessungID: messdaten.id});
        await updateEinflussFaktor(state);

        history.push('/MessDaten/FaktorDaten/' + messdatenidsList[0] + '/showYears');
    }

    const setYear = (selection) => {
        setState({...state, startJahr: selection ? selection.getFullYear() : null});
    }

    return (
        <div>
            <div className="base-page">
                <header>
                    <h4>Neuer Einflussfaktor anlegen</h4>
                </header>
                <small>Welche Einflussfaktoren beeinflussen den CO2 und Energieverbrauch deiner Verbraucher? Relevante Einflussfaktoren erkennst du daran, dass es keinen direkten Einfluss darauf gibt. Wie z.B. die Produktionsmenge. Einflussfaktoren auf die es Einfluss gibt wie z.B. die Leerlaufdauer einer Maschine, kannst du hier auch sammeln. Sie sind aber nicht für deine Kennzahlen relevant sondern sie sind die Stellhebel wo du etwas optimieren kannst. </small>
                <div className="base-content">

                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={6}>
                            <div className="mb-3">
                                <TextField
                                    required
                                    fullWidth
                                    id="input-einflussFaktorName"
                                    label="Einflussfaktor Name"
                                    name="einflussfaktorname"
                                    InputLabelProps={{shrink: true}}
                                    onChange={e => setState({...state, einflussFaktorName: e.target.value})}
                                    value={state.einflussFaktorName}/>
                            </div>

                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <div className="mb-3">
                                <TextField
                                    fullWidth
                                    id="input-einheit"
                                    label="Einheit"
                                    name="einheit"
                                    InputLabelProps={{shrink: true}}
                                    onChange={e => setState({...state, einheit: e.target.value})}
                                    value={state.einheit}/>
                            </div>
                        </Grid>
                    </Grid>

                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={6}>
                            <div className="mb-3">
                                <FormControl className="MuiFormControl-fullWidth">
                                    <InputLabel id="bezugsjahr-select-label">Daten hinterlegen ab Jahr</InputLabel>
                                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                                        <DatePicker
                                            views={['year']}
                                            value={state.startJahr ? new Date().setFullYear(state.startJahr) : null}
                                            minDate={new Date().setFullYear(2000)}
                                            maxDate={new Date().setFullYear((new Date().getFullYear()+1))}
                                            onChange={(newValue) => setYear(newValue)}
                                            renderInput={(params) => <TextField {...params} helperText={null}/>}
                                        />
                                    </LocalizationProvider>
                                </FormControl>
                            </div>
                        </Grid>
                        <Grid item xs={12} sm={6}>

                        </Grid>
                    </Grid>
                    <EnpiButton
                        onClick={saveEinflussFaktor}
                        disabled={state.einflussFaktorName === "" || state.einheit === "" || state.startJahr === null}>
                        Messungen eintragen
                    </EnpiButton>
                    <br/>
                    <br/>
                    <BackToDashboardButton/>
                </div>
            </div>
        </div>
    );
}

export default withRouter(NewFactor);
