import React, {Component} from "react";
import {Button} from 'react-bootstrap';
import {withRouter} from "react-router-dom";

import {
    getEinflussFaktorbyID,
    listEinflussFaktorbyCompany,
    updateEinflussFaktor
} from "../../../DataBase/EinflussFaktor/EinflussfaktorAPI";
import {getProcess, updateProcess} from "../../../DataBase/Prozess/ProzessAPI";
import {
    energieMessDatenForProcess,
    faktorMessDatenForEinflussfaktorID,
    messDatenAbgeschlossen,
    neuesEnergieMessDatenYear,
    newFaktorMessDatenYear
} from "../../../DataBase/MessDaten/MessDatenAPI";
import {
    createEnergiekennzahlenBaseline,
    createEnergiekennzahlenJahresDaten, getBaselineEnergieKennzahlenById
} from "../../../DataBase/BaselineEnergieKennzahlen/BaselineEnergieKennzahlenAPI";
import {getTargetbyID} from "../../../DataBase/Target/TargetAPI";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import CircularProgress from "@material-ui/core/CircularProgress";

const delay = ms => new Promise(res => setTimeout(res, ms));

class SelectFaktorenOverview extends Component {
    constructor(props) {
        super(props);
        this.history = props.history;
        this.companyID = props.match.params.company;
        this.processID = props.match.params.id;
        this.target = props.match.params.target;
        this.energieverbrauchsDatenID = undefined;
        this.einflussFaktorMessDatenID = undefined;
        this.retry = 0;

        console.log("sometarget", this.target)
        this.state = {
            open: false,
            target: "",
            jahr: "",
            selectedIndex: "",
            faktorViewlist: [],
            energieMessDaten: [],
            einflussFaktorMessDaten: [],
            loading: true,
            possibleBaselines: []
        };


        this.fetchTarget(props.match.params.target);

        this.fetchProcessData(this.processID);
        this.fetchEinflussFaktorData(this.companyID);

    }

    async fetchTarget(targetID) {
        const target = await getTargetbyID(targetID);
        this.setState({target: target});
        console.log(this.state);
    }

    async fetchEinflussFaktorData(companyID) {

        const faktorlist = await listEinflussFaktorbyCompany(companyID);
        let faktorViewlist = [];
        for (let i = 0; i < faktorlist.length; i++) {
            let item = {key: i, val: faktorlist[i]};
            item.val.checked = false;
            faktorViewlist.push(item);
        }
        this.setState({faktorViewlist: faktorViewlist, loading: false});

    }

    async saveKennzahl() {
        this.setState({loading: true});
        this.getMessjahrIDS(this.state.jahr);
        let newBaseline;
        try {
            const kennzahl = {
                prozessID: this.processID,
                name: this.state.process.prozessname + " - " + this.state.faktorViewlist[this.state.selectedIndex].val.einflussFaktorName,
                jahr: this.state.jahr,
                companyID: this.companyID,
                target: this.state.target.id,

                einflussfaktorID: this.state.faktorViewlist[this.state.selectedIndex].val.id,
                baselineEinflussFaktorMessDatenID: this.einflussFaktorMessDatenID,
                baselineEnergieverbrauchsDatenID: this.energieverbrauchsDatenID
            }
            newBaseline = await createEnergiekennzahlenBaseline(kennzahl);

        } catch (error) {
            console.log("Fehler bei Speichern einer BaselineEnergieKennzahlen", error)
        }

        if (newBaseline && newBaseline.id) {
            await this.createEnergiekennzahlenJahresDatenVollstaendigeMessdatenJahre(newBaseline.id);

            await this.navigateWhenReady(newBaseline.id)
        } else {
            console.log("Fehler beim createEnergiekennzahlenJahresDatenVollstaendigeMessdatenJahre")
        }
    }


    async navigateWhenReady(baselineid) {
        let baselineEnergieKennzahlen = await getBaselineEnergieKennzahlenById(baselineid);
        if (this.retry > 10) {
            this.history.push('/');
        }
        if (!baselineEnergieKennzahlen.rwert) {
            this.retry++;
            await delay(2000);
            await this.navigateWhenReady(baselineid);
            return;
        }
        this.history.push('/EnergieKennzahl/AbweichungsAnalyse/' + baselineid);
    }
    //Methode die überprüft ob vollständige Jahre für Einfluss und Factor vorlieren und legt es an
    async createEnergiekennzahlenJahresDatenVollstaendigeMessdatenJahre(baselineID) {
        console.log("Target Jahr ", this.state);

        for (let i = 0; i < this.state.energieMessDaten.length; i++) {
            for (let k = 0; k < this.state.einflussFaktorMessDaten.length; k++) {
                if (this.state.energieMessDaten[i].bezugsJahr === this.state.einflussFaktorMessDaten[k].bezugsJahr) {
                    this.state.energieMessDaten[i].vollstaendig = true;
                    this.state.einflussFaktorMessDaten[k].vollstaendig = true;

                    let energieMessDaten = this.state.energieMessDaten[i];
                    let einflussFaktorMessDaten = this.state.einflussFaktorMessDaten[k];
                    let durchschnittsEnergieAufwand = undefined;

                    if (energieMessDaten.summeMessdaten && einflussFaktorMessDaten.summeMessdaten) {
                        durchschnittsEnergieAufwand = Number.parseFloat(energieMessDaten.summeMessdaten) / Number.parseFloat(einflussFaktorMessDaten.summeMessdaten);

                    }

                    try {

                        const energieKennzahlenJahresDaten = {

                            baselineEnergieKennzahlenID: baselineID,
                            jahr: this.state.energieMessDaten[i].bezugsJahr,
                            einflussFaktorMessDatenID: this.state.einflussFaktorMessDaten[k].id,
                            energieverbrauchsDatenID: this.state.energieMessDaten[i].id,
                            durchschnittsEnergieAufwand: durchschnittsEnergieAufwand

                        }
                        console.log("createEnergiekennzahlenJahresDaten ", energieKennzahlenJahresDaten);
                        await createEnergiekennzahlenJahresDaten(energieKennzahlenJahresDaten);

                    } catch (error) {
                        console.log("Fehler bei Speichern einer EnergieKennzahlenJahresDaten", error)
                    }
                }
            }
        }

        let needEnergieMessDaten = true;
        let indexenergieMessDaten;
        let indexfaktorMessDaten;
        for (let i = 0; i < this.state.energieMessDaten.length; i++) {
            if (this.state.energieMessDaten[i].bezugsJahr === this.state.target.bezugsJahr) {
                needEnergieMessDaten = false;
                indexenergieMessDaten = i;
            }
        }

        let needFaktorMessDaten = true;
        for (let k = 0; k < this.state.einflussFaktorMessDaten.length; k++) {
            if (this.state.einflussFaktorMessDaten[k].bezugsJahr === this.state.target.bezugsJahr) {
                needFaktorMessDaten = false;
                indexfaktorMessDaten = k
            }
        }

        if (needEnergieMessDaten || needFaktorMessDaten) {
            let energieMessDaten;
            let einflussFaktorMessDaten;

            if (needEnergieMessDaten) {
                energieMessDaten = await neuesEnergieMessDatenYear(this.state.energieMessDaten[0].prozessID, this.state.target.bezugsJahr);
                let process = await getProcess(this.state.energieMessDaten[0].prozessID);
                process.aktuelleMessungID = energieMessDaten.id;
                await updateProcess(process);
            }
            if (needFaktorMessDaten) {
                einflussFaktorMessDaten = await newFaktorMessDatenYear(this.state.einflussFaktorMessDaten[0].einflussfaktorID, this.state.target.bezugsJahr);
                let faktor = await getEinflussFaktorbyID(this.state.einflussFaktorMessDaten[0].einflussfaktorID);
                faktor.aktuelleMessungID = einflussFaktorMessDaten.id;
                await updateEinflussFaktor(faktor);
            }

            einflussFaktorMessDaten = einflussFaktorMessDaten || this.state.einflussFaktorMessDaten[indexfaktorMessDaten];
            energieMessDaten = energieMessDaten || this.state.energieMessDaten[indexenergieMessDaten];
            try {

                let durchschnittsEnergieAufwand = 0;

                if (energieMessDaten.summeMessdaten && einflussFaktorMessDaten.summeMessdaten) {
                    durchschnittsEnergieAufwand = Number.parseFloat(energieMessDaten.summeMessdaten) / Number.parseFloat(einflussFaktorMessDaten.summeMessdaten);
                }
                const energieKennzahlenJahresDaten = {

                    baselineEnergieKennzahlenID: baselineID,
                    jahr: this.state.target.bezugsJahr,
                    einflussFaktorMessDatenID: einflussFaktorMessDaten.id,
                    energieverbrauchsDatenID: energieMessDaten.id,
                    durchschnittsEnergieAufwand: durchschnittsEnergieAufwand

                }
                await createEnergiekennzahlenJahresDaten(energieKennzahlenJahresDaten);

            } catch (error) {
                console.log("Fehler bei Speichern einer EnergieKennzahlenJahresDaten", error)
            }


        }


    }

    async fetchallMessDaten() {
        const process = await getProcess(this.processID);
        await this.fetchEnergieMessData(process);
        await this.fetchFaktorMessDaten(this.state.faktorViewlist[this.state.selectedIndex].val.id);
        this.getMessjahrIDS(this.state.jahr);
    }

    getMessjahrIDS(jahr) {
        for (let i = 0; i < this.state.energieMessDaten.length; i++) {
            if (this.state.energieMessDaten[i].bezugsJahr === jahr) {
                this.energieverbrauchsDatenID = this.state.energieMessDaten[i].id;
            }
        }

        for (let k = 0; k < this.state.einflussFaktorMessDaten.length; k++) {
            if (this.state.einflussFaktorMessDaten[k].bezugsJahr === jahr) {
                this.einflussFaktorMessDatenID = this.state.einflussFaktorMessDaten[k].id;
            }
        }
        this.setState({jahr: jahr});

    }

    async fetchProcessData(prozessID) {
        let processData = await getProcess(prozessID);
        this.setState({process: processData});
    }


    async fetchEnergieMessData(processID) {
        let energieDatenlist = await energieMessDatenForProcess(processID);
        this.setState({energieMessDaten: energieDatenlist});

    }

    async fetchFaktorMessDaten(einflussfaktorID) {
        let einflussfaktorMessDaten = await faktorMessDatenForEinflussfaktorID(einflussfaktorID);
        this.setState({einflussFaktorMessDaten: this.markDefinedYears(einflussfaktorMessDaten)});
    }


    async selectionChanged(event) {
        let item;
        this.setState({jahr: ""});
        if (this.state.selectedIndex !== "") {
            item = this.state.faktorViewlist[this.state.selectedIndex];
            item.val.checked = false;

        }

        this.state.faktorViewlist[event].val.checked = !this.state.faktorViewlist[event].val.checked;
        this.setState({selectedIndex: event});
        await this.fetchallMessDaten();
    }

    handleClose = () => {
        this.setState({open: false});
    };

    handleOpen = () => {
        this.setState({open: true});
    };

    markDefinedYears(einflussFaktorMessDaten) {
        let possible = [];
        for (let i = 0; i < this.state.energieMessDaten.length; i++) {
            for (let k = 0; k < einflussFaktorMessDaten.length; k++) {
                if (this.state.energieMessDaten[i].bezugsJahr === einflussFaktorMessDaten[k].bezugsJahr) {
                    if (messDatenAbgeschlossen(this.state.energieMessDaten[i].Messpunkte) && messDatenAbgeschlossen(einflussFaktorMessDaten[k].Messpunkte)) {
                        this.state.energieMessDaten[i].color = "green";
                        einflussFaktorMessDaten[k].color = "green";
                        possible.push(einflussFaktorMessDaten[k].bezugsJahr);
                    }
                }
            }
        }
        this.setState({jahr: ""});
        this.setState({possibleBaselines: possible});
        return einflussFaktorMessDaten;
    }

    onChangeBaselineJahr(jahr) {
        this.setState({jahr: jahr});
    }

    render() {
        return (
            this.state.loading ?
                <div className="loading-screen">
                    <CircularProgress className="loading-icon"/>
                </div>
                :
                <div className="dashboard-root">
                    <div className="base-page">

                        <div className="base-content">
                            <div>
                                <header>
                                    <h1>Einflussfaktoren</h1>
                                </header>
                                <br/>

                                <div className="table-responsive">
                                    <table
                                        className="table table-vcenter">
                                        <thead>
                                        <tr>
                                            <th>Einflussfaktor</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {this.state.faktorViewlist.map((faktor, index) =>
                                            <tr key={index}>
                                                <td className="text-dark">
                                                    {faktor.val.einflussFaktorName}
                                                </td>

                                                <td className="text-dark">
                                                    <label className="form-colorinput form-colorinput-light">
                                                        <input name="color" type="checkbox" value="white"
                                                               className="form-colorinput-input"
                                                               onChange={() => {
                                                                   this.selectionChanged(index)
                                                               }}
                                                               checked={faktor.val.checked}
                                                        />
                                                    </label>
                                                </td>
                                            </tr>
                                        )}
                                        </tbody>
                                    </table>
                                </div>
                                <br/>
                            </div>
                        </div>
                        <div className="base-content">
                            <div className="mb-3">
                                <InputLabel id="baslienJahr-select-label">Baseline Jahr *</InputLabel>
                                <Select
                                    labelId="baslienJahr-select-label"
                                    id="baslienJahr-open-select"
                                    className="select-minsize"
                                    open={this.state.open}
                                    onClose={this.handleClose}
                                    onOpen={this.handleOpen}
                                    value={this.state.jahr}
                                    disabled={this.state.selectedIndex === ""}
                                    onChange={e => this.onChangeBaselineJahr(e.target.value)}
                                >
                                    {this.state.possibleBaselines && this.state.possibleBaselines.length > 0 ? this.state.possibleBaselines.map((item, index) =>
                                            <MenuItem style={{color: item}} key={index}
                                                      value={item}>{item}</MenuItem>)
                                        :
                                        <div>Kein mögliches Baseline Jahr gefunden.
                                            Für ein Baselinejahr müssen Vollständige Energiemessaden und Faktordaten
                                            vorhanden sein</div>
                                    }

                                </Select>
                                {this.state.possibleBaselines && this.state.possibleBaselines.length > 0 ?
                                    <div></div>
                                    :
                                    <div>{this.state.selectedIndex === "" ? "" : "Kein mögliches Baseline Jahr gefunden.\n" +
                                        "                                        Für ein Baselinejahr müssen Vollständige Energiemessaden und Faktordaten\n" +
                                        "                                        vorhanden sein"}</div>
                                }
                            </div>

                            <br/>
                            <Button className="full-size-button" onClick={() => this.saveKennzahl()}
                                    disabled={this.state.selectedIndex === "" || this.state.jahr === ""}
                                    variant="primary">EnPI anlegen</Button>{' '}

                        </div>
                    </div>

                </div>

        );
    }
}

export default withRouter(SelectFaktorenOverview);
