import {API, graphqlOperation} from "aws-amplify";
import {getEinflussfaktor, listEinflussFaktorMessDatens, listEinflussfaktors} from "../../graphql/queries";
import {
    createEinflussfaktor,
    deleteEinflussfaktor,
    deleteEinflussFaktorMessDaten, updateEinflussfaktor
} from "../../graphql/mutations";
import {
    deleteBaselineEnergieKennzahlenbyEinflussfaktorID, listBaselineEnergieKennzahlenByEinflussfaktorID,
    updateBaselineEnergieKennzahlenByState
} from "../BaselineEnergieKennzahlen/BaselineEnergieKennzahlenAPI";
import {getProcess} from "../Prozess/ProzessAPI";


export const getEinflussFaktorbyID = async (einflussFaktorID) => {
    let einflussFaktorData;
    try {
        einflussFaktorData = await API.graphql({
            query: getEinflussfaktor, variables: {
                id: einflussFaktorID
            }
        });

        return einflussFaktorData.data.getEinflussfaktor;
    } catch (error) {
        console.log("Fetch Einflussfaktor Faild ", error);
    }

}

export const listEinflussFaktorbyCompany = async (companyID) => {
    try {

        let filter = {
            companyID: {
                eq: companyID
            }
        };

        const faktorlist = await API.graphql({
            query: listEinflussfaktors, variables: {
                filter: filter,
                limit: 10000
            }
        });
        let faktorViewlist = [];
        if (faktorlist && faktorlist.data && faktorlist.data.listEinflussfaktors && faktorlist.data.listEinflussfaktors.items) {
            faktorViewlist = faktorlist.data.listEinflussfaktors.items;
        }

        return faktorViewlist;

    } catch (error) {
        console.log("Fetch Einflussfaktoren Faild ", error);
    }
}

export const updateEinflussFaktor = async (state) => {

    const oldState = await getEinflussFaktorbyID(state.id);

    try {
        const einflussFaktor = {
            id: state.id,
            companyID: state.companyID,
            prozessID: state.prozessID,
            naechsteMessung: state.naechsteMessung,
            aktuelleMessungID: state.aktuelleMessungID,
            einflussFaktorNummer: state.einflussFaktorNummer,
            einflussFaktorName: state.einflussFaktorName,
            verantwortlicher: state.verantwortlicher,
            einheit: state.einheit
        }
        const updateEinflussFaktor = await API.graphql(graphqlOperation(updateEinflussfaktor, {input: einflussFaktor}));
        if (oldState.einflussFaktorName !== state.einflussFaktorName) {

            let baselinelist = await listBaselineEnergieKennzahlenByEinflussfaktorID(state.id);

            for (let i = 0; i < baselinelist.length; i++) {
                var process = await getProcess(baselinelist[i].prozessID);
                baselinelist[i].name = process.prozessname + " - " + state.einflussFaktorName;
                await updateBaselineEnergieKennzahlenByState(baselinelist[i]);
            }

        }
        return updateEinflussFaktor.data.updateEinflussfaktor;

    } catch (error) {
        console.log("Fehler beim Speichern des Einflussfaktors", error)
    }
}


export const createEinflussFaktor = async (state) => {
    try {
        const einflussFaktor = {
            companyID: state.companyID,
            prozessID: state.prozessID,
            naechsteMessung: state.naechsteMessung,
            aktuelleMessungID: state.aktuelleMessungID,
            einflussFaktorNummer: state.einflussFaktorNummer,
            einflussFaktorName: state.einflussFaktorName,
            verantwortlicher: state.verantwortlicher,
            einheit: state.einheit
        }
        const newEinflussFaktor = await API.graphql(graphqlOperation(createEinflussfaktor, {input: einflussFaktor}));

        return newEinflussFaktor.data.createEinflussfaktor;


    } catch (error) {
        console.log("Fehler beim Speichern des Einflussfaktors", error)
    }

}


export const deleteEinflussFaktorCascade = async (einflussFaktorID) => {

    // Energie MessDaten Löschen,
    let filter = {
        einflussfaktorID: {
            eq: einflussFaktorID
        }
    };

    try {
        const faktorDatenlist = await API.graphql({
            query: listEinflussFaktorMessDatens, variables: {
                filter: filter,
                limit: 10000
            }
        });


        if (faktorDatenlist && faktorDatenlist.data && faktorDatenlist.data.listEinflussFaktorMessDatens && faktorDatenlist.data.listEinflussFaktorMessDatens.items) {
            console.log(faktorDatenlist.data.listEinflussFaktorMessDatens.items);
            for (let i = 0; i < faktorDatenlist.data.listEinflussFaktorMessDatens.items.length; i++) {

                let item = {key: i, val: faktorDatenlist.data.listEinflussFaktorMessDatens.items[i]};
                let datenInput = {

                    id: item.val.id
                }
                await API.graphql(graphqlOperation(deleteEinflussFaktorMessDaten, {input: datenInput}));
            }

        }

    } catch (error) {
        console.log("Delete EinflussFaktor Messdaten Failed ", error);
    }

    let datenInput = {

        id: einflussFaktorID
    }
    try {
        await API.graphql(graphqlOperation(deleteEinflussfaktor, {input: datenInput}));
    } catch (error) {
        console.log("Fehler bei löschen eines Einlussfaktors", error)
    }

    await deleteBaselineEnergieKennzahlenbyEinflussfaktorID(einflussFaktorID);

}
