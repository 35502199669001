import { Button } from 'react-bootstrap';
import { useHistory, withRouter } from "react-router-dom";

const GoBackButton = () => {
    const history = useHistory();

    const back = () => {
        history.goBack();
    }

    return (
        <div>
            <br/>
            <Button className="link-button" onClick={() => back()} variant="primary">Zurück</Button>
        </div>
    );
}

export default withRouter(GoBackButton);
