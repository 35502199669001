import React, {Component} from "react";
import {withRouter} from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import TextField from "@mui/material/TextField";
import {Button} from "react-bootstrap";
import {
    createNewCorporation,
    listCorporationbyCompanyID,
    updateCorporationbyState
} from "../DataBase/Corporation/CorporationAPI";

class UnternehmenSettings extends Component {

    constructor(props) {
        super(props);
        this.history = props.history;

        this.companyID = props.companyid;

        this.state = {
            company: "",
            corporationname: "",
            street: "",
            housenumber: "",
            zipcode: "",
            town: "",
            phoneNumber: "",
            mail: "",
            standorteJson: "",
            energysourcesJson: ""
        };

        this.fetchCorporation();
    }

    async fetchCorporation() {
        let profillist = await listCorporationbyCompanyID(this.companyID);
        let profile;
        if (profillist && profillist.length === 0) {
            profile = await createNewCorporation({company: this.companyID})
        } else {
            profile = profillist[0];
        }
        this.setState(profile);

    }

    async save() {
        let updatedCorporation = await updateCorporationbyState(this.state);
        this.setState(updatedCorporation);
    }

    render() {

        return (
            <div className="dashboard-root">
                <div className="base-page">
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={12}>
                            <div className="mb-3">
                                <TextField
                                    required
                                    fullWidth
                                    id="input-unternehmen"
                                    label="Name des Unternehmens"
                                    name="unternehmen"
                                    InputLabelProps={{shrink: true}}
                                    onChange={e => this.setState({corporationname: e.target.value})}
                                    value={this.state.corporationname}/>
                            </div>

                        </Grid>
                    </Grid>

                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={6}>
                            <div className="mb-3">
                                <TextField
                                    required
                                    fullWidth
                                    id="input-straße"
                                    label="Straße"
                                    name="straße"
                                    InputLabelProps={{shrink: true}}
                                    onChange={e => this.setState({street: e.target.value})}
                                    value={this.state.street}/>
                            </div>

                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <div className="mb-3">
                                <TextField
                                    fullWidth
                                    id="input-hausnummer"
                                    label="Hausnummer"
                                    name="hausnummer"
                                    InputLabelProps={{shrink: true}}
                                    onChange={e => this.setState({housenumber: e.target.value})}
                                    value={this.state.housenumber}/>
                            </div>
                        </Grid>
                    </Grid>

                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={6}>
                            <div className="mb-3">
                                <TextField
                                    required
                                    fullWidth
                                    id="input-postleitzahl"
                                    label="Postleitzahl"
                                    name="postleitzahl"
                                    InputLabelProps={{shrink: true}}
                                    onChange={e => this.setState({zipcode: e.target.value})}
                                    value={this.state.zipcode}/>
                            </div>

                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <div className="mb-3">
                                <TextField
                                    fullWidth
                                    id="input-stadt"
                                    label="Stadt"
                                    name="stadt"
                                    InputLabelProps={{shrink: true}}
                                    onChange={e => this.setState({town: e.target.value})}
                                    value={this.state.town}/>
                            </div>
                        </Grid>
                    </Grid>

                    <br/>
                    <Button className="full-size-button" onClick={() => this.save()}
                            variant="primary">Speichern</Button>{' '}
                </div>
            </div>
        );
    }
}

export default withRouter(UnternehmenSettings);
