import {API, graphqlOperation} from "aws-amplify";
import {getProzess, listEnergieMessDatens, listProzesss} from "../../graphql/queries";
import {createProzess, deleteEnergieMessDaten, deleteProzess, updateProzess} from "../../graphql/mutations";
import {
    deleteBaselineEnergieKennzahlenbyProcessID,
    listBaselineEnergieKennzahlenByProcessId,
    updateBaselineEnergieKennzahlenByState
} from "../BaselineEnergieKennzahlen/BaselineEnergieKennzahlenAPI";
import {getEinflussFaktorbyID} from "../EinflussFaktor/EinflussfaktorAPI";
import { generateVirtualConsumer } from "../../Model/VirtualConsumer";
import { CreateProzessMutation, EnergySource, GetProzessQuery, ListEnergieMessDatensQuery, ListProzesssQuery, Prozess, UpdateProzessMutation } from "../../API";
import { listEnergySourcesByCompany } from "../EnergySourceAPI";

export const getProcess = async (prozessID: string) => {
    try {
        const processData = await API.graphql({
            query: getProzess, variables: {
                id: prozessID
            }
        }) as { data: GetProzessQuery };
        return generateVirtualConsumer(processData.data.getProzess);
    } catch (error) {
        console.log("Fetch Faild ", error);
    }
    return null;
}

export const listProcessbyCompanyID = async (companyID: string, energySources?: EnergySource[]) =>  {
    try {
        let filter = {
            companyID: {
                eq: companyID
            }
        };
        const processlist = await API.graphql({
            query: listProzesss, variables: {
                filter: filter,
                limit: 10000
            }
        }) as { data: ListProzesssQuery };
        const processes = processlist.data.listProzesss.items;
        for (let i = 0; i < processes.length; i++) {
            processes[i] = await generateVirtualConsumer(processes[i], processes, energySources);
        }
        return processes;
    } catch (error) {
        console.log("Fetch Faild ", error);
    }
    return null;
}

export interface ProcessWithEnergySource {
    process: Prozess;
    energySource: EnergySource;
}

export const listProcessesWithEnergySources = async(companyID: string) => {
    const energySources = await listEnergySourcesByCompany(companyID);
    const processes = await listProcessbyCompanyID(companyID, energySources);
    if (!processes) {
        return [];
    }
    const ret = [] as ProcessWithEnergySource[];
    for (const process of processes) {
        const energySource = energySources.find((source) => source.id === process.energySourceID);
        ret.push({
            process,
            energySource,
        });
    }
    return ret;
}

export const updateProcess = async (state) => {
    const oldState = await getProcess(state.id);
    try {
        const process = {
            id: state.id,
            companyID: state.companyID,
            prozessname: state.prozessname,
            prozessnummer: state.prozessnummer,
            aktuelleMessungID: state.aktuelleMessungID,
            einheit: state.einheit,
            verantwortlicher: state.verantwortlicher,
            energiekosten: state.energiekosten,
            energietraeger: state.energietraeger,
            kohlendioxid: state.kohlendioxid,
            aktuellesTarget: state.aktuellesTarget,
            formula: state.formula,
            energySourceID: state.energySourceID,
        };

        console.log(state)

        const updateProcess = await API.graphql(
            graphqlOperation(updateProzess, { input: process })
        ) as { data: UpdateProzessMutation };

        if (oldState.prozessname !== state.prozessname) {
            let baselinelist = await listBaselineEnergieKennzahlenByProcessId(state.id);

            for (let i = 0; i < baselinelist.length; i++) {
                var einflussfaktor = await getEinflussFaktorbyID(baselinelist[i].einflussfaktorID);
                baselinelist[i].name = state.prozessname + " - " + einflussfaktor.einflussFaktorName;
                await updateBaselineEnergieKennzahlenByState(baselinelist[i]);
            }
        }
        return updateProcess.data.updateProzess;
    } catch (error) {
        console.log("Error updating Prozess", error)
    }
}

export const createProcess = async (state) => {
    try {
        const process = {
            companyID: state.companyID,
            prozessname: state.prozessname,
            prozessnummer: state.prozessnummer,
            aktuelleMessungID: state.aktuelleMessungID,
            einheit: state.einheit,
            verantwortlicher: state.verantwortlicher,
            energiekosten: state.energiekosten,
            kohlendioxid: state.kohlendioxid,
            aktuellesTarget: state.aktuellesTarget,
            formula: state.formula,
            energySourceID: state.energySourceID,
        };
        const newProcess = await API.graphql(graphqlOperation(createProzess, {
            input: process
        })) as { data: CreateProzessMutation };

        return newProcess.data.createProzess;
    } catch (error) {
        console.log("Error creating Process", error);
    }
}

export const deleteProcessCascade = async (prozessID: string) => {

    // Energie MessDaten Löschen,
    let filter = {
        prozessID: {
            eq: prozessID
        }
    };

    try {
        const energieDatenlist = await API.graphql({
            query: listEnergieMessDatens, variables: {
                filter: filter,
                limit: 10000
            }
        }) as { data: ListEnergieMessDatensQuery };

        if (energieDatenlist && energieDatenlist.data && energieDatenlist.data.listEnergieMessDatens && energieDatenlist.data.listEnergieMessDatens.items) {
            console.log(energieDatenlist.data.listEnergieMessDatens.items);
            for (let i = 0; i < energieDatenlist.data.listEnergieMessDatens.items.length; i++) {

                let item = {key: i, val: energieDatenlist.data.listEnergieMessDatens.items[i]};
                console.log("Delete", item);
                let datenInput = {

                    id: item.val.id
                }
                await API.graphql(graphqlOperation(deleteEnergieMessDaten, {input: datenInput}));
            }
        }
    } catch (error) {
        console.log("Delete Energie Messdaten Failed ", error);
    }

    let datenInput = {
        id: prozessID
    }
    try {
        await API.graphql(graphqlOperation(deleteProzess, {input: datenInput}));

    } catch (error) {
        console.log("Fehler bei löschen eines Prozess", error)
    }

    await deleteBaselineEnergieKennzahlenbyProcessID(prozessID);

}
