import { useState } from "react";
import { Link } from "react-router-dom";
import { FiTrash2 } from "react-icons/fi";
import { ProcessWithEnergySource } from "../../DataBase/Prozess/ProzessAPI";
import { isVirtualConsumer } from "../../Model/VirtualConsumer";
import DeleteProzessDialog from "../../Prozess/DeleteProzessDialog";

interface ProcessListItemProps {
    consumer: ProcessWithEnergySource;
}

const ProcessListItem = (props: ProcessListItemProps) => {
    const consumer = props.consumer;
    const isVirtual = isVirtualConsumer(consumer.process);
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);

    const handleClickOpen = () => {
        setDeleteDialogOpen(true);
    }

    const handleDeleted = () => {
        setDeleteDialogOpen(false);
        window.location.reload();
    }

    const handleDialogClosed = () => {
        setDeleteDialogOpen(false);
    }

    return (
        <tr>
            <td className="text-dark">
                <Link to={`/Prozess/DetailsProzess/${consumer.process.id}`}>{consumer.process.prozessname}</Link>
            </td>
            <td className="text-dark">
                {isVirtual ? consumer.process.formula : consumer.process.verantwortlicher}
            </td>
            <td>
                {consumer.energySource ? consumer.energySource.name : '<Verschiedene>'}
            </td>
            <td className="text-end">
                <FiTrash2 size={24} className="float-right" title="Process löschen" onClick={() => handleClickOpen()}/>
                <div>
                    <DeleteProzessDialog
                        consumer={consumer.process}
                        open={deleteDialogOpen}
                        onDelete={handleDeleted}
                        onClose={handleDialogClosed}/>
                </div>
            </td>
        </tr>
    );
}

export default ProcessListItem;
