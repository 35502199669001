import Grid from "@material-ui/core/Grid";
import {formater} from "../../../Util/helper";
import { Target } from "../../../API";

//Component to show Summe gemessen and Summer normalisiert at the Abweichungsanalyse

interface EnPiAbweichungSumProps {
    einheit: string;
    target: Target;
    summegemessen: number;
    summeNormalisiert: number;
}

const EnPiAbweichungSum = (props: EnPiAbweichungSumProps) => {
    return (
        <div className="root">
            <header>
                <h4>Jahreswerte {props.target.bezugsJahr}</h4>
            </header>
            <br/>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                    <div className="grid-contents">
                        <div className="base-content-grid">
                            <header className="border-bottom">
                                <h6 className="medium-font">Verbrauch</h6>
                                <div className="small-font">gemessen</div>
                            </header>
                            <br/>
                            <div className="font-large">
                                {formater(props.summegemessen)} {props.einheit}
                            </div>
                        </div>
                    </div>
                </Grid>

                <Grid item xs={12} sm={6}>
                    <div className="grid-contents">
                        <div className="base-content-grid">
                            <header className="border-bottom">
                                <h6 className="medium-font">Verbrauch
                                </h6>
                                <div className="small-font">berechnet
                                </div>
                            </header>
                            <br/>
                            <div className="font-large">
                                {formater(props.summeNormalisiert.toFixed(0))} {props.einheit}
                            </div>
                        </div>
                    </div>
                </Grid>
            </Grid>
        </div>
    );
}

export default EnPiAbweichungSum;
