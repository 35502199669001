import { FiEdit2, FiEye } from "react-icons/fi";
import { useHistory } from "react-router";
import { EnergySource } from "../API";
import { isEnpiEnergySource } from "../Model/EnergySource";
import { formatFloat } from "../Util/helper";

interface EnergySourceListItemProps {
    energySource: EnergySource;
}

const EnergySourceListItem = (props: EnergySourceListItemProps) => {
    const energySource = props.energySource;
    const history = useHistory();
    const isEnpiSource = isEnpiEnergySource(energySource);

    const handleEdit = () => {
        history.push(`/Settings/EnergySource/Edit/${energySource.id}`);
    }

    return (
        <tr style={isEnpiSource ? {backgroundColor: "lightyellow"} : {}}>
            <td>{energySource.name}</td>
            <td className="text-dark text-right">
                {energySource.unit}
            </td>
            <td className="text-dark text-right">
                {formatFloat(energySource.co2factor)}
            </td>
            <td>
                {!isEnpiSource && (
                    <FiEdit2 size={24} className="float-right" title="Energieträger Bearbeiten" onClick={handleEdit}/>
                )}
                {isEnpiSource && (
                    <FiEye size={24} className="float-right" title="Energieträger Anzeigen" onClick={handleEdit}/>
                )}
            </td>
        </tr>
    );
}

export default EnergySourceListItem;
