import { useState } from "react";
import { useHistory, withRouter } from "react-router-dom";
import { createProcess, getProcess, listProcessbyCompanyID, updateProcess } from "../DataBase/Prozess/ProzessAPI";
import GoBackButton from "../Util/GoBackButton";
import { Alert, AlertTitle, Button, Grid, TextField } from "@mui/material";
import { useUser } from "../UserContext";
import { isVirtualConsumer, validateFormula } from "../Model/VirtualConsumer";
import { Prozess } from "../API";
import { useConstructor } from "../Util/ReactUtils";
import EnpiButton from "../ui/EnpiButton";

const VirtualConsumerEdit = (props) => {
    const { companyID } = useUser();
    const mode = props.mode;
    const processID = props.match.params.consumer;
    const [consumer, setConsumer] = useState({} as Prozess);
    const [consumers, setConsumers] = useState([]);
    const [error, setError] = useState(null);
    const history = useHistory();

    const fetchData = async() => {
        if (mode === 'EDIT') {
            let process = await getProcess(processID);
            setConsumer(process);
        }
        const allConsumers = await listProcessbyCompanyID(companyID);
        const consumers = allConsumers.filter((v) => !isVirtualConsumer(v));
        setConsumers(consumers);
    }

    useConstructor(() => {
        fetchData();
    });

    const save = async() => {
        consumer.companyID = companyID;
        if (mode === 'NEW') {
            const consumerResult = await createProcess(consumer);
            setConsumer(consumerResult);
            history.push(`/Prozess/DetailsProzess/${consumerResult.id}`);
        } else {
            await updateProcess(consumer);
        }
    }

    const setFormula = async (formula) => {
        updateConsumer('formula', formula);
        try {
            await validateFormula(companyID, formula, consumers);
            setError(null);
        } catch (e) {
            setError({message: e.message, cause: e.cause?.message});
        }
    }

    const updateConsumer = (k,v) => {
        setConsumer({
            ...consumer,
            [k]: v,
        });
    }

    const insertReferenceIntoFormula = (str) => {
        const currentFormula = consumer.formula || '';
        setFormula(currentFormula + ' ${' + str + '}');
    }

    const titleStr = mode === 'NEW' ? 'Neuen Virtuellen Verbraucher anlegen' : 'Virtuellen Verbraucher bearbeiten';
    const saveStr = mode === 'NEW' ? 'Virtuellen Verbraucher erstellen' : 'Änderungen speichern';
    return (
        <div className="dashboard-root">
            <div className="base-page">
                <header>
                    <h4>{titleStr}</h4>
                    {/* <small>
                        Lege einen Verbraucher, energieverbrauchenden Prozess oder eine Anlage an und hinterlege
                        einen Energieträger, für den dir Messdaten zur Verfügung stehen.
                    </small> */}
                </header>
                <div className="base-content">
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={12}>
                            <div className="mb-3">
                                <TextField
                                    required
                                    fullWidth
                                    id="input-prozessname"
                                    label="Bezeichnung Verbraucher"
                                    name="prozessname"
                                    InputLabelProps={{shrink: true}}
                                    onChange={e => updateConsumer('prozessname', e.target.value)}
                                    value={consumer.prozessname || ''}/>
                            </div>
                        </Grid>
                    </Grid>
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={12}>
                            <div className="mb-3">
                                <TextField
                                    required
                                    fullWidth
                                    id="input-formula"
                                    label="Formel"
                                    name="formula"
                                    InputLabelProps={{shrink: true}}
                                    onChange={e => setFormula(e.target.value)}
                                    value={consumer.formula || ''}/>
                            </div>
                        </Grid>
                    </Grid>
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={12}>
                            {error && error.message &&
                                <Alert severity="error">
                                    {error.message} <strong>{error.cause}</strong>
                                </Alert>
                            }
                        </Grid>
                    </Grid>
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={12}>
                            <Alert severity="info" onClose={() => {}}>
                                <AlertTitle>Information zu der Formel</AlertTitle>
                                Erlaubt sind mathematische Operatoren (+,-,*,/) sowie Klammern.
                                Als Variablen können andere Verbraucher referenziert werden. 
                                Die Schreibweise ist <strong>$&#123;VerraucherName&#125;</strong>,
                                wobei "VerbraucherName" der Name eines bereits existierenden Verbrauchers sein muss.
                                <br/>
                                Beispiel: 
                                <span style={{border: '1px solid black', margin: 8, padding: 8, fontFamily: 'monospace'}}>
                                    5 * ( <strong>$&#123;Verbraucher1&#125;</strong> + <strong>$&#123;Verbraucher2&#125;</strong> )
                                </span>
                            </Alert>
                        </Grid>
                    </Grid>
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={6}>
                            
                                <h3>Verfügbare Verbraucher:</h3>
                                <div className="table-responsive">
                                    <table className="table table-vcenter">
                                        <thead>
                                            <tr>
                                                <th>Verbraucher</th>
                                                <th></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                        {consumers.map((consumer) => (
                                            <tr key={consumer.prozessname}>
                                                <td>{consumer.prozessname}</td>
                                                <td>
                                                    <Button style={{ textDecoration: 'none' }} onClick={() => insertReferenceIntoFormula(consumer.prozessname)}>
                                                        In die Formel einfügen
                                                    </Button>
                                                </td>
                                            </tr>
                                        ))}
                                        </tbody>
                                    </table>
                                </div>

                        </Grid>
                    </Grid>
                    <EnpiButton onClick={() => save()} disabled={error || consumer.prozessname === "" || consumer.formula === ""}>
                        {saveStr}
                    </EnpiButton>
                    <br/>
                    <br/>
                    <GoBackButton/>
                </div>
            </div>
        </div>
    )
}

export default withRouter(VirtualConsumerEdit);
