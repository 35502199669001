import React, {Component} from "react";
import {Button, FormControl, InputGroup} from 'react-bootstrap';
import {API, graphqlOperation} from "aws-amplify";

import {createCompany} from "../../graphql/mutations"

class Company extends Component {
    constructor(props) {
        super(props);

        this.state = {
            name: "",
            group:"",
            mail: "",
            phoneNumber: ""
        };
    }

    addCompany = async () => {
        try {
            const company =  this.state;
            console.log(company);
            const newCompany = await API.graphql(graphqlOperation(createCompany, {input: company}));
            console.log("Neue Firma", newCompany)
        } catch (error) {
            console.log("Fehler bei erstellen einer neuen Firma")
        }
    }

    render() {

        return (

            <div>
                <header>
                    <h1>Neue Firma</h1>
                </header>
                <InputGroup className="mb-3">
                    <InputGroup.Prepend>
                        <InputGroup.Text>Name</InputGroup.Text>
                    </InputGroup.Prepend>
                    <FormControl name="name" onChange={e => this.setState({ name: e.target.value})}/>
                </InputGroup>
                <InputGroup className="mb-3">
                    <InputGroup.Prepend>
                        <InputGroup.Text>Group</InputGroup.Text>
                    </InputGroup.Prepend>
                    <FormControl name="group" onChange={e => this.setState({ group: e.target.value})}/>
                </InputGroup>
                <InputGroup className="mb-3">
                    <InputGroup.Prepend>
                        <InputGroup.Text>Mail Adresse</InputGroup.Text>
                    </InputGroup.Prepend>
                    <FormControl name="mail" onChange={e => this.setState({ mail: e.target.value})}/>
                </InputGroup>

                <InputGroup className="mb-3">
                    <InputGroup.Prepend>
                        <InputGroup.Text>Telephonenummer</InputGroup.Text>
                    </InputGroup.Prepend>
                    <FormControl name="phoneNumber" onChange={e => this.setState({ phoneNumber: e.target.value})}/>
                </InputGroup>


                <br/>
                <Button onClick={() => this.addCompany()} variant="primary">Erstellen</Button>{' '}
                <br/>

            </div>

        );
    }
}

export default Company;
