import React, {Component} from "react";
import {withRouter} from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import TextField from "@mui/material/TextField";
import {Button} from "react-bootstrap";
import {
    createNewCorporation, createNewUser,
    listCorporationbyCompanyID,
    listUsersbyCorporationID, updateUserbyState
} from "../DataBase/Corporation/CorporationAPI";

class ProfileSettings extends Component {

    constructor(props) {
        super(props);
        this.history = props.history;
        this.companyID = props.companyid;

        this.state = {
            corporation: "",
            firstname: "",
            lastname: "",
            position: "",
            phoneNumber: "",
            mail: ""
        };

        this.fetchProfile();
    }

    async fetchProfile() {
        let profillist = await listCorporationbyCompanyID(this.companyID);
        let profile;
        if (profillist && profillist.length === 0) {
            profile = await createNewCorporation({company: this.companyID})
        } else {
            profile = profillist[0];
        }
        this.setState({unternehmen: profile});

        let userlist = await listUsersbyCorporationID(profile.id);
        let user;
        if (userlist && userlist.length === 0) {
            user = await createNewUser({corporation: profile.id})
        } else {
            user = userlist[0];
        }
        this.setState(user);
    }

    async save() {
        let user = await updateUserbyState(this.state);
        this.setState(user);
    }

    render() {

        return (
            <div className="dashboard-root">
                <div className="base-page">
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={6}>
                            <div className="mb-3">
                                <TextField
                                    required
                                    fullWidth
                                    id="input-vorname"
                                    label="Vorname"
                                    name="vorname"
                                    InputLabelProps={{shrink: true}}
                                    onChange={e => this.setState({firstname: e.target.value})}
                                    value={this.state.firstname}/>
                            </div>

                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <div className="mb-3">
                                <TextField
                                    fullWidth
                                    id="input-nachname"
                                    label="Nachname"
                                    name="nachname"
                                    InputLabelProps={{shrink: true}}
                                    onChange={e => this.setState({lastname: e.target.value})}
                                    value={this.state.lastname}/>
                            </div>
                        </Grid>
                    </Grid>

                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={6}>
                            <div className="mb-3">
                                <TextField
                                    required
                                    fullWidth
                                    id="input-position"
                                    label="Position (Optional)"
                                    name="position"
                                    InputLabelProps={{shrink: true}}
                                    onChange={e => this.setState({position: e.target.value})}
                                    value={this.state.position}/>
                            </div>

                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <div className="mb-3">
                                <TextField
                                    fullWidth
                                    disabled={true}
                                    id="input-telefonnummer"
                                    label="Telefonnummer (Optional)"
                                    name="telefonnummer"
                                    InputLabelProps={{shrink: true}}
                                    onChange={e => this.setState({phoneNumber: e.target.value})}
                                    value={this.state.phoneNumber}/>
                            </div>
                        </Grid>
                    </Grid>

                    <header>
                        <h6>Anmeldedaten</h6>
                    </header>
                    <br/>

                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={6}>
                            <div className="mb-3">
                                <TextField
                                    required
                                    fullWidth
                                    disabled={true}
                                    id="input-email"
                                    label="E-Mail-Adresse"
                                    name="email"
                                    InputLabelProps={{shrink: true}}
                                    onChange={e => this.setState({email: e.target.value})}
                                    value={this.state.email}/>
                            </div>

                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <div className="mb-3">
                                <TextField
                                    fullWidth
                                    disabled={true}
                                    id="input-password"
                                    label="Passwort"
                                    name="passwort"
                                    InputLabelProps={{shrink: true}}
                                    onChange={e => this.setState({passwort: e.target.value})}
                                    value={this.state.passwort}/>
                            </div>
                        </Grid>
                    </Grid>

                    <br/>
                    <Button className="full-size-button" onClick={() => this.save()}
                            variant="primary">Speichern</Button>{' '}
                </div>
            </div>
        );
    }
}

export default withRouter(ProfileSettings);
