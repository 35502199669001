import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

//Component for a list of simple Kennzahlen this components gets mediancanvas as datainput, which is a list of the simple Kennzahlen with year and einheit

interface BasicKennzahlenTableProps {
    data;
}

const BasicKennzahlenTable = (props: BasicKennzahlenTableProps) => {
    let data = props.data;
    let rows = [];
    let averagevalue = [];
    let years = [];
    let einheit = data[0][1];
    let re = einheit.split("/");
    let energieeinheit = re[0];
    let einflusseinheit = re[1];

    for (let i = 1; i <= 12; i++) {
        if (data && data[i]) {
            if (data[i][0]) {
                averagevalue.push(data[i][1]);
            }        
        }    
    }
    for (let i = 1; i <= 12; i++) {
        if (data && data[i]) {

            if (data[i][0]) {
                years.push(data[i][0]);
            }           
        }
    }

    for (let i = 0; i <= years.length-1; i++) {
        rows[i] = {
            year: years[i],
            value: averagevalue[i],
        }         
    }

    const StyledTableCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.head}`]: {
            backgroundColor: "#dddddd",

        }
    }));

    return (
        <TableContainer component={Paper}>
            <Table sx={{ minWidth: 200 }} aria-label="simple table">
                <TableHead>
                    <TableRow>
                        <TableCell align="center">Jahr</TableCell>
                        <TableCell align="center">Verbrauch in {energieeinheit} pro {einflusseinheit}</TableCell>
                    </TableRow>
                </TableHead>

                <TableBody>
                    {rows.map((row) => (
                        <TableRow
                        key={row.year}
                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                        <TableCell align="center" component="th" scope="row">{row.year}</TableCell>
                        <TableCell align="center">{row.value} {row.einheiten}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
}
export default BasicKennzahlenTable;