import {API, graphqlOperation} from "aws-amplify";
import {
    createEinflussFaktorMessDaten,
    createEnergieMessDaten,
    deleteEinflussFaktorMessDaten,
    deleteEnergieMessDaten
} from "../../graphql/mutations";
import {
    getEinflussFaktorMessDaten,
    getEnergieMessDaten,
    listEinflussFaktorMessDatens,
    listEnergieMessDatens
} from "../../graphql/queries";
import {getProcess, updateProcess} from "../Prozess/ProzessAPI";
import {
    deleteJahresDatenbyIDandJahr,
    getEnergieKennzahlenJahresDatenByEnergieMessDatenId, getEnergieKennzahlenJahresDatenByFaktorMessDatenId,
    listBaselineEnergieKennzahlenByEnergieMessDatenId,
    listBaselineEnergieKennzahlenByFaktorMessDatenId, updateEnergiekennzahlenJahresDatenByData
} from "../BaselineEnergieKennzahlen/BaselineEnergieKennzahlenAPI";
import {getEinflussFaktorbyID, updateEinflussFaktor} from "../EinflussFaktor/EinflussfaktorAPI";
import { generateDataForVirtualConsumer } from "../../Model/VirtualConsumer";
import { CreateEinflussFaktorMessDatenMutation, CreateEnergieKennzahlenJahresDatenMutation, CreateEnergieMessDatenMutation, EnergieMessDaten, GetEinflussFaktorMessDatenQuery, GetEnergieMessDatenQuery, ListEinflussFaktorMessDatensQuery, ListEnergieMessDatensQuery, Prozess } from "../../API";


export const neuesEnergieMessDatenYear = async (prozessID: string, bezugsJahr) => {
    let newEnergieMessdaten

    let datenInput = {
        prozessID: prozessID,
        bezugsJahr: bezugsJahr
    }
    try {
        newEnergieMessdaten = await API.graphql(graphqlOperation(createEnergieMessDaten, {input: datenInput}));
        return newEnergieMessdaten.data.createEnergieMessDaten;
    } catch (error) {
        console.log("Fehler bei erstellen einer neuen Referenz", error)
    }
}

export const newEnergieMessDaten = async (prozessID: string) => {
    const datenInput = {
        prozessID
    };
    try {
        const newEnergieMessdaten = await API.graphql(
            graphqlOperation(createEnergieMessDaten, {input: datenInput})
        ) as { data: CreateEnergieMessDatenMutation };
        return newEnergieMessdaten.data.createEnergieMessDaten;
    } catch (error) {
        console.log("Fehler bei erstellen einer neuen Referenz", error)
    }
}

export const getEnergieMessDatenbyId = async (energieMessDatenID) => {
    try {
        const energieMessdaten = await API.graphql({
            query: getEnergieMessDaten, variables: {
                id: energieMessDatenID
            }
        }) as { data: GetEnergieMessDatenQuery };
        return energieMessdaten.data.getEnergieMessDaten;
    } catch (error) {
        console.log("GetEnergieMessDaten Faild ", error);
    }
}

export const getFaktorMessDatenbyId = async (faktorMessDatenID: string) => {
    try {
        const faktorMessdaten = await API.graphql({
            query: getEinflussFaktorMessDaten, variables: {
                id: faktorMessDatenID
            }
        }) as { data: GetEinflussFaktorMessDatenQuery };
        return faktorMessdaten.data.getEinflussFaktorMessDaten;
    } catch (error) {
        console.log("GetEnergieMessDaten Faild ", error);
    }
}

export const newFaktorMessDaten = async (einflussfaktorID: string, bezugsJahr) => {
    const datenInput = {
        einflussfaktorID: einflussfaktorID,
        bezugsJahr: bezugsJahr
    };
    try {
        const newFaktorMessdaten = await API.graphql(graphqlOperation(createEinflussFaktorMessDaten, { input: datenInput })) as { data: CreateEinflussFaktorMessDatenMutation };
        return newFaktorMessdaten.data.createEinflussFaktorMessDaten;

    } catch (error) {
        console.log("Fehler bei erstellen einer neuen Referenz", error)
    }
}

export const newFaktorMessDatenYear = async (einflussfaktorID: string, bezugsJahr) => {
    const datenInput = {
        einflussfaktorID: einflussfaktorID,
        bezugsJahr: bezugsJahr
    };
    try {
        const newFaktorMessdaten = await API.graphql(graphqlOperation(createEinflussFaktorMessDaten, {input: datenInput})) as { data: CreateEinflussFaktorMessDatenMutation };
        return newFaktorMessdaten.data.createEinflussFaktorMessDaten;
    } catch (error) {
        console.log("Fehler bei erstellen einer neuen Referenz", error)
    }
}

export const energieMessDatenForProcess = async (prozess: Prozess) => {
    let energieMessDaten: EnergieMessDaten[];
    if (prozess.formula) {
        // do NOT use the results returned here as the data doesn't contain IDs
        // which then may crash at other places; this method pushes the data to the DB,
        // so simply request the data (see below)
        await generateDataForVirtualConsumer(prozess);
    }
    try {
        if (prozess.energieverbrauchsDaten) {
            energieMessDaten = prozess.energieverbrauchsDaten?.items;
        } else {
            let filter = {
                prozessID: {
                    eq: prozess.id
                }
            };
            const energieDatenlist = await API.graphql({
                query: listEnergieMessDatens, variables: {
                    filter: filter,
                    limit: 10000
                }
            }) as { data: ListEnergieMessDatensQuery };
            energieMessDaten = energieDatenlist.data.listEnergieMessDatens.items;
        }
    } catch (error) {
        console.log("Fetch failed", error);
    }
    return energieMessDaten.sort(compareMessDatenYear);
}

export const faktorMessDatenForEinflussfaktorID = async (einflussfaktorID: string) => {
    const filter = {
        einflussfaktorID: {
            eq: einflussfaktorID
        }
    };

    console.log(filter)
    try {
        const faktorDatenlist = await API.graphql({
            query: listEinflussFaktorMessDatens, variables: {
                filter: filter,
                limit: 10000
            }
        }) as { data: ListEinflussFaktorMessDatensQuery };

        const faktorMessDatenlist = faktorDatenlist?.data?.listEinflussFaktorMessDatens?.items;
        return faktorMessDatenlist.sort(compareMessDatenYear);
    } catch (error) {
        console.log("Fetch failed", error);
    }
}

export const deleteEnergieMessdatenByID = async (energieMessDatenID: string) => {

    let baseline = await listBaselineEnergieKennzahlenByEnergieMessDatenId(energieMessDatenID);

    if (baseline && baseline.length > 0) {
        return;
    }

    let jahresDaten = await getEnergieKennzahlenJahresDatenByEnergieMessDatenId(energieMessDatenID);

    for (let i = 0; i < jahresDaten.length; i++) {
        await deleteJahresDatenbyIDandJahr(jahresDaten[i].id, jahresDaten[i].jahr);
    }

    let messdaten = await getEnergieMessDatenbyId(energieMessDatenID);
    let process = await getProcess(messdaten.prozessID);
    let messdatenlist = await energieMessDatenForProcess(process);

    if (messdatenlist.length === 1 || messdatenlist.length === 0) {
        process.aktuelleMessungID = undefined;
    } else if (messdatenlist[messdatenlist.length - 1].id === energieMessDatenID) {
        process.aktuelleMessungID = messdatenlist[messdatenlist.length - 2].id
    } else {
        process.aktuelleMessungID = messdatenlist[messdatenlist.length - 1].id
    }

    await updateProcess(process);

    let datenInput = {
        id: energieMessDatenID
    }
    console.log("MessDatenDelete");
    await API.graphql(graphqlOperation(deleteEnergieMessDaten, {input: datenInput}));

}

export const messDatenSatus = (messdatenArray) => {
    if (messdatenArray) {
        let msg = ["Januar", "Februar", "März", "April", "Mai", "Juni", "Juli", "August", "September", "Oktober", "November", "Dezember"]
        for (let i = 0; i < messdatenArray.length; i++) {
            if (messdatenArray[i] === null || messdatenArray[i] === "") {
                return "Nächste Messung im " + msg[i]
            }
        }
        return "Abgeschlossen"
    } else {
        return "Keine Messdaten"
    }
}

export const messDatenAbgeschlossen = (messdatenArray) => {
    if (messdatenArray) {
        for (let i = 0; i < messdatenArray.length; i++) {
            if (messdatenArray[i] === null || messdatenArray[i] === "") {
                return false
            }
        }

        return true
    } else {
        return false
    }
}

export const deleteFaktorMessdatenByID = async (faktorMessDatenID) => {

    let baseline = await listBaselineEnergieKennzahlenByFaktorMessDatenId(faktorMessDatenID);

    if (baseline && baseline.length > 0) {
        return;
    }


    let jahresDaten = await getEnergieKennzahlenJahresDatenByFaktorMessDatenId(faktorMessDatenID);

    for (let i = 0; i < jahresDaten.length; i++) {
        await deleteJahresDatenbyIDandJahr(jahresDaten[i].id, jahresDaten[i].jahr);
    }

    let messdaten = await getFaktorMessDatenbyId(faktorMessDatenID);

    let faktor = await getEinflussFaktorbyID(messdaten.einflussfaktorID);


    let messdatenlist = await faktorMessDatenForEinflussfaktorID(faktor.id);

    if (messdatenlist.length === 1 || messdatenlist.length === 0) {
        faktor.aktuelleMessungID = undefined;
    } else if (messdatenlist[messdatenlist.length - 1].id === faktorMessDatenID) {
        faktor.aktuelleMessungID = messdatenlist[messdatenlist.length - 2].id
    } else {
        faktor.aktuelleMessungID = messdatenlist[messdatenlist.length - 1].id
    }

    await updateEinflussFaktor(faktor);


    let datenInput = {

        id: faktorMessDatenID
    }

    await API.graphql(graphqlOperation(deleteEinflussFaktorMessDaten, {input: datenInput}));

}


function compareMessDatenYear(itemA, itemB) {
    if (itemA.bezugsJahr && itemB.bezugsJahr) {
        return itemA.bezugsJahr > itemB.bezugsJahr ? 1 : -1;
    } else if (itemA.bezugsJahr) {
        return 1;
    } else {
        return -1
    }
}

//Here is the space to include the calculation of the monthly durschnittsData

export const UpdateJahresDatenbyEnergieMessdatenID = async (energieMessDatenID: string) => {
    let jahresDaten = await getEnergieKennzahlenJahresDatenByEnergieMessDatenId(energieMessDatenID);
    if (jahresDaten && jahresDaten.length > 0) {
        const energieMessDaten = await getEnergieMessDatenbyId(energieMessDatenID);
        for (let i = 0; i < jahresDaten.length; i++) {
            let einflussFaktorMessDaten = await getFaktorMessDatenbyId(jahresDaten[i].einflussFaktorMessDatenID);
            if (einflussFaktorMessDaten && einflussFaktorMessDaten.summeMessdaten && energieMessDaten && energieMessDaten.summeMessdaten) {
                jahresDaten[i].durchschnittsEnergieAufwand = energieMessDaten.summeMessdaten / einflussFaktorMessDaten.summeMessdaten;
                await updateEnergiekennzahlenJahresDatenByData(jahresDaten[i]);
            }
        }
    }
}

export const UpdateJahresDatenbyFaktorMessdatenID = async (faktorMessDatenID) => {
    let jahresDaten = await getEnergieKennzahlenJahresDatenByFaktorMessDatenId(faktorMessDatenID);
    if (jahresDaten && jahresDaten.length > 0) {
        let einflussFaktorMessDaten = await getFaktorMessDatenbyId(faktorMessDatenID);
        for (let i = 0; i < jahresDaten.length; i++) {
            const energieMessDaten = await getEnergieMessDatenbyId(jahresDaten[i].energieverbrauchsDatenID);

            if (einflussFaktorMessDaten && einflussFaktorMessDaten.summeMessdaten && energieMessDaten && energieMessDaten.summeMessdaten) {
                jahresDaten[i].durchschnittsEnergieAufwand = energieMessDaten.summeMessdaten / einflussFaktorMessDaten.summeMessdaten;
                await updateEnergiekennzahlenJahresDatenByData(jahresDaten[i]);
            }
        }
    }
}





