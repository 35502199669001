/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getCompany = /* GraphQL */ `
  query GetCompany($id: ID!) {
    getCompany(id: $id) {
      id
      group
      processes {
        items {
          id
          companyID
          naechsteMessung
          aktuelleMessungID
          aktuellesTarget
          targets {
            id
            name
            amount
            bewertungsfaktor
            savings
            bezugsJahr
            verantwortlicher
            prozessID
            createdOn
            updatedOn
            owner
          }
          energieverbrauchsDaten {
            items {
              id
              prozessID
              naechsteMessung
              isvollstaendig
              bezugsJahr
              Messpunkte
              summeMessdaten
              kostenpu
              createdOn
              updatedOn
              owner
            }
            nextToken
          }
          energieKennzahlen {
            items {
              id
              prozessID
              companyID
              name
              jahr
              needProcessing
              target
              energieVerbrauchsFunktion
              energieCoefizent
              energieIntercept
              rwert
              einflussfaktorID
              baselineEinflussFaktorMessDatenID
              baselineEnergieverbrauchsDatenID
              energieKennzahlenJahresDaten {
                nextToken
              }
              createdOn
              updatedOn
              owner
            }
            nextToken
          }
          prozessnummer
          prozessname
          einheit
          verantwortlicher
          energietraeger
          energiekosten
          kohlendioxid
          formula
          energySourceID
          createdOn
          updatedOn
          owner
        }
        nextToken
      }
      name
      mail
      phoneNumber
      createdOn
      updatedOn
    }
  }
`;
export const listCompanys = /* GraphQL */ `
  query ListCompanys(
    $filter: ModelCompanyFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCompanys(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        group
        processes {
          items {
            id
            companyID
            naechsteMessung
            aktuelleMessungID
            aktuellesTarget
            targets {
              id
              name
              amount
              bewertungsfaktor
              savings
              bezugsJahr
              verantwortlicher
              prozessID
              createdOn
              updatedOn
              owner
            }
            energieverbrauchsDaten {
              items {
                id
                prozessID
                naechsteMessung
                isvollstaendig
                bezugsJahr
                Messpunkte
                summeMessdaten
                kostenpu
                createdOn
                updatedOn
                owner
              }
              nextToken
            }
            energieKennzahlen {
              items {
                id
                prozessID
                companyID
                name
                jahr
                needProcessing
                target
                energieVerbrauchsFunktion
                energieCoefizent
                energieIntercept
                rwert
                einflussfaktorID
                baselineEinflussFaktorMessDatenID
                baselineEnergieverbrauchsDatenID
                createdOn
                updatedOn
                owner
              }
              nextToken
            }
            prozessnummer
            prozessname
            einheit
            verantwortlicher
            energietraeger
            energiekosten
            kohlendioxid
            formula
            energySourceID
            createdOn
            updatedOn
            owner
          }
          nextToken
        }
        name
        mail
        phoneNumber
        createdOn
        updatedOn
      }
      nextToken
    }
  }
`;
export const getCorporation = /* GraphQL */ `
  query GetCorporation($id: ID!) {
    getCorporation(id: $id) {
      id
      company
      corporationname
      street
      housenumber
      zipcode
      town
      phoneNumber
      mail
      standorteJson
      energysourcesJson
      createdOn
      updatedOn
      owner
    }
  }
`;
export const listCorporations = /* GraphQL */ `
  query ListCorporations(
    $filter: ModelCorporationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCorporations(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        company
        corporationname
        street
        housenumber
        zipcode
        town
        phoneNumber
        mail
        standorteJson
        energysourcesJson
        createdOn
        updatedOn
        owner
      }
      nextToken
    }
  }
`;
export const getUser = /* GraphQL */ `
  query GetUser($id: ID!) {
    getUser(id: $id) {
      id
      corporation
      firstname
      lastname
      position
      phoneNumber
      mail
      createdOn
      updatedOn
      owner
    }
  }
`;
export const listUsers = /* GraphQL */ `
  query ListUsers(
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        corporation
        firstname
        lastname
        position
        phoneNumber
        mail
        createdOn
        updatedOn
        owner
      }
      nextToken
    }
  }
`;
export const getTarget = /* GraphQL */ `
  query GetTarget($id: ID!) {
    getTarget(id: $id) {
      id
      name
      amount
      bewertungsfaktor
      savings
      bezugsJahr
      verantwortlicher
      prozessID
      createdOn
      updatedOn
      owner
    }
  }
`;
export const listTargets = /* GraphQL */ `
  query ListTargets(
    $filter: ModelTargetFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTargets(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        amount
        bewertungsfaktor
        savings
        bezugsJahr
        verantwortlicher
        prozessID
        createdOn
        updatedOn
        owner
      }
      nextToken
    }
  }
`;
export const getProzess = /* GraphQL */ `
  query GetProzess($id: ID!) {
    getProzess(id: $id) {
      id
      companyID
      naechsteMessung
      aktuelleMessungID
      aktuellesTarget
      targets {
        id
        name
        amount
        bewertungsfaktor
        savings
        bezugsJahr
        verantwortlicher
        prozessID
        createdOn
        updatedOn
        owner
      }
      energieverbrauchsDaten {
        items {
          id
          prozessID
          naechsteMessung
          isvollstaendig
          bezugsJahr
          Messpunkte
          summeMessdaten
          kostenpu
          createdOn
          updatedOn
          owner
        }
        nextToken
      }
      energieKennzahlen {
        items {
          id
          prozessID
          companyID
          name
          jahr
          needProcessing
          target
          energieVerbrauchsFunktion
          energieCoefizent
          energieIntercept
          rwert
          einflussfaktorID
          baselineEinflussFaktorMessDatenID
          baselineEnergieverbrauchsDatenID
          energieKennzahlenJahresDaten {
            items {
              id
              baselineEnergieKennzahlenID
              jahr
              einflussFaktorMessDatenID
              energieverbrauchsDatenID
              needProcessing
              normalisedFaktorMessdaten
              graphmetaDatenMonat
              durchschnittsEnergieAufwand
              createdOn
              updatedOn
              owner
            }
            nextToken
          }
          createdOn
          updatedOn
          owner
        }
        nextToken
      }
      prozessnummer
      prozessname
      einheit
      verantwortlicher
      energietraeger
      energiekosten
      kohlendioxid
      formula
      energySourceID
      createdOn
      updatedOn
      owner
    }
  }
`;
export const listProzesss = /* GraphQL */ `
  query ListProzesss(
    $filter: ModelProzessFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listProzesss(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        companyID
        naechsteMessung
        aktuelleMessungID
        aktuellesTarget
        targets {
          id
          name
          amount
          bewertungsfaktor
          savings
          bezugsJahr
          verantwortlicher
          prozessID
          createdOn
          updatedOn
          owner
        }
        energieverbrauchsDaten {
          items {
            id
            prozessID
            naechsteMessung
            isvollstaendig
            bezugsJahr
            Messpunkte
            summeMessdaten
            kostenpu
            createdOn
            updatedOn
            owner
          }
          nextToken
        }
        energieKennzahlen {
          items {
            id
            prozessID
            companyID
            name
            jahr
            needProcessing
            target
            energieVerbrauchsFunktion
            energieCoefizent
            energieIntercept
            rwert
            einflussfaktorID
            baselineEinflussFaktorMessDatenID
            baselineEnergieverbrauchsDatenID
            energieKennzahlenJahresDaten {
              items {
                id
                baselineEnergieKennzahlenID
                jahr
                einflussFaktorMessDatenID
                energieverbrauchsDatenID
                needProcessing
                normalisedFaktorMessdaten
                graphmetaDatenMonat
                durchschnittsEnergieAufwand
                createdOn
                updatedOn
                owner
              }
              nextToken
            }
            createdOn
            updatedOn
            owner
          }
          nextToken
        }
        prozessnummer
        prozessname
        einheit
        verantwortlicher
        energietraeger
        energiekosten
        kohlendioxid
        formula
        energySourceID
        createdOn
        updatedOn
        owner
      }
      nextToken
    }
  }
`;
export const getEinflussfaktor = /* GraphQL */ `
  query GetEinflussfaktor($id: ID!) {
    getEinflussfaktor(id: $id) {
      id
      companyID
      naechsteMessung
      aktuelleMessungID
      messDaten {
        items {
          id
          einflussfaktorID
          naechsteMessung
          isvollstaendig
          bezugsJahr
          Messpunkte
          summeMessdaten
          kostenpu
          createdOn
          updatedOn
          owner
        }
        nextToken
      }
      einflussFaktorNummer
      einflussFaktorName
      einheit
      verantwortlicher
      createdOn
      updatedOn
      owner
    }
  }
`;
export const listEinflussfaktors = /* GraphQL */ `
  query ListEinflussfaktors(
    $filter: ModelEinflussfaktorFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listEinflussfaktors(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        companyID
        naechsteMessung
        aktuelleMessungID
        messDaten {
          items {
            id
            einflussfaktorID
            naechsteMessung
            isvollstaendig
            bezugsJahr
            Messpunkte
            summeMessdaten
            kostenpu
            createdOn
            updatedOn
            owner
          }
          nextToken
        }
        einflussFaktorNummer
        einflussFaktorName
        einheit
        verantwortlicher
        createdOn
        updatedOn
        owner
      }
      nextToken
    }
  }
`;
export const getEinflussFaktorMessDaten = /* GraphQL */ `
  query GetEinflussFaktorMessDaten($id: ID!) {
    getEinflussFaktorMessDaten(id: $id) {
      id
      einflussfaktorID
      naechsteMessung
      isvollstaendig
      bezugsJahr
      Messpunkte
      summeMessdaten
      kostenpu
      createdOn
      updatedOn
      owner
    }
  }
`;
export const listEinflussFaktorMessDatens = /* GraphQL */ `
  query ListEinflussFaktorMessDatens(
    $filter: ModelEinflussFaktorMessDatenFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listEinflussFaktorMessDatens(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        einflussfaktorID
        naechsteMessung
        isvollstaendig
        bezugsJahr
        Messpunkte
        summeMessdaten
        kostenpu
        createdOn
        updatedOn
        owner
      }
      nextToken
    }
  }
`;
export const getEnergieMessDaten = /* GraphQL */ `
  query GetEnergieMessDaten($id: ID!) {
    getEnergieMessDaten(id: $id) {
      id
      prozessID
      naechsteMessung
      isvollstaendig
      bezugsJahr
      Messpunkte
      summeMessdaten
      kostenpu
      createdOn
      updatedOn
      owner
    }
  }
`;
export const listEnergieMessDatens = /* GraphQL */ `
  query ListEnergieMessDatens(
    $filter: ModelEnergieMessDatenFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listEnergieMessDatens(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        prozessID
        naechsteMessung
        isvollstaendig
        bezugsJahr
        Messpunkte
        summeMessdaten
        kostenpu
        createdOn
        updatedOn
        owner
      }
      nextToken
    }
  }
`;
export const getBaselineEnergieKennzahlen = /* GraphQL */ `
  query GetBaselineEnergieKennzahlen($id: ID!) {
    getBaselineEnergieKennzahlen(id: $id) {
      id
      prozessID
      companyID
      name
      jahr
      needProcessing
      target
      energieVerbrauchsFunktion
      energieCoefizent
      energieIntercept
      rwert
      einflussfaktorID
      baselineEinflussFaktorMessDatenID
      baselineEnergieverbrauchsDatenID
      energieKennzahlenJahresDaten {
        items {
          id
          baselineEnergieKennzahlenID
          jahr
          einflussFaktorMessDatenID
          energieverbrauchsDatenID
          needProcessing
          normalisedFaktorMessdaten
          graphmetaDatenMonat
          durchschnittsEnergieAufwand
          createdOn
          updatedOn
          owner
        }
        nextToken
      }
      createdOn
      updatedOn
      owner
    }
  }
`;
export const listBaselineEnergieKennzahlens = /* GraphQL */ `
  query ListBaselineEnergieKennzahlens(
    $filter: ModelBaselineEnergieKennzahlenFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listBaselineEnergieKennzahlens(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        prozessID
        companyID
        name
        jahr
        needProcessing
        target
        energieVerbrauchsFunktion
        energieCoefizent
        energieIntercept
        rwert
        einflussfaktorID
        baselineEinflussFaktorMessDatenID
        baselineEnergieverbrauchsDatenID
        energieKennzahlenJahresDaten {
          items {
            id
            baselineEnergieKennzahlenID
            jahr
            einflussFaktorMessDatenID
            energieverbrauchsDatenID
            needProcessing
            normalisedFaktorMessdaten
            graphmetaDatenMonat
            durchschnittsEnergieAufwand
            createdOn
            updatedOn
            owner
          }
          nextToken
        }
        createdOn
        updatedOn
        owner
      }
      nextToken
    }
  }
`;
export const getEnergieKennzahlenJahresDaten = /* GraphQL */ `
  query GetEnergieKennzahlenJahresDaten($id: ID!, $jahr: ID!) {
    getEnergieKennzahlenJahresDaten(id: $id, jahr: $jahr) {
      id
      baselineEnergieKennzahlenID
      jahr
      einflussFaktorMessDatenID
      energieverbrauchsDatenID
      needProcessing
      normalisedFaktorMessdaten
      graphmetaDatenMonat
      durchschnittsEnergieAufwand
      createdOn
      updatedOn
      owner
    }
  }
`;
export const listEnergieKennzahlenJahresDatens = /* GraphQL */ `
  query ListEnergieKennzahlenJahresDatens(
    $id: ID
    $jahr: ModelIDKeyConditionInput
    $filter: ModelEnergieKennzahlenJahresDatenFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listEnergieKennzahlenJahresDatens(
      id: $id
      jahr: $jahr
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        baselineEnergieKennzahlenID
        jahr
        einflussFaktorMessDatenID
        energieverbrauchsDatenID
        needProcessing
        normalisedFaktorMessdaten
        graphmetaDatenMonat
        durchschnittsEnergieAufwand
        createdOn
        updatedOn
        owner
      }
      nextToken
    }
  }
`;
export const getEnergySource = /* GraphQL */ `
  query GetEnergySource($id: ID!) {
    getEnergySource(id: $id) {
      id
      companyID
      name
      unit
      co2factor
      sourceDescription
      sourceUrl
      createdOn
      updatedOn
      owner
    }
  }
`;
export const listEnergySources = /* GraphQL */ `
  query ListEnergySources(
    $filter: ModelEnergySourceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listEnergySources(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        companyID
        name
        unit
        co2factor
        sourceDescription
        sourceUrl
        createdOn
        updatedOn
        owner
      }
      nextToken
    }
  }
`;
