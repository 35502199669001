import Grid from "@material-ui/core/Grid";
import { BaselineEnergieKennzahlen, EnergySource, Prozess, Target } from "../../../API";
import { formater } from "../../../Util/helper";

interface EnPiAbweichungZielProps {
    abweichungProzentualAbsolute: number;
    abweichungZiel: number;
    aktuelleAbweichung: number;
    baseline: BaselineEnergieKennzahlen;
    bisherigeErsparnis: number;
    einflussFaktorMessDaten;
    energieMessDaten;
    energySource: EnergySource;
    process: Prozess;
    target: Target;
}

const EnPiAbweichungZiel = (props: EnPiAbweichungZielProps) => {
    const levelOfSuccessDone = [
        "🤔 Der Energievebrauch zum Referenzjahr ist gestiegen.",
        "🧐 Der Energievebrauch ist zum Referenzjahr ist bisher unverändert.",
        "😃 Gegenüber dem Referenzjahr wurde gespart.",
        "🎉 Das gesetzte Ziel wurde erreicht.",
        "🚀 Das gesetzte Ziel wurde übertroffen!",
        "🚀 Das gesetzte Ziel wurde übertroffen!"
    ];
    const levelOfSuccessInProgress = [
        "🤔 Der Energievebrauch ist zum Referenzjahr gestiegen.",
        "🧐 Der Energievebrauch ist zum Referenzjahr bisher unverändert.",
        "😉 Es gibt eine positive Tendenz, zur Zielerreichung braucht es weitere Einsparungen.",
        "😃 Die Tendenz zum erreichen des gesetzten Einsparungsziels ist gut.",
        "😎 Die Tendenz das Einsparziel zu übertreffen ist gut.",
        "🤩 Die Tendenz ist sehr gut, das gesetzte Ziel zu übertreffen."
    ];

    let text = "";
    let levelofSuccess = 0;
    const target = Number.parseFloat(props.target.amount);
    console.log("::EnPiAbweichungZiel::", props.einflussFaktorMessDaten, props.energieMessDaten);
    const abgeschlossen = "Abgeschlossen" === props.einflussFaktorMessDaten[1].status && "Abgeschlossen" === props.energieMessDaten[1].status;
    let abweichung = props.abweichungProzentualAbsolute;
    if (abweichung === 0) {
        levelofSuccess = 1;
    }
    if (abweichung < 0 && Math.abs(abweichung) < target) {
        levelofSuccess = 2;
    }
    if (abweichung < 0 && Math.abs(abweichung) === target) {
        levelofSuccess = 3;
    }
    if (abweichung < 0 && Math.abs(abweichung) > target) {
        levelofSuccess = 4;
    }
    if (abweichung < 0 && Math.abs(abweichung) > (target * 2)) {
        levelofSuccess = 5;
    }

    if (abgeschlossen) {
        text = levelOfSuccessDone[levelofSuccess];
    } else {
        text = levelOfSuccessInProgress[levelofSuccess];
    }
    const amountNumber = Number.parseFloat(props.target.amount) * -1;
    return (
        <div className="root">
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <div className="grid-contents">
                        <div className="base-content-grid">

                            <header className="border-bottom">
                                <h6 className="medium-font">Zielerreichung {props.target.bezugsJahr} gegenüber
                                    Referenzjahr {props.baseline.jahr}
                                </h6>
                            </header>
                            <br/>
                            <div
                                className={props.abweichungProzentualAbsolute > 0 ? "bad-result font-large" : "good-result font-large"}>
                                {text}
                                <div className={((Number.parseFloat(props.target.amount) * -1) - props.abweichungProzentualAbsolute) < 0 ? "bad-result font-large" : "good-result font-large"}>
                                    {(amountNumber - props.abweichungProzentualAbsolute) < 0 ? "Weitere ".concat(formater(Math.abs(amountNumber - props.abweichungProzentualAbsolute).toFixed(2))) + " % sind noch offen." : "Es wurden zusätzlich ".concat(formater((amountNumber - props.abweichungProzentualAbsolute).toFixed(2))) + " % gespart."}
                                </div>
                            </div>
                        </div>
                    </div>
                </Grid>
            </Grid>

            <header>
                <h4>{props.abweichungProzentualAbsolute > 0 ? "Bisherige Mehrverbräuche" : "Bisherigen Einsparungen"}</h4>
            </header>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={3}>
                    <div className="grid-contents">
                        <div className="base-content-grid">
                            <header className="border-bottom">
                                <h6 className="medium-font">Verbrauch</h6>
                                <div className="small-font">berechnet</div>
                            </header>
                            <br/>
                            <div className="font-large">
                                {formater(props.aktuelleAbweichung)} {props.process.einheit}
                            </div>
                        </div>
                    </div>
                </Grid>
                <Grid item xs={12} sm={3}>
                    <div className="grid-contents">
                        <div className="base-content-grid">
                            <header className="border-bottom">
                                <h6 className="medium-font">Prozentual</h6>
                                <div className="small-font">berechnet</div>
                            </header>
                            <br/>
                            <div className="font-large">
                                {formater((props.abweichungProzentualAbsolute))} %
                            </div>
                        </div>
                    </div>
                </Grid>
                <Grid item xs={12} sm={3}>
                    <div className="grid-contents">
                        <div className="base-content-grid">
                            <header className="border-bottom">
                                <h6 className="medium-font">Kosten</h6>
                                <div className="small-font">berechnet</div>
                            </header>
                            <br/>
                            <div className="font-large">
                                {formater((Number.parseFloat(props.energieMessDaten[1].kostenpu) * props.aktuelleAbweichung))} €
                            </div>
                        </div>
                    </div>
                </Grid>
                <Grid item xs={12} sm={3}>
                    <div className="grid-contents">
                        <div className="base-content-grid">
                            <header className="border-bottom">
                                <h6 className="medium-font">CO<sub>2</sub></h6>
                                <div className="small-font">berechnet</div>
                            </header>
                            <br/>
                            <div className="font-large">
                                {formater(props.energySource.co2factor * props.aktuelleAbweichung)} kg
                            </div>
                        </div>
                    </div>
                </Grid>
            </Grid>

            <header>
                <h4>Einsparziel</h4>
            </header>

            <Grid container spacing={2}>
                <Grid item xs={12} sm={3}>
                    <div className="grid-contents">
                        <div className="base-content-grid">
                            <header className="border-bottom">
                                <h6 className="medium-font">Verbrauch</h6>
                                <div className="small-font">berechnet</div>
                            </header>
                            <br/>
                            <div className="font-large">
                                {formater(props.bisherigeErsparnis)} {props.process.einheit}
                            </div>
                        </div>
                    </div>
                </Grid>
                <Grid item xs={12} sm={3}>
                    <div className="grid-contents">
                        <div className="base-content-grid">
                            <header className="border-bottom">
                                <h6 className="medium-font">Prozentual</h6>
                                <div className="small-font">berechnet</div>
                            </header>
                            <br/>
                            <div className="font-large">{formater((-props.target.amount))} %</div>
                        </div>
                    </div>
                </Grid>
                <Grid item xs={12} sm={3}>
                    <div className="grid-contents">
                        <div className="base-content-grid">
                            <header className="border-bottom">
                                <h6 className="medium-font">Kosten</h6>
                                <div className="small-font">berechnet</div>
                            </header>
                            <br/>
                            <div className="font-large">
                                {formater((Number.parseFloat(props.energieMessDaten[1].kostenpu) * props.bisherigeErsparnis))} €
                            </div>
                        </div>
                    </div>
                </Grid>
                <Grid item xs={12} sm={3}>
                    <div className="grid-contents">
                        <div className="base-content-grid">
                            <header className="border-bottom">
                                <h6 className="medium-font">CO<sub>2</sub></h6>
                                <div className="small-font">berechnet</div>
                            </header>
                            <br/>
                            <div className="font-large">
                                {formater(props.energySource.co2factor * props.bisherigeErsparnis)} kg
                            </div>
                        </div>
                    </div>
                </Grid>
            </Grid>

            <header>
                <h4>Abweichung</h4>
            </header>

            <Grid container spacing={2}>
                <Grid item xs={12} sm={3}>
                    <div className="grid-contents">
                        <div className="base-content-grid">
                            <header className="border-bottom">
                                <h6 className="medium-font">Verbrauch</h6>
                                <div className="small-font">berechnet</div>
                            </header>
                            <br/>
                            <div className="font-large">
                                {formater(props.abweichungZiel)} {props.process.einheit}
                            </div>
                        </div>
                    </div>
                </Grid>
                <Grid item xs={12} sm={3}>
                    <div className="grid-contents">
                        <div className="base-content-grid">
                            <header className="border-bottom">
                                <h6 className="medium-font">Prozentual</h6>
                                <div className="small-font">berechnet</div>
                            </header>
                            <br/>
                            <div className="font-large">
                                {formater((Number.parseFloat(props.target.amount) + props.abweichungProzentualAbsolute))} %
                            </div>
                        </div>
                    </div>
                </Grid>
                <Grid item xs={12} sm={3}>
                    <div className="grid-contents">
                        <div className="base-content-grid">
                            <header className="border-bottom">
                                <h6 className="medium-font">Kosten</h6>
                                <div className="small-font">berechnet</div>
                            </header>
                            <br/>
                            <div className="font-large">
                                {formater((Number.parseFloat(props.energieMessDaten[1].kostenpu) * props.abweichungZiel))} €
                            </div>
                        </div>
                    </div>
                </Grid>
                <Grid item xs={12} sm={3}>
                    <div className="grid-contents">
                        <div className="base-content-grid">
                            <header className="border-bottom">
                                <h6 className="medium-font">CO<sub>2</sub></h6>
                                <div className="small-font">berechnet</div>
                            </header>
                            <br/>
                            <div className="font-large">
                                {formater(props.energySource.co2factor * props.abweichungZiel)} kg
                            </div>
                        </div>
                    </div>
                </Grid>
            </Grid>
        </div>
    );
}

export default EnPiAbweichungZiel;