import { useUser } from "../UserContext";
import { NavLink } from "react-router-dom";
import { withRouter } from "react-router-dom";
import { Auth } from "@aws-amplify/auth";
import { PackageIcon, ProjectIcon, ThumbsupIcon, RocketIcon, SignOutIcon, GearIcon } from '@primer/octicons-react';

const NavigationItems = () => {
    const { companyID } = useUser();

    const logout = async() => {
        console.log("Test");
        await Auth.signOut();
    }

    const EnpiNavItem = ({title, to, icon}) => {
        return (
            <li className="nav-item">
                <NavLink className="nav-link" exact to={to}>{icon}<span className="nav-link-title">{title}</span>
                </NavLink>
            </li>
        );
    }

    return (
        <div className="max-width">
            <ul className="navbar-nav">
                <EnpiNavItem
                    title="Dashboard"
                    to={"/"}
                    icon={<ProjectIcon/>}
                />
                <EnpiNavItem
                    title="Verbraucher"
                    to={`/Prozess/${companyID}`}
                    icon={<PackageIcon/>}
                />
                <EnpiNavItem
                    title="Einflussfaktoren"
                    to={`/Factor/${companyID}`} 
                    icon={<RocketIcon/>}
                />
                <EnpiNavItem
                    title="Kennzahlen"
                    to={`/EnergieKennzahl/${companyID}`}
                    icon={<ThumbsupIcon/>}
                />
                <EnpiNavItem
                    title="Einstellungen"
                    to={`/Settings/${companyID}`}
                    icon={<GearIcon/>}
                />
                <li className="nav-item logout">
                    <a className="logout-link nav-link" href="/" onClick={logout}>
                        <SignOutIcon/>
                        <span className="nav-link-title">Ausloggen</span>
                    </a>
                </li>
            </ul>
        </div>
    );
}

export default withRouter(NavigationItems);
