import React, {Component} from "react";
import {withRouter} from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import TextField from "@mui/material/TextField";
import {Button} from "react-bootstrap";
import {
    createNewCorporation,
    listCorporationbyCompanyID,
    updateCorporationbyState
} from "../DataBase/Corporation/CorporationAPI";

import StandortListItem from "./StandortListItem";

class StandorteSettings extends Component {

    constructor(props) {
        super(props);
        this.history = props.history;

        this.companyID = props.companyid;

        this.state = {
            mode: "list",
            standorte: [],
            bezeichnung: "",
            verantwortlichkeit: "",
            bezeichnungsub: "",
            subbereiche: []
        };

        this.fetchCorporation();
    }

    async fetchCorporation() {
        let profillist = await listCorporationbyCompanyID(this.companyID);
        let profile;
        if (profillist && profillist.length === 0) {
            profile = await createNewCorporation({company: this.companyID})
        } else {
            profile = profillist[0];
        }
        this.setState({unternehmen: profile});
        console.log(this);
        if (JSON.parse(profile.standorteJson)) {
            this.setState({standorte: JSON.parse(profile.standorteJson)})
        }

        console.log(this);
    }

    save() {

    }

    async saveNewStandort() {
        let standorte = this.state.standorte;
        standorte.push({
            bezeichnung: this.state.bezeichnung,
            verantwortlichkeit: this.state.verantwortlichkeit,
            subbereiche: this.state.bezeichnungsub
        });
        let updateStandort = this.state.unternehmen;
        updateStandort.standorteJson = JSON.stringify(standorte);
        let updatedCorporation = await updateCorporationbyState(updateStandort);
        this.setState({unternehmen: updatedCorporation});
        this.setState({
            mode: "list",
            bezeichnung: "",
            verantwortlichkeit: "",
            bezeichnungsub: "",
            subbereiche: []
        });
    }

    createStandort() {
        this.setState({mode: "create"});

    }

    render() {

        return (
            this.state.mode === "list" && this.state.standorte && this.state.standorte.length > 0 ?
                <div className="dashboard-root">
                    <div className="base-page">

                        <div className="table-responsive">
                            <table
                                className="table table-vcenter">
                                <thead>
                                <tr className="fixed-cell">
                                    <th className="no-border">Bezeichnung</th>
                                    <th className="text-right no-border">Verantwortlichkeit</th>
                                    <th className="text-right no-border">Sub-Bereich</th>
                                    <th className="no-border"></th>
                                </tr>
                                </thead>
                                <tbody>
                                {this.state.standorte.map((standort, index) =>
                                    <StandortListItem key={index} state={standort}/>
                                )}
                                </tbody>
                            </table>
                        </div>
                        <br/>

                        <br/>
                        <Button className="full-size-button" onClick={() => this.createStandort()}
                                variant="primary">Standort/Bereich hinzufügen</Button>{' '}
                    </div>
                </div>

                : this.state.mode === "list" ?
                <div>
                    <header>
                        <h4 className="style-kursiv">Du hast noch keinen Standort/Bereich angelegt</h4>
                    </header>
                    <br/>
                    <br/>
                    <br/>
                    <Button className="full-size-button" onClick={() => this.createStandort()}
                            variant="primary">Standort/Bereich hinzufügen</Button>{' '}
                </div>
                :
                <div className="dashboard-root">
                    <div className="base-page">
                        <Grid container spacing={3}>
                            <Grid item xs={12} sm={6}>
                                <div className="mb-3">
                                    <TextField
                                        required
                                        fullWidth
                                        id="input-bezeichnung"
                                        label="Bezeichnung"
                                        name="bezeichnung"
                                        InputLabelProps={{shrink: true}}
                                        onChange={e => this.setState({bezeichnung: e.target.value})}
                                        value={this.state.bezeichnung}/>
                                </div>

                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <div className="mb-3">
                                    <TextField
                                        fullWidth
                                        id="input-verantwortlichkeit"
                                        label="Verantwortlichkeit (Optional)"
                                        name="verantwortlichkeit"
                                        InputLabelProps={{shrink: true}}
                                        onChange={e => this.setState({verantwortlichkeit: e.target.value})}
                                        value={this.state.verantwortlichkeit}/>
                                </div>
                            </Grid>
                        </Grid>

                        <header>
                            <h6>Sub-Bereiche hinzufügen</h6>
                        </header>
                        <br/>

                        <Grid container spacing={3}>
                            <Grid item xs={12} sm={6}>
                                <div className="mb-3">
                                    <TextField
                                        required
                                        fullWidth
                                        id="input-bezeichnungsub"
                                        label="Bezeichnung"
                                        name="bezeichnungsub"
                                        InputLabelProps={{shrink: true}}
                                        onChange={e => this.setState({bezeichnungsub: e.target.value})}
                                        value={this.state.bezeichnungsub}/>
                                </div>

                            </Grid>
                            <Grid item xs={12} sm={6}>

                            </Grid>
                        </Grid>


                        <br/>
                        <Button className="full-size-button" onClick={() => this.saveNewStandort()}
                                variant="primary">Speichern</Button>{' '}
                    </div>
                </div>
        );
    }
}

export default withRouter(StandorteSettings);
