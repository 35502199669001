import { createContext, useContext, useEffect, useState } from "react";

interface User {
    name: string;
    companyID: string;
}

interface UserContextProps {
    user: User;
    setUser: React.Dispatch<React.SetStateAction<User>>;
}

export const UserContext = createContext<UserContextProps | undefined>(undefined);

export const UserProvider = ({ children }) => {
    const [user, setUser] = useState<User>(() => {
        const context = localStorage.getItem('context');
        const ctxObject = JSON.parse(context);
        if (ctxObject) {
            return ctxObject.state;
        }
        return {
            name: '',
            companyID: '',
        };
    });

    useEffect(() => {
        if (user && user.name !== '') {
            localStorage.setItem('context', JSON.stringify({ state: user }));
        }
    }, [user]);

    return (
        <UserContext.Provider value={{ user, setUser }}>
            {children}
        </UserContext.Provider>
    );
};

export const useUser = () => useContext(UserContext).user;