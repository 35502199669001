import { useContext, useState } from "react";
import { useHistory, withRouter } from "react-router-dom";
import { createProcess, updateProcess } from "../DataBase/Prozess/ProzessAPI";
import { neuesEnergieMessDatenYear } from "../DataBase/MessDaten/MessDatenAPI";
import TextField from "@mui/material/TextField";
import Grid from "@material-ui/core/Grid";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@mui/material/FormControl";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import DatePicker from "@mui/lab/DatePicker";
import GoBackButton from "../Util/GoBackButton";
import { EnergySource, Prozess } from "../API";
import { listEnergySourcesByCompany } from "../DataBase/EnergySourceAPI";
import { UserContext, useUser } from "../UserContext";
import { useConstructor } from "../Util/ReactUtils";
import EnpiButton from "../ui/EnpiButton";

const NewProzess = () => {
    const { companyID } = useUser();
    const [consumer, setConsumer] = useState({companyID} as Prozess);
    const [energySources, setEnergySources] = useState([] as EnergySource[]);
    const [startJahr, setStartJahr] = useState(0);
    const history = useHistory();

    useConstructor(async () => {
        const energySources = await listEnergySourcesByCompany(companyID);
        setEnergySources(energySources);
    });

    const updateConsumer = (k, v) => {
        console.log("updateConsumer", k, v);
        setConsumer({
            ...consumer,
            [k]: v,
        });
    }

    const saveProcess = async () => {
        let process = await createProcess(consumer);
        setConsumer(process);
        let messdaten;
        let messdatenidsList = [];
        for (let i = startJahr; i <= new Date().getFullYear(); i++) {
            messdaten = await neuesEnergieMessDatenYear(process.id, "" + i);
            messdatenidsList.push(messdaten.id);
        }
        updateConsumer('aktuelleMessungID', messdaten.id);
        await _updateProcess();
        history.push('/MessDaten/EnergieDaten/' + messdatenidsList[0] + '/showYears');
    }

    const _updateProcess = async() => {
        await updateProcess(consumer);
    }

    const changeEnergietraeger = (energySourceId: string) => {
        const source = energySources.find((val) => val.id === energySourceId);
        console.log("changeEnergietraeger", source);
        if (source) {
            setConsumer({
                ...consumer,
                energySourceID: energySourceId,
                kohlendioxid: ''+source.co2factor,
                einheit: source.unit
            });
        }
    }

    const setYear = (selection) => {
        if(selection) {
            setStartJahr(selection.getFullYear());
        } else {
            setStartJahr(null);
        }
    }

    return (
        <div className="dashboard-root">
            <div className="base-page">
                <header>
                    <h4>Neuer Verbraucher anlegen
                    </h4>
                    <small>Lege einen Verbraucher, energieverbrauchenden Prozess oder eine Anlage an und hinterlege
                        einen Energieträger, für den dir Messdaten zur Verfügung stehen.</small>
                </header>
                <div className="base-content">
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={6}>
                            <div className="mb-3">
                                <TextField
                                    required
                                    fullWidth
                                    id="input-prozessname"
                                    label="Bezeichnung Verbraucher"
                                    name="prozessname"
                                    InputLabelProps={{shrink: true}}
                                    onChange={e => updateConsumer('prozessname', e.target.value)}
                                    value={consumer.prozessname || ''}/>
                            </div>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <div className="mb-3">
                                <TextField
                                    fullWidth
                                    id="input-verantwortlichkeit"
                                    label="Verantwortlichkeit (optional)"
                                    name="verantwortlicher"
                                    InputLabelProps={{shrink: true}}
                                    onChange={e => updateConsumer('verantwortlicher', e.target.value)}
                                    value={consumer.verantwortlicher || ''}/>
                            </div>
                        </Grid>
                    </Grid>


                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={6}>
                            <div className="mb-3">
                                <InputLabel required id="energietraeger-select-label">Energieträger</InputLabel>
                                <Select
                                    required
                                    fullWidth
                                    label="Energieträger"
                                    labelId="energietraeger-select-label"
                                    id="energietraeger-open-select"
                                    /*className="select-minsize"*/
                                    value={consumer.energySourceID || ''}
                                    onChange={e => changeEnergietraeger(e.target.value)}
                                >
                                    {energySources && energySources.map((item) =>
                                        <MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>
                                    )}
                                </Select>
                            </div>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <div className="mb-3">
                                <TextField
                                    fullWidth
                                    id="input-einheit"
                                    label="Einheit Energieträger (automatisch)"
                                    disabled={true}
                                    name="einheit"
                                    InputLabelProps={{shrink: true}}
                                    value={consumer.einheit}
                                />
                            </div>
                        </Grid>
                    </Grid>

                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={6}>
                            <div className="mb-3">
                                <TextField
                                    fullWidth
                                    id="input-kohlendioxid"
                                    label="CO2 Faktor (automatisch)"
                                    name="kohlendioxid"
                                    disabled={true}
                                    InputLabelProps={{shrink: true}}
                                    value={consumer.kohlendioxid && `${consumer.kohlendioxid} kg/${consumer.einheit}`}/>
                            </div>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <div className="mb-3">
                                <FormControl className="MuiFormControl-fullWidth">
                                    <InputLabel id="bezugsjahr-select-label">Daten hinterlegen ab Jahr</InputLabel>
                                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                                        <DatePicker
                                            views={['year']}
                                            value={startJahr ? new Date().setFullYear(startJahr) : null}
                                            minDate={new Date().setFullYear(2000)}
                                            maxDate={new Date().setFullYear((new Date().getFullYear()+1))}
                                            onChange={(newValue) => setYear(newValue)}
                                            renderInput={(params) => <TextField {...params} helperText={null}/>}
                                        />
                                    </LocalizationProvider>
                                </FormControl>
                            </div>

                        </Grid>
                    </Grid>
                    <EnpiButton
                        onClick={saveProcess}
                        disabled={!consumer.prozessname || !consumer.energySourceID || !startJahr}>
                        Messdaten eintragen
                    </EnpiButton>
                    <br/>
                    <br/>
                    <GoBackButton/>
                    <br/>
                </div>
            </div>
        </div>
    );
}

export default withRouter(NewProzess);
