import React, {Component} from "react";
import {Button} from 'react-bootstrap';
import {API, graphqlOperation} from "aws-amplify";
import {
    updateEinflussFaktorMessDaten,
    updateEnergieMessDaten
} from "../graphql/mutations";

import {withRouter} from "react-router-dom";
import {
    getEinflussFaktorMessDaten,
    getEnergieMessDaten
} from "../graphql/queries";
import {getProcess, updateProcess} from "../DataBase/Prozess/ProzessAPI";
import {getEinflussFaktorbyID, updateEinflussFaktor} from "../DataBase/EinflussFaktor/EinflussfaktorAPI";
import {BsReverseLayoutTextSidebarReverse} from "react-icons/bs";
import {
    energieMessDatenForProcess,
    faktorMessDatenForEinflussfaktorID,
    newEnergieMessDaten,
    newFaktorMessDaten,
    UpdateJahresDatenbyEnergieMessdatenID,
    UpdateJahresDatenbyFaktorMessdatenID,
} from "../DataBase/MessDaten/MessDatenAPI";

import {
    createEnergieKennzahlenforNewYearFaktor,
    createEnergieKennzahlenforNewYearProcess
} from "../DataBase/BaselineEnergieKennzahlen/BaselineEnergieKennzahlenAPI";

import TextField from '@mui/material/TextField';

import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";

import Grid from "@material-ui/core/Grid";
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import CircularProgress from "@material-ui/core/CircularProgress";
import { getEnergySource } from "../Model/EnergySource";
import { getEnergySourceById } from "../DataBase/EnergySourceAPI";
import EnpiButton from "../ui/EnpiButton";

class MessDaten extends Component {

    constructor(props) {
        super(props);
        console.log(this);
        this.history = props.history;
        this.type = props.match.params.type;
        this.setType(this.type);
        this.referenceID = props.match.params.id;
        this.canGoBack = props.match.params.canNavigateBack || false;

        this.state = {
            loading: true,
            open: false,
            dateDisabled: true,
            tableData: "",
            showYears: "showYears" === props.match.params.canNavigateBack,
            messdatenJahre: null,
            showDialog: false,
            regexNumbersOnly: /^[+-]?\d*\.?,?\d*$/,
            referenceID: this.referenceID,
            errortext_parser: "",
            bezugsJahr: "",
            kostenpu: "",
            januar: "",
            februar: "",
            maerz: "",
            april: "",
            mai: "",
            juni: "",
            juli: "",
            august: "",
            september: "",
            oktober: "",
            november: "",
            dezember: ""
        };

        this.fetchReferenzData(this.referenceID);
    }


    fetchReferenzData = async (referenceID) => {
        let referenzData;
        if (this.isEnergieMode()) {
            try {
                referenzData = await API.graphql({
                    query: getEnergieMessDaten, variables: {
                        id: referenceID
                    }
                });
            } catch (error) {
                console.log("Fetch Faild ", error);
            }

            if (referenzData.data && referenzData.data.getEnergieMessDaten && referenzData.data.getEnergieMessDaten.Messpunkte != null) {
                this.mapMonate(referenzData.data.getEnergieMessDaten.Messpunkte);
            }
            this.setState(referenzData.data.getEnergieMessDaten);
            let process = await getProcess(referenzData.data.getEnergieMessDaten.prozessID);
            this.setState({process: process});
            const energySource = await getEnergySourceById(process.energySourceID);
            this.setState({energySource: energySource});

            if (this.state.showYears) {
                let responselist = await energieMessDatenForProcess(process);
                let energiMessDatenlist = [];
                for (let i = 0; i < responselist.length; i++) {
                    // if (responselist[i].bezugsJahr !== this.state.bezugsJahr) {
                    energiMessDatenlist.push(responselist[i]);
                    //  }
                }
                this.setState({messdatenJahre: energiMessDatenlist})
            }

        } else {
            try {
                referenzData = await API.graphql({
                    query: getEinflussFaktorMessDaten, variables: {
                        id: referenceID
                    }
                });
            } catch (error) {
                console.log("Fetch Faild ", error);
            }
            if (referenzData.data.getEinflussFaktorMessDaten && referenzData.data.getEinflussFaktorMessDaten.Messpunkte != null) {
                this.mapMonate(referenzData.data.getEinflussFaktorMessDaten.Messpunkte);
            }
            this.setState(referenzData.data.getEinflussFaktorMessDaten);
            let einflussfaktor = await getEinflussFaktorbyID(referenzData.data.getEinflussFaktorMessDaten.einflussfaktorID);
            this.setState({einflussfaktor: einflussfaktor});

            if (this.state.showYears) {
                let responselist = await faktorMessDatenForEinflussfaktorID(einflussfaktor.id);
                let energiMessDatenlist = [];
                for (let i = 0; i < responselist.length; i++) {
                    // if (responselist[i].bezugsJahr !== this.state.bezugsJahr) {
                    energiMessDatenlist.push(responselist[i]);
                    //  }
                }
                this.setState({messdatenJahre: energiMessDatenlist})
            }

        }
        this.setState({dateDisabled: !!this.state.bezugsJahr});
        this.setState({loading: false});
    }

    mapMonate(Messpunkte) {
        this.setState({januar: this.deNormalise(Messpunkte[0])});
        this.setState({februar: this.deNormalise(Messpunkte[1])});
        this.setState({maerz: this.deNormalise(Messpunkte[2])});
        this.setState({april: this.deNormalise(Messpunkte[3])});
        this.setState({mai: this.deNormalise(Messpunkte[4])});
        this.setState({juni: this.deNormalise(Messpunkte[5])});
        this.setState({juli: this.deNormalise(Messpunkte[6])});
        this.setState({august: this.deNormalise(Messpunkte[7])});
        this.setState({september: this.deNormalise(Messpunkte[8])});
        this.setState({oktober: this.deNormalise(Messpunkte[9])});
        this.setState({november: this.deNormalise(Messpunkte[10])});
        this.setState({dezember: this.deNormalise(Messpunkte[11])});
    }


    setType(type) {
        if (type === "EnergieDaten") {
            this.headerText = "Messdaten für Energieträger  "
            this.mode = "Energie";
        } else {
            this.headerText = "Messdaten für EinflussFaktor : "
            this.mode = "EinflussFaktor";
        }

    }

    isEnergieMode() {
        return "Energie" === this.mode;
    }

    setValue(fieldAndValue) {
        let inputfield = document.getElementsByName(Object.keys(fieldAndValue)[0])[0];
        if (this.validate(fieldAndValue[Object.keys(fieldAndValue)[0]])) {
            this.setState(fieldAndValue);
            this.unsetErrorFor(inputfield);
        } else {
            this.setErrorFor(inputfield);
        }
    }

    validate(value) {
        return value === '' || this.state.regexNumbersOnly.test(value);
    }

    setErrorFor(input) {
        const formControl = input.parentElement.parentElement.parentElement;
        const small = formControl.querySelector('small');
        formControl.className = 'mb-3 error';
        small.innerText = "Es können nur Zahlen eingegeben werden";
    }

    unsetErrorFor(input) {
        const formControl = input.parentElement.parentElement.parentElement;
        formControl.className = 'mb-3';
    }

    async saveAndGotoProcessorFaktor() {
        await this.saveReferenz();

        if ("true" === this.canGoBack) {
            this.history.goBack();
        } else {
            if (this.isEnergieMode()) {
                this.history.push('/Prozess/DetailsProzess/' + this.state.prozessID);
            } else {
                this.history.push('/Factor/DetailsFactor/' + this.state.einflussfaktorID);
            }
        }
    }

    async navigateToMessdaten(messdatenID) {
        await this.saveReferenz();
        if (this.isEnergieMode()) {
            this.history.push('/MessDaten/EnergieDaten/' + messdatenID + '/showYears');
        } else {
            this.history.push('/MessDaten/FactorMessDaten/' + messdatenID + '/showYears');
        }
        window.location.reload(false);
    }

    goBack() {
        if (this.canGoBack === "new") {
            if (this.isEnergieMode()) {
                this.history.push('/Prozess/Prozess/' + this.state.prozessID);
            } else {
                this.history.push('/Factor/Factor/' + this.state.einflussfaktorID);
            }
        } else
            this.history.goBack();

    }


    getRefData() {

        let Messpunkte = [
            this.normalise(this.state.januar),
            this.normalise(this.state.februar),
            this.normalise(this.state.maerz),
            this.normalise(this.state.april),
            this.normalise(this.state.mai),
            this.normalise(this.state.juni),
            this.normalise(this.state.juli),
            this.normalise(this.state.august),
            this.normalise(this.state.september),
            this.normalise(this.state.oktober),
            this.normalise(this.state.november),
            this.normalise(this.state.dezember)];

        let summeMessdaten = 0;

        for (let i = 0; i < Messpunkte.length; i++) {
            if (Messpunkte[i]) {
                summeMessdaten = summeMessdaten + Number.parseFloat(Messpunkte[i]);
            }
        }

        return {
            id: this.state.referenceID,
            bezugsJahr: this.state.bezugsJahr,
            einheit: this.state.einheit,
            summeMessdaten: summeMessdaten,
            Messpunkte: Messpunkte,
            kostenpu: this.normalise(this.state.kostenpu)
        }
    }

    normalise(entry) {
        if (entry === "") {
            return null;
        }
        return Number.parseFloat(("".concat(entry)).replaceAll(',', '.'));
    }

    deNormalise(entry) {
        if (entry === null) {
            return "";
        }
        return ("".concat(entry)).replaceAll('.', ',');
    }

    async saveReferenz() {

        if (this.isEnergieMode()) {
            try {

                const referenz = await API.graphql(graphqlOperation(updateEnergieMessDaten, {input: this.getRefData()}));
                await createEnergieKennzahlenforNewYearProcess(referenz.data.updateEnergieMessDaten.prozessID, referenz.data.updateEnergieMessDaten.id, referenz.data.updateEnergieMessDaten.bezugsJahr);
                await UpdateJahresDatenbyEnergieMessdatenID(referenz.data.updateEnergieMessDaten.id);

            } catch (error) {
                console.log("Fehler bei UpDate der EnergieMessDaten", error)
            }
        } else {
            try {

                const referenz = await API.graphql(graphqlOperation(updateEinflussFaktorMessDaten, {input: this.getRefData()}));

                await createEnergieKennzahlenforNewYearFaktor(referenz.data.updateEinflussFaktorMessDaten.einflussfaktorID, referenz.data.updateEinflussFaktorMessDaten.id, referenz.data.updateEinflussFaktorMessDaten.bezugsJahr);
                await UpdateJahresDatenbyFaktorMessdatenID(referenz.data.updateEinflussFaktorMessDaten.id);

            } catch (error) {
                console.log("Fehler bei UpDate der FaktorMessDaten", error)
            }
        }
    }

    async saveAndNext() {
        await this.saveReferenz();

        //Process
        if (this.isEnergieMode()) {
            let messdaten = await newEnergieMessDaten(this.state.process.id);
            this.state.process.aktuelleMessungID = messdaten.id;
            await updateProcess(this.state.process);
            this.history.push('/MessDaten/EnergieDaten/' + messdaten.id + '/false');
            this.history.go();
        } else {

            //Faktor
            let newFaktorMessdaten = await newFaktorMessDaten(this.state.einflussfaktor.id);

            this.state.einflussfaktor.aktuelleMessungID = newFaktorMessdaten.id;

            await updateEinflussFaktor(this.state.einflussfaktor);

            this.history.push('/MessDaten/FaktorDaten/' + newFaktorMessdaten.id + '/false');
            this.history.go();
        }
    }


    setYear(selection) {
        this.setState({bezugsJahr: selection.getFullYear()})
    }

    parseData(data) {
        let rows = data.target && data.target.value && data.target.value.split("\n");
        if (rows.length > 12) {
            this.setState({errortext_parser: "Es können maximal 12 Werte für Januar bis Dezember eingetragen werden."});
        } else {
            this.setState({errortext_parser: ""});
        }
        for (let i = 0; i < rows.length; i++) {
            rows[i] = ("".concat(rows[i])).replaceAll('.', '')
            if (!this.validate(rows[i])) {
                rows[i] = undefined;
                this.setState({errortext_parser: "Es dürfen nur Zahlen eingebeben werden"});
            }
        }

        this.setState({tableData: data.target && data.target.value});
        this.setState({januar: rows[0]});
        this.setState({februar: rows[1]});
        this.setState({maerz: rows[2]});
        this.setState({april: rows[3]});
        this.setState({mai: rows[4]});
        this.setState({juni: rows[5]});
        this.setState({juli: rows[6]});
        this.setState({august: rows[7]});
        this.setState({september: rows[8]});
        this.setState({oktober: rows[9]});
        this.setState({november: rows[10]});
        this.setState({dezember: rows[11]});

    }

    closeDialoge() {
        this.setState({showDialog: false});
        window.location.reload(false);
    }

    onHide() {
        this.setState({showDialog: false});
    }

    openDialoge() {
        this.setState({showDialog: true});
    }


    render() {

        return (
            this.state.loading ?
                <div className="loading-screen">
                    <CircularProgress className="loading-icon"/>
                </div>
                :
                <div className="dashboard-root">
                    <div>
                        <header>
                            <h4>{this.headerText}
                                {this.state.process && (getEnergySource(this.state.process.energietraeger).displayName)}
                                {this.state.einflussfaktor && (this.state.einflussfaktor.einflussFaktorName)}
                                {this.state.messdatenJahre ? "" : " bearbeiten"}
                                <BsReverseLayoutTextSidebarReverse className="float-right"
                                                                   title="Kopiere Daten aus Tabellen"
                                                                   onClick={() => this.openDialoge()}/>
                            </h4>
                            {this.state.process && this.state.messdatenJahre &&
                            <small>Trage Durchschnittskosten je Einheit sowie verfügbare Messdaten je Monat ein. Du
                                kannst
                                deine Eingabe später vervollständigen oder bearbeiten.</small>}

                            {this.state.einflussfaktor && this.state.messdatenJahre &&
                            <small>Trage verfügbare Messdaten je Monat und Jahr ein. Du kannst deine Eingabe später
                                vervollständigen oder bearbeiten.</small>}
                        </header>
                        <div className="base-content">

                            <div>
                                {this.state.messdatenJahre && this.state.messdatenJahre.map((item, index) =>
                                    <Button className="margin-left color-w" key={index}
                                            active={item.bezugsJahr === this.state.bezugsJahr}
                                            onClick={() => this.navigateToMessdaten(item.id)}>
                                        {item.bezugsJahr}
                                    </Button>
                                )}
                            </div>

                            {!this.state.dateDisabled &&
                            <div className="mb-3 messJahr">
                                <label className="form-label">Bezugsjahr</label>
                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                    <DatePicker
                                        views={['year']}
                                        value={this.state.bezugsJahr ? new Date().setFullYear(this.state.bezugsJahr) : ""}
                                        onChange={(newValue) => this.setYear(newValue)}
                                        minDate={new Date().setFullYear(2000)}
                                        maxDate={new Date().setFullYear((new Date().getFullYear() + 2))}
                                        disabled={this.state.dateDisabled}
                                        renderInput={(params) => <TextField {...params} helperText={null}/>}
                                    />
                                </LocalizationProvider>
                            </div>
                            }

                            <br/>
                            <br/>

                            <header>
                                <h5>Messungen für Referenzperiode {this.state.bezugsJahr} </h5>
                            </header>
                            <br/>
                            <br/>
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={6}>
                                    {this.state.process &&
                                    <div className="grid-contents">
                                        <div className="mb-3">
                                            <TextField
                                                fullWidth
                                                id="input-kostenpu"
                                                label="Durchschnittskosten"
                                                name="kostenpu"
                                                InputLabelProps={{shrink: true}}
                                                value={this.deNormalise(this.state.kostenpu)}
                                                onChange={e => this.setValue({kostenpu: e.target.value})}/>
                                            <small>Error message</small>
                                        </div>
                                    </div>
                                    }
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <div className="grid-contents">

                                    </div>
                                </Grid>
                            </Grid>
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={6}>
                                    <div className="grid-contents">
                                        <div className="mb-3">
                                            <TextField
                                                fullWidth
                                                id="input-januar"
                                                label="Januar"
                                                name="januar"
                                                InputLabelProps={{shrink: true}}
                                                value={this.state.januar}
                                                onChange={e => this.setValue({januar: e.target.value})}/>
                                            <small>Error message</small>
                                        </div>
                                    </div>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <div className="grid-contents">
                                        <div className="mb-3">
                                            <TextField
                                                fullWidth
                                                id="input-februar"
                                                label="Februar"
                                                name="februar"
                                                InputLabelProps={{shrink: true}}
                                                value={this.state.februar}
                                                onChange={e => this.setValue({februar: e.target.value})}/>
                                            <small>Error message</small>
                                        </div>
                                    </div>
                                </Grid>
                            </Grid>

                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={6}>
                                    <div className="grid-contents">
                                        <div className="mb-3">
                                            <TextField
                                                fullWidth
                                                id="input-maerz"
                                                label="März"
                                                name="maerz"
                                                InputLabelProps={{shrink: true}}
                                                value={this.state.maerz}
                                                onChange={e => this.setValue({maerz: e.target.value})}/>
                                            <small>Error message</small>
                                        </div>
                                    </div>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <div className="grid-contents">
                                        <div className="mb-3">
                                            <TextField
                                                fullWidth
                                                id="input-april"
                                                label="April"
                                                name="april"
                                                InputLabelProps={{shrink: true}}
                                                value={this.state.april}
                                                onChange={e => this.setValue({april: e.target.value})}/>
                                            <small>Error message</small>
                                        </div>
                                    </div>
                                </Grid>
                            </Grid>

                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={6}>
                                    <div className="grid-contents">
                                        <div className="mb-3">
                                            <TextField
                                                fullWidth
                                                id="input-mai"
                                                label="Mai"
                                                name="mai"
                                                InputLabelProps={{shrink: true}}
                                                value={this.state.mai}
                                                onChange={e => this.setValue({mai: e.target.value})}/>
                                            <small>Error message</small>
                                        </div>
                                    </div>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <div className="grid-contents">
                                        <div className="mb-3">
                                            <TextField
                                                fullWidth
                                                id="input-juni"
                                                label="Juni"
                                                name="juni"
                                                InputLabelProps={{shrink: true}}
                                                value={this.state.juni}
                                                onChange={e => this.setValue({juni: e.target.value})}/>
                                            <small>Error message</small>
                                        </div>
                                    </div>
                                </Grid>
                            </Grid>

                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={6}>
                                    <div className="grid-contents">
                                        <div className="mb-3">
                                            <TextField
                                                fullWidth
                                                id="input-juli"
                                                label="Juli"
                                                name="juli"
                                                InputLabelProps={{shrink: true}}
                                                value={this.state.juli}
                                                onChange={e => this.setValue({juli: e.target.value})}/>
                                            <small>Error message</small>
                                        </div>
                                    </div>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <div className="grid-contents">
                                        <div className="mb-3">
                                            <TextField
                                                fullWidth
                                                id="input-august"
                                                label="August"
                                                name="august"
                                                InputLabelProps={{shrink: true}}
                                                value={this.state.august}
                                                onChange={e => this.setValue({august: e.target.value})}/>
                                            <small>Error message</small>
                                        </div>
                                    </div>
                                </Grid>
                            </Grid>

                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={6}>
                                    <div className="grid-contents">
                                        <div className="mb-3">
                                            <TextField
                                                fullWidth
                                                id="input-september"
                                                label="September"
                                                name="september"
                                                InputLabelProps={{shrink: true}}
                                                value={this.state.september}
                                                onChange={e => this.setValue({september: e.target.value})}/>
                                            <small>Error message</small>
                                        </div>
                                    </div>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <div className="grid-contents">
                                        <div className="mb-3">
                                            <TextField
                                                fullWidth
                                                id="input-oktober"
                                                label="Oktober"
                                                name="oktober"
                                                InputLabelProps={{shrink: true}}
                                                value={this.state.oktober}
                                                onChange={e => this.setValue({oktober: e.target.value})}/>
                                            <small>Error message</small>
                                        </div>
                                    </div>
                                </Grid>
                            </Grid>

                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={6}>
                                    <div className="grid-contents">
                                        <div className="mb-3">
                                            <TextField
                                                fullWidth
                                                id="input-november"
                                                label="November"
                                                name="november"
                                                InputLabelProps={{shrink: true}}
                                                value={this.state.november}
                                                onChange={e => this.setValue({november: e.target.value})}/>
                                            <small>Error message</small>
                                        </div>
                                    </div>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <div className="grid-contents">
                                        <div className="mb-3">
                                            <TextField
                                                fullWidth
                                                id="input-dezember"
                                                label="Dezember"
                                                name="dezember"
                                                InputLabelProps={{shrink: true}}
                                                value={this.state.dezember}
                                                onChange={e => this.setValue({dezember: e.target.value})}/>
                                            <small>Error message</small>
                                        </div>

                                    </div>
                                </Grid>
                            </Grid>
                            <EnpiButton
                                onClick={() => this.saveAndGotoProcessorFaktor()}
                                disabled={this.state.bezugsJahr == null || this.state.bezugsJahr === ""}>
                                {this.isEnergieMode() && this.state.messdatenJahre ? "Verbraucher anlegen" : !this.isEnergieMode() && this.state.messdatenJahre ? "Einflussfaktor anlegen" : "Speichern"}

                            </EnpiButton>
                            <br/>
                            <Button className="link-button" onClick={() => this.goBack()}
                                    variant="primary">Zurück</Button>{' '}

                        </div>
                    </div>

                    <Dialog
                        fullScreen={false}
                        open={this.state.showDialog}
                        onClose={() => this.onHide()}
                        aria-labelledby="responsive-dialog-title"
                    >
                        <DialogTitle id="responsive-dialog-title">
                            {"Werte aus Tabelle eintragen"}
                        </DialogTitle>
                        <DialogContent>
                            <DialogContentText>
                                Kopiere Messwerte für Januar bis Dezember aus einer beliebigen Tabelle und
                                füge
                                diese im jeweiligen Bereich ein.
                                Die Angaben werden automatisch übernommen.
                            </DialogContentText>
                            <br/>
                            <div className="align-center">
                                <br/>
                                <TextField
                                    className="table-dialog"
                                    id="outlined-multiline-static"
                                    label="Gemessene Werte"
                                    multiline
                                    rows={12}
                                    value={this.state.tableData}
                                    InputLabelProps={{shrink: true}}
                                    error={this.state.errortext_parser !== ""}
                                    helperText={this.state.errortext_parser}
                                    onChange={(data) => {
                                        this.parseData(data)
                                    }}
                                />
                            </div>

                        </DialogContent>
                        <DialogActions>
                            <Button disabled={this.state.errortext_parser !== ""} onClick={() => this.onHide()}>
                                Übernehmen
                            </Button>
                            <Button className="link-button" onClick={() => this.closeDialoge()}>
                                Abbrechen
                            </Button>
                        </DialogActions>
                    </Dialog>
                </div>

        );
    }
}

export default withRouter(MessDaten);
