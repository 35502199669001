//This is the Navigation for mobile devices when clicking on the burger nav

import React from 'react';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import PopupState, {bindTrigger, bindMenu} from 'material-ui-popup-state';
import {NavLink} from "react-router-dom";
//Is GoGrabber still in use?
import {GoGrabber} from "react-icons/go";
import {Auth} from "@aws-amplify/auth";
import {useUser} from "../UserContext";
import awsconfig from "../aws-exports";
import {PackageIcon,ProjectIcon,ThumbsupIcon,RocketIcon,SignOutIcon,GearIcon} from '@primer/octicons-react';


export default function MenuPopupState() {
    Auth.configure(awsconfig);
    const { companyID } = useUser();

    return (
        <PopupState variant="popover" popupId="demo-popup-menu">
            {(popupState) => (
                <React.Fragment>
                    <GoGrabber className="icon-size-medium float-right" title="Messjahr löschen"
                               {...bindTrigger(popupState)}/>
                    <Menu {...bindMenu(popupState)}>

                        <MenuItem onClick={popupState.close}><NavLink exact className="nav-link" activeClassName="main-nav-active" to={`/`}> 
                            <span className="nav-link-title"> 
                            <ProjectIcon /> Dashboard
                            </span>
                            </NavLink>
                        </MenuItem>

                        <MenuItem onClick={popupState.close}>
                            <NavLink className="nav-link" activeClassName="main-nav-active" to={`/Prozess/${companyID}`}>
                            <span className="nav-link-title"> 
                            <PackageIcon /> Verbraucher 
                            </span>
                            </NavLink>
                        </MenuItem>

                        <MenuItem onClick={popupState.close}>
                            <NavLink className="nav-link" activeClassName="main-nav-active" to={`/Factor/${companyID}`}>
                                <span className="nav-link-title">
                                <RocketIcon/> Einflussfaktoren
                                </span>
                            </NavLink>
                        </MenuItem>

                        <MenuItem onClick={popupState.close}>
                            <NavLink className="nav-link" activeClassName="main-nav-active" to={`/EnergieKennzahl/${companyID}`}>               
                                <span className="nav-link-title">
                                <ThumbsupIcon/> Kennzahlen
                                </span>
                            </NavLink>
                        </MenuItem>

                        <MenuItem onClick={popupState.close}>
                            <NavLink className="nav-link" activeClassName="main-nav-active" to={`/Settings/${companyID}`}>               
                                <span className="nav-link-title">
                                <GearIcon/> Einstellungen
                                </span>
                            </NavLink>
                        </MenuItem>

                        <MenuItem onClick={popupState.close}> 
                        <a className="nav-link" href="/"onClick={() => Auth.signOut()}>
                            <span className="nav-link-title">
                            <SignOutIcon/> Ausloggen
                            </span>
                        </a>
                        </MenuItem>
                    </Menu>
                </React.Fragment>
            )}
        </PopupState>
    );
}
