import Grid from "@material-ui/core/Grid";
import { useEffect, useMemo, useState } from "react";
import { Link, withRouter } from "react-router-dom";

import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import { FiEdit2 } from "react-icons/fi";
import { formaterEuro, isEmpty } from "../Util/helper";
import EnergieMessDatenOverview from "./EnergieMessDaten/EnergieMessDatenOverview";
import MessDatenAnalyseCanvasJahre from "./MessDatenAnalyseCanvasJahre";
import MessDatenAnalyseCanvasMonatszeitraum from "./MessDatenAnalyseCanvasMonatszeitraum";
import { CachedConsumer } from "../Model/VirtualConsumer";

const DetailsProzess = ({ match }) => {
    const processId = match.params.process;
    const [cachedConsumer, setCachedConsumer] = useState(null as CachedConsumer);
    const [consumerError, setConsumerError] = useState("");

    //const [energyMeasurements, setEnergyMeasurements] = useState(null as EnergieMessDaten[]);
    //const [energySource, setEnergySource] = useState({} as EnergySource);

    const [monthError, setMonthError] = useState(false);
    const [missingDataError, setMissingDataError] = useState("");

    const [availableYears, setAvailableYears] = useState([]);
    const [filterOptions, setFilterOptions] = useState({
        showYears: true,
        startYear: null,
        endYear: null,
        startMonth: 0,
        endMonth: 2,
        mode: "all", // 'all' || 'zeitraum'
    });

    const [energyDataViewList, setEnergyDataViewList] = useState(null);

    const firstYear = useMemo(() => (availableYears.length > 0 ? availableYears[0] : null), [availableYears]);
    const lastYear = useMemo(() => (availableYears.length > 0 ? availableYears[availableYears.length - 1] : null), [availableYears]);

    const effectiveYearsWindow = useMemo(() => {
        if (filterOptions.mode === "all") return [firstYear, lastYear];
        return filterOptions.showYears ? [filterOptions.startYear, filterOptions.endYear] : [firstYear, lastYear];
    }, [firstYear, lastYear, filterOptions]);

    useEffect(() => {
        if (!processId) return;
        const fetchData = async () => {
            try {
                const cachedConsumer = await CachedConsumer.load(processId);
                setCachedConsumer(cachedConsumer);
                setEnergyDataViewList(cachedConsumer.getDataViewList());

                const years = cachedConsumer.getYears();
                setAvailableYears(years);
                setFilterOptions((options) => ({
                    ...options,
                    startYear: years[0],
                    endYear: years[years.length - 1],
                }));
            } catch(e) {
                setConsumerError((e as Error).message);
                console.error(e);
            }
        };
        fetchData();
    }, [processId]);

    const { gesamtVerbrauch, energiekosten, gesamtCO2 } = useMemo(() => {
        if (!cachedConsumer) {
            return {
                gesamtVerbrauch: 0,
                energiekosten: 0,
                gesamtCO2: 0,
            };
        }

        const [startYear, endYear] = effectiveYearsWindow;

        const startMonth = filterOptions.showYears ? 0 : filterOptions.startMonth;
        const endMonth = filterOptions.showYears ? 11 : filterOptions.endMonth;

        const totals = cachedConsumer.getTotals(startYear, endYear, startMonth, endMonth);
        setMissingDataError(totals.dataError);
        return totals;
    }, [filterOptions, cachedConsumer, effectiveYearsWindow]);

    const editLink = cachedConsumer ? (cachedConsumer.isVirtual() ?
        `/VirtualConsumer/Edit/${cachedConsumer.primaryProcess.id}` : `/Prozess/Prozess/${cachedConsumer.primaryProcess.id}`) : '';
    return (
        <div className="dashboard-root">
            <div className="base-content">
                <header>
                    <h1>
                        Details Verbraucher {cachedConsumer ? cachedConsumer.primaryProcess.prozessname : ""}
                        <Link to={editLink}>
                            <FiEdit2 className="float-right" title="Verbraucher bearbeiten" />
                        </Link>
                    </h1>
                </header>
                {consumerError && (
                    <h5 className="text-danger mr-4">{consumerError}</h5>
                )}
                <br />
                <div className="table-responsive">
                    <table className="table table-vcenter">
                        <tbody>
                            {cachedConsumer && !cachedConsumer.isVirtual() &&
                                <tr>
                                    <td className="no-border">Verantwortlichkeit</td>
                                    <td className="no-border">{cachedConsumer.primaryProcess.verantwortlicher}</td>
                                </tr>
                            }
                            {cachedConsumer && cachedConsumer.isVirtual() &&
                                <tr>
                                    <td className="no-border">Formel</td>
                                    <td className="no-border">{cachedConsumer.primaryProcess.formula}</td>
                                </tr>
                            }
                            <tr>
                                <td>Energieträger</td>
                                <td>{cachedConsumer ? cachedConsumer.getEnergySource().name : ""}</td>
                            </tr>
                            <tr>
                                <td>Einheit</td>
                                <td>{cachedConsumer ? cachedConsumer.getEinheit() : ""}</td>
                            </tr>
                            <tr>
                                <td>CO<sub>2</sub> Faktor</td>
                                <td>{cachedConsumer ? cachedConsumer.getCO2FactorStr() : ""}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <br />
                <br />

                <header>
                    <h3>Auswertung Messdaten</h3>
                </header>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <div className="">
                            <div className="">
                                <Grid container spacing={2}>
                                    <Grid item xs={12} sm={12} className="pb-0">
                                        <div className="mb-3 auswertung float-right">
                                            <InputLabel id="auswertung-select-label">Auswertung</InputLabel>
                                            <Select
                                                labelId="auswertung-select-label"
                                                id="auswertung-open-select"
                                                className="select-minsize"
                                                value={filterOptions.mode}
                                                onChange={(e) => {
                                                    const mode = e.target.value as string;
                                                    setFilterOptions((options) => ({
                                                        ...options,
                                                        mode,
                                                    }));
                                                }}
                                            >
                                                <MenuItem value={"all"}>Alle</MenuItem>
                                                <MenuItem value={"zeitraum"}>Zeitraum</MenuItem>
                                            </Select>
                                        </div>

                                        <div className="mb-3 anzeigeZeitraum float-right">
                                            <InputLabel id="anzeigeZeitraum-select-label">Zeitraum</InputLabel>
                                            <Select
                                                labelId="anzeigeZeitraum-select-label"
                                                id="anzeigeZeitraum-open-select"
                                                className="select-minsize"
                                                value={filterOptions.showYears ? 'Years' : 'Months'}
                                                onChange={(e) =>
                                                    setFilterOptions((options) => ({
                                                        ...options,
                                                        showYears: e.target.value === 'Years',
                                                    }))
                                                }
                                            >
                                                <MenuItem value={'Years'}>Jahre</MenuItem>
                                                <MenuItem value={'Months'}>Monate</MenuItem>
                                            </Select>
                                        </div>
                                        {/* select month period */}
                                        {/* End month */}
                                        {!filterOptions.showYears && (
                                            <div className="mb-3 monatauswahl float-right">
                                                <InputLabel id="label-select-endmonth">End-Monat</InputLabel>
                                                <Select
                                                    labelId="label-select-endmonth"
                                                    id="endmonth-open-select"
                                                    className="select-minsize"
                                                    value={filterOptions.endMonth}
                                                    onChange={(e) => {
                                                        const endMonth = e.target.value as number;
                                                        if (filterOptions.startMonth <= endMonth) {
                                                            setFilterOptions((options) => ({
                                                                ...options,
                                                                endMonth,
                                                            }));
                                                            setMonthError(false);
                                                        } else {
                                                            setMonthError(true);
                                                        }
                                                    }}
                                                >
                                                    <MenuItem value={0}>Jan</MenuItem>
                                                    <MenuItem value={1}>Feb</MenuItem>
                                                    <MenuItem value={2}>März</MenuItem>
                                                    <MenuItem value={3}>April</MenuItem>
                                                    <MenuItem value={4}>Mai</MenuItem>
                                                    <MenuItem value={5}>Juni</MenuItem>
                                                    <MenuItem value={6}>Juli</MenuItem>
                                                    <MenuItem value={7}>Aug</MenuItem>
                                                    <MenuItem value={8}>Sep</MenuItem>
                                                    <MenuItem value={9}>Okt</MenuItem>
                                                    <MenuItem value={10}>Nov</MenuItem>
                                                    <MenuItem value={11}>Dez</MenuItem>
                                                </Select>
                                            </div>
                                        )}
                                        {/*Start month*/}
                                        {!filterOptions.showYears && (
                                            <div className="mb-3 monatauswahl float-right">
                                                {/* month select */}
                                                <InputLabel id="label-select-startmonth">Start-Monat</InputLabel>
                                                <Select
                                                    labelId="label-select-startmonth"
                                                    id="startmonth-open-select"
                                                    className="select-minsize"
                                                    value={filterOptions.startMonth}
                                                    onChange={(e) => {
                                                        const startMonth = e.target.value as number;
                                                        if (startMonth <= filterOptions.endMonth) {
                                                            setFilterOptions((options) => ({
                                                                ...options,
                                                                startMonth,
                                                            }));
                                                            setMonthError(false);
                                                        } else {
                                                            setMonthError(true);
                                                        }
                                                    }}
                                                >
                                                    <MenuItem value={0}>Jan</MenuItem>
                                                    <MenuItem value={1}>Feb</MenuItem>
                                                    <MenuItem value={2}>März</MenuItem>
                                                    <MenuItem value={3}>April</MenuItem>
                                                    <MenuItem value={4}>Mai</MenuItem>
                                                    <MenuItem value={5}>Juni</MenuItem>
                                                    <MenuItem value={6}>Juli</MenuItem>
                                                    <MenuItem value={7}>Aug</MenuItem>
                                                    <MenuItem value={8}>Sep</MenuItem>
                                                    <MenuItem value={9}>Okt</MenuItem>
                                                    <MenuItem value={10}>Nov</MenuItem>
                                                    <MenuItem value={11}>Dez</MenuItem>
                                                </Select>
                                            </div>
                                        )}

                                        {filterOptions.showYears && filterOptions.mode === "zeitraum" && (
                                            <div className="mb-3 bisJahrauswahl float-right">
                                                <InputLabel id="bisJahrauswahl-select-label">bis</InputLabel>
                                                <Select
                                                    labelId="bisJahrauswahl-select-label"
                                                    id="bisJahrauswahl-open-select"
                                                    className="select-minsize"
                                                    value={filterOptions.endYear}
                                                    onChange={(e) =>
                                                        setFilterOptions((options) => ({
                                                            ...options,
                                                            endYear: e.target.value,
                                                        }))
                                                    }
                                                >
                                                    {availableYears && availableYears.length > 0 ? (
                                                        availableYears.map((item, index) => (
                                                            <MenuItem key={index} value={item}>
                                                                {item}
                                                            </MenuItem>
                                                        ))
                                                    ) : (
                                                        <div>Kein mögliches Jahr gefunden.</div>
                                                    )}
                                                </Select>
                                            </div>
                                        )}
                                        {filterOptions.showYears && filterOptions.mode === "zeitraum" && (
                                            <div className="mb-3 vonJahrauswahl float-right">
                                                <InputLabel id="vonJahrauswahl-select-label">von</InputLabel>
                                                <Select
                                                    labelId="vonJahrauswahl-select-label"
                                                    id="vonJahrauswahl-open-select"
                                                    className="select-minsize"
                                                    value={filterOptions.startYear}
                                                    onChange={(e) =>
                                                        setFilterOptions((options) => ({
                                                            ...options,
                                                            startYear: e.target.value,
                                                        }))
                                                    }
                                                >
                                                    {availableYears && availableYears.length > 0 ? (
                                                        availableYears.map((item, index) => (
                                                            <MenuItem key={index} value={item}>
                                                                {item}
                                                            </MenuItem>
                                                        ))
                                                    ) : (
                                                        <div>Kein mögliches Jahr gefunden.</div>
                                                    )}
                                                </Select>
                                            </div>
                                        )}
                                    </Grid>
                                    {/* month error if start month > end month*/}
                                    <Grid item xs={12} sm={12} className="d-flex justify-content-end p-0 mb-3">
                                        {monthError && (
                                            <h5 className="text-danger mr-4">Startmonat muss gleich sein oder vor dem Endmonat liegen!</h5>
                                        )}
                                    </Grid>
                                </Grid>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} sm={4}>
                                        <div className="grid-contents">
                                            <div className="base-content-grid">
                                                <header className="border-bottom">
                                                    <h6 className="medium-font">Gesamtverbrauch</h6>
                                                </header>
                                                <div className="font-large">
                                                    {(gesamtVerbrauch && cachedConsumer) ? cachedConsumer.getGesamtVerbrauchStr(gesamtVerbrauch) : ""}
                                                </div>
                                            </div>
                                        </div>
                                    </Grid>
                                    <Grid item xs={12} sm={4}>
                                        <div className="grid-contents">
                                            <div className="base-content-grid">
                                                <header className="border-bottom">
                                                    <h6 className="medium-font">
                                                        CO<sub>2</sub> Emission
                                                    </h6>
                                                </header>

                                                <div className="font-large">
                                                    {(!isEmpty(gesamtCO2) && cachedConsumer) ? cachedConsumer.getTotalCO2Str(gesamtCO2) : ""}
                                                </div>
                                            </div>
                                        </div>
                                    </Grid>
                                    <Grid item xs={12} sm={4}>
                                        <div className="grid-contents">
                                            <div className="base-content-grid text-align-center">
                                                <header className="border-bottom">
                                                    <h6 className="medium-font">Kosten</h6>
                                                </header>

                                                <div className="font-large">{formaterEuro(energiekosten)}</div>
                                                <small className="text-align-center">
                                                    {missingDataError === "" ? "" : "Kosten für " + missingDataError + " nicht enthalten"}
                                                </small>
                                            </div>
                                        </div>
                                    </Grid>
                                </Grid>
                                {cachedConsumer && energyDataViewList && (
                                <div>
                                    <header>
                                        <h3>Entwicklung Verbrauch</h3>
                                    </header>
                                    <br />
                                    <div>
                                        {!filterOptions.showYears && cachedConsumer && energyDataViewList && (
                                            //    overview over several months
                                            <MessDatenAnalyseCanvasMonatszeitraum
                                                data={energyDataViewList}
                                                startMonth={filterOptions.startMonth}
                                                endMonth={filterOptions.endMonth}
                                                einheit={cachedConsumer.getEinheit()}
                                            />
                                        )}
                                        {filterOptions.showYears && cachedConsumer && energyDataViewList && (
                                            <MessDatenAnalyseCanvasJahre
                                                data={energyDataViewList?.filter(
                                                    (item) =>
                                                        Number.parseInt(item.val.bezugsJahr, 10) >= effectiveYearsWindow[0] &&
                                                        Number.parseInt(item.val.bezugsJahr, 10) <= effectiveYearsWindow[1]
                                                )}
                                                einheit={cachedConsumer.getEinheit()}
                                                type="line"
                                            />
                                        )}
                                    </div>
                                </div>
                                )}
                            </div>
                        </div>
                    </Grid>
                </Grid>
                <br />
                <br />
                {energyDataViewList && cachedConsumer && (
                    <EnergieMessDatenOverview data={energyDataViewList} process={cachedConsumer.primaryProcess} />
                )}
            </div>
        </div>
    );
};

export default withRouter(DetailsProzess);
