import { useState } from "react";
import { Link } from "react-router-dom";
import { deleteEinflussFaktorCascade } from "../../DataBase/EinflussFaktor/EinflussfaktorAPI";
import { FiTrash2 } from "react-icons/fi/index";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import { Button } from "react-bootstrap";
import { Einflussfaktor } from "../../API";

interface FactorListItemProps {
    factor: Einflussfaktor;
}

const FaktorListItem = (props: FactorListItemProps) => {
    const [open, setOpen] = useState(false);

    const deleteEinflussFaktor = async() => {
        await deleteEinflussFaktorCascade(props.factor.id);
        handleClose();
        window.location.reload();
    }

    const handleClickOpen = () => {
        setOpen(true);
    }

    const handleClose = () => {
        setOpen(false);
    }

    return (
        <tr>
            <td className="text-dark">
                <Link to={`/Factor/DetailsFactor/${props.factor.id}`}>{props.factor.einflussFaktorName}</Link>
            </td>
            <td>
                {props.factor.einheit}
            </td>
            
            <td className="text-end">
                <FiTrash2 size={24} className="float-right" title="Prozess löschen" onClick={handleClickOpen}/>
                <div>
                    <Dialog
                        fullScreen={false}
                        open={open}
                        onClose={handleClose}
                        aria-labelledby="responsive-dialog-title"
                    >
                        <DialogTitle id="responsive-dialog-title">
                            {"Einflussfaktor löschen"}
                        </DialogTitle>
                        <DialogContent>
                            <DialogContentText>
                                Bist du sicher, dass du den
                                Einflussfaktor {props.factor.einflussFaktorName} löschen möchtest?
                                Dies kann nicht rückgängig gemacht werden.
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button className="red-button" onClick={deleteEinflussFaktor}>
                                Löschen
                            </Button>
                            <Button className="link-button" onClick={handleClose}>
                                Abbrechen
                            </Button>
                        </DialogActions>
                    </Dialog>
                </div>
            </td>
        </tr>
    );
}

export default FaktorListItem;
