import FaktorMessDatenListItem from "./FaktorMessDatenListItem";
import { RouteComponentProps, useHistory, withRouter } from "react-router-dom";
import { newFaktorMessDaten } from "../../DataBase/MessDaten/MessDatenAPI";
import { updateEinflussFaktor } from "../../DataBase/EinflussFaktor/EinflussfaktorAPI";
import EnpiButton from "../../ui/EnpiButton";

interface FaktorMessDatenOverviewProps extends RouteComponentProps {
    data: any; //faktorDatenViewlist
    faktor: any; //einflussfaktor
}

const FaktorMessDatenOverview = (props: FaktorMessDatenOverviewProps) => {
    const history = useHistory();

    const neuesMessJahr = async() => {
        let newFaktorMessdaten = await newFaktorMessDaten(props.faktor.id, null);
        props.faktor.aktuelleMessungID = newFaktorMessdaten.id;
        await updateEinflussFaktor(props.faktor);
        history.push('/MessDaten/FaktorDaten/' + newFaktorMessdaten.id);
    }

    return (
        <div>
            <div className="dashboard-root">
                <div className="base-content">
                    <div>
                        <header>
                            <h4>Übersicht Messdaten : {props.faktor && props.faktor.einflussFaktorName}</h4>
                        </header>
                        <br/>

                        <div className="table-responsive">
                            <table
                                className="table table-vcenter">
                                <thead>
                                <tr>
                                    <th>Jahr</th>
                                    <th className="text-right">{props.faktor && props.faktor.einheit}</th>
                                    <th className="text-right">Status Messdaten</th>
                                    <th></th>
                                </tr>
                                </thead>
                                <tbody>
                                {props.data.map((energieDaten, index) =>
                                    <FaktorMessDatenListItem key={index} energieDaten={energieDaten}/>
                                )}
                                </tbody>
                            </table>
                        </div>
                        <EnpiButton onClick={neuesMessJahr}>Messdaten hinzufügen</EnpiButton>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default withRouter(FaktorMessDatenOverview);
